import React, { useEffect } from "react";
import ConvPerAgentChart from "./Chart";
import { useTranslation } from "react-i18next";
import Cards from "../../Modules/Dashboard/components/reuseableComponents/Cards";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { whatsappReportData } from "../../Modules/Dashboard/states/actions";
import { Table } from "antd";
import moment from "moment";
const ReportsStats = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 3,
  });
  const receiveMessage = useSelector(
    (state) => state?.dashboard?.Reports?.stats?.receiveMessage
  );
  const resolutionTime = useSelector(
    (state) => state?.dashboard?.Reports?.stats?.resolutionTime
  );
  const waiting = useSelector(
    (state) => state?.dashboard?.Reports?.stats?.waiting
  );
  const open = useSelector((state) => state?.dashboard?.Reports?.stats?.open);
  const solve = useSelector((state) => state?.dashboard?.Reports?.stats?.solve);
  const agentResolution = useSelector(
    (state) => state?.dashboard?.Reports?.agentResolution || []
  );
  const monthWiseResolution = useSelector(
    (state) => state?.dashboard?.Reports?.monthWiseResolution || []
  );
  const agentResolutionData = {
    labels: agentResolution.map((item) => item.agentEmail),
    datasets: [
      {
        data: agentResolution.map((item) => item.count),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  useEffect(() => {
    dispatch(whatsappReportData());
  }, []);

  const SurveysColumns = [
    {
      title: t("Average Resolution Time"),
      dataIndex: "averageResolutionTime",
      key: "averageResolutionTime",
    },
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
    },
  ];
  const monthWiseResolutionData = monthWiseResolution.map((item, index) => ({
    key: index,
    averageResolutionTime: item.averageResolutionTime,
    date: item.date ? moment(item.date).format("MMMM Do YYYY") : ""
  }));
  return (
    <>
      <div className="main-states-container">
        <Cards title={t("Messages Received")} value={receiveMessage || 0} width="30%" />
        <Cards title={t("Resolution Time")} value={resolutionTime || 0} width="30%"  />
        <Cards title={t("Av Customer Waiting Time")} value={waiting || 0}  width="30%" />
        <Cards title={t("Opened Conversation")} value={open || 0}  width="30%"  />
        <Cards title={t("Solved Conversation")} value={solve || 0}  width="30%"  />
      </div>
      <div /* className="chartsParent" */>
        <div className="cards-box" style={{width:"100%",height:"100%",marginTop:10}}>
          <h6 /* className="heading" */ >{t("Conversations-per-agents")}</h6>
          <div style={{ marginTop: "1%" }}>
            <ConvPerAgentChart
              data={agentResolutionData}
            />
          </div>
        </div>
        <div className="cards-box" style={{width:"100%",height:"100%",marginTop:10}}>
          <h6 /* className="heading" */ >{t("Resolution-Time")}</h6>
          <div style={{ marginTop: "1%" }}>
            <Table
              columns={SurveysColumns}
              dataSource={monthWiseResolutionData}
              onChange={(page) => {
                setPagination(page);
              }}
            />
          </div>
        </div>
      </div>
     <div>
</div> 
    </>
  );
};
export default ReportsStats;
