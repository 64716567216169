import React from 'react';
import './Cards.scss';

const Cards = (props) => {
    let {title, value,width} = props
    return <div className='cards-box' style={{padding:14, textAlign:'center',width: width}}>
        <div className='cards-title'>{value}</div>
        <div className='cards-value'>{title}</div>
    </div>
}

export default Cards;