import { useEffect, useState } from "react"
import {templateList} from "./api/list";
import CustomSelectBox from "./fields/option";

export default function BuildTemplate(){
    const [templates, setTemplates] = useState(null);

    useEffect(()=>{
        templateList().then((res) => {
            setTemplates(res.data || []); 
        });
    },[])

    return(
        <>
        <div
            className={'message-template-section message-inner-section form-section d-block'}>
            <h3 className="sub-heading" style={{marginBottom:10, textAlign:'start'}}>Select Template</h3>
            {!templates && <span className="message-text"> Getting Template List </span>}
            {templates?.length === 0 && <span className="message-text">Template not found</span>}
            {templates?.length > 0 &&
                <CustomSelectBox options={templates} name='template' label="Select any template"/>}
            </div>
        </>
    )
}

