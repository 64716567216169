import { whatsappAnalytic , analyticPerformance , conversationStats , AllBotData,AllcustomerVoice} from '../components/Api/Api';
import {whatsappReport} from "../../Reports/Api/Api"
const getDashboardOverview = (data) => {
    return (dispatch) => {
        try {
            !!data ? dispatch({
                type:"RECEIVE_DASHBOARD_OVERVIEW",
                payload:data
            }) :  whatsappAnalytic()
              .then((res) => {
                  dispatch({
                      type:"RECEIVE_DASHBOARD_OVERVIEW",
                      payload:res.data.data
                  })
              })
              .catch((error) => {
                  console.error("Error fetching WhatsApp analytics:", error);
              });
        } catch {
            console.log("Error while getting dashboard overview")
        }
    }
}
const getSpeedMeter = (data) => {
    return (dispatch) => {
        try {
                dispatch({
                    type: "RECEIVE_SPEED_METER",
                    payload:data
                })
        } catch {
            console.log("Error while getting SPEED METER:");
        }
    }
}

const AllAgentPerformance = (data) => {
    return (dispatch) => {
        try {
             !!data ?  dispatch({
                    type:"RECEIVE_Agent_Performance",
                    payload:data
                }) :  analyticPerformance()
                .then((res) => {
                    dispatch({
                        type:"RECEIVE_Agent_Performance",
                        payload:res.data.data
                    })
                })
                .catch((error) => {
                    console.error("Error RECEIVE_Agent_Performance:", error);
                });
        } catch {
            console.log("Error while getting_Agent_Performance")
        }
    }
}
const getLoginActivity = (data) => {
    return (dispatch) => {
        try {
                dispatch({
                    type: "RECEIVE_LOGIN_ACTIVITY",
                    payload:data
                })
        } catch {
            console.log("Error while getting login activity:");
        }
    }
}
const GetConversionData = (data) => {
    return (dispatch) => {
        try {
             !!data ?  dispatch({
                    type:"RECEIVE_Conversation_Initiated",
                    payload:data
                }) :  conversationStats()
                .then((res) => {
                    dispatch({
                        type:"RECEIVE_Conversation_Initiated",
                        payload:res.data.data
                    })
                })
                .catch((error) => {
                    console.error("Error RECEIVE_Conversation_Initiated:", error);
                });
        } catch {
            console.log("Error while getting RECEIVE_Conversation_Initiated")
        }
    }
}
const getBotData = (data) => {
    return (dispatch) => {
        try {
              !!data? dispatch({
                    type: "RECEIVE_Dashborad_Bot_Builder_Data",
                    payload:data
                }) : AllBotData()
                .then((res) => {
                  dispatch({type: "RECEIVE_Dashborad_Bot_Builder_Data",
                  payload:res.data.data
                });  

                  })
                .catch((error) => console.log(error));
        } catch {
            console.log("Error while getting Dashborad Bot Builder Data:");
        }
    }
}
const whatsappReportData = (data) => {
    return (dispatch) => {
        try {
              !!data?  dispatch({
                    type: "RECEIVE_whatsapp_Report",
                    payload:data
                })  : whatsappReport()
                .then((res) => {
                    console.log(res,88822222)
                    dispatch({type: "RECEIVE_whatsapp_Report",
                    payload:res.data.data
                  });  
  
                    })
                .catch((err)=>console.log(err))
        } catch {
            console.log("Error while getting whatsapp Report:");
        }
    }
}
const getVoiceCustomer = (data) => {
    return (dispatch) => {
        !!data ? dispatch({
            type: "RECEIVE_Dashborad_Voice_of_customer_data",
            payload: data
        }) : AllcustomerVoice()
            .then((res) => {
                dispatch({
                    type: "RECEIVE_Dashborad_Voice_of_customer_data",
                    payload: res.data.data
                });
            })
            .catch((error) => console.log(error));
    };
};

export {
    getVoiceCustomer,
    getBotData,
    whatsappReportData,
    GetConversionData,
    getLoginActivity,
    AllAgentPerformance,
    getSpeedMeter,
    getDashboardOverview,
}