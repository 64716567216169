export default function ContentRenderer({ data }) {
  console.log(data)
  
    if (data.mediaType === 'image') {
      return<img src={data.url} alt="Image" />;
    } else if (data.mediaType === 'document') {
      // Render a link to the document
      return <a href={data.url} target="_blank" rel="noopener noreferrer">View PDF</a>;
    } else if (data.mediaType === 'audio') {
      return (
        <audio controls>
          <source src={data.url} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      );
    } 
    else if (data.mediaType === 'video') {
      return (
            <video controls>
                <source src={data.url} type="video/mp4" />
                Your browser does not support the video element.
            </video> 
      );
    }
    else if (data.messageType === 'template') {
        return (
            <p>
                Template Node
            </p>
        );
      }
      else if (data.messageType === 'api' || data.api) {
        return (
            <p>
                API Node
            </p>
        );
      }
      else if (data.messageType === 'form' || data.form) {
        return (
            <p>
                Form Node
            </p>
        );
      }
      else if (data.messageType === 'message') {
        return (
            <p>
                {data.message}
            </p>
        );
      }
    else {
      return <p className="label-text text-center">Invalid Data</p>;
    }
  }
  