import React from 'react';
import Inbox                          from "../Inbox";
import { assignAgent, assignStatus, getWhatsappChat } from "../Apis";
import WhatsappImage from "../../../assets/svg/social-logos/whatsappLogo.png"
import config from "../../../config";

const Whatsapp = () => {
    let url = config.api.baseUrl + "/message/send"

    let assignStatusFunc=assignStatus
    let assignAgentFunc=assignAgent
    return <Inbox
        url = {url}
        conversations={[]}
        getChat={getWhatsappChat}
        channel={"whatsapp"}
        assignStatusFunc={assignStatusFunc}
        assignAgentFunc={assignAgentFunc}
        channelLogo={WhatsappImage}
    />
}
export default Whatsapp