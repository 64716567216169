import axios from "../../Authentication/headers"

export function getAllSegments(){
  return axios.get(`/segment/all`)
}

export function activateSegment(id){
  return axios.get(`/segment/activate/${id}`)
}

export function deActivateSegment(id){
  return axios.get(`/segment/deactivate/${id}`)
}

export function deleteSegment(id){
  return axios.get(`/segment/delete/${id}`)
}

