import React, { useState } from 'react';
import { DatePicker, Radio , Button} from 'antd'; 
import excelImage from '../../../assets/svg/files/excel.png';
import pdfImage from '../../../assets/svg/files/pdf.png';

const { RangePicker } = DatePicker; 

const ApiDownload = () => {
    const [state, setState] = useState({
        selectedFile: 'pdf',
        exportDateRange: [],
        isLoader: false
    });

    const handleDateRangeChange = (dates, dateStrings) => {
        setState({ ...state, exportDateRange: dates, exportDateFormatted: dateStrings });
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RangePicker value={state.exportDateRange} onChange={handleDateRangeChange} />
                <div style={{ display: 'flex', justifyContent: 'center', gap: 30, marginTop: 30 }}>
                    <Radio.Group
                        value={state.selectedFile}
                        onChange={e => setState({ ...state, selectedFile: e.target.value })}
                        name="radiogroup"
                        defaultValue={state.selectedFile}
                    >
                        <Radio value="pdf">
                            <img style={{ width: 50 }} src={pdfImage} alt="PDF" />
                        </Radio>
                        <Radio value="xlsx">
                            <img style={{ width: 50 }} src={excelImage} alt="Excel" />
                        </Radio>
                    </Radio.Group>
                </div>
                <Button style={{marginTop:"5%"}} /* onClick={exportReportFunc} */ disabled={state.isLoader || !state.exportDateRange || state.exportDateRange.length < 1}>Export</Button>
            </div>
        </>
    );
};

export default ApiDownload;
