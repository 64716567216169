import React, {useState} from 'react'
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader'
import {Input, Button} from 'antd';
import {savePassword} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';
import "./ChangePassword.scss"


const ChangePassword = () => {
    const {t} = useTranslation();
    const [state, setState] = useState({
        oldPassword:"",
        newPassword:"",
        confirmNewPassword:"",
        isLoader:false
    })
console.log(state, "1111111222")
    const saveFunc = () => {
        setState({...state, isLoader: true})
        let data = {
            currentPassword:state.oldPassword,
            newPassword:state.newPassword
        }
        savePassword(data)
            .then(()=>setState({
                ...state,
                isLoader: false
            })).catch(()=>setState({
            ...state,
            isLoader: false
            }))
    }
    return (
        state.isLoader ? <Loader/> :
            <div className='synctic-module-container'>
                <SettingsHeader
                    heading={t("ChangePassword")}
                    description={t("Passwordmustcontainthefollowing")}
                />
                <div className='cards-box' style={{marginTop:"10px"}}>
                <div>
                    <div className='Passwordcannot'
                    >{t("PasswordcannotcontainspacesPassword")}
                    </div>
                    <div style={{fontWeight: "500", marginTop: "1%"}}>{t("CurrentPassword")}</div>
                    <Input.Password
                        style={{width: "44%"}}
                        size="middle"
                        placeholder='Current Password'
                        autoComplete="off"
                        onChange={(e)=>setState({
                            ...state,
                            oldPassword: e.target.value
                        })}
                    />
                    <div>
                        <div>
                            <div style={{fontWeight: "500", marginTop: "1%"}}>{t("NewPassword")}</div>
                            <Input.Password
                                style={{width: "44%"}}
                                size="middle"
                                placeholder='New Password'
                                autoComplete="off"
                                onChange={(e)=>setState({
                                    ...state,
                                    newPassword: e.target.value
                                })}
                            />
                        </div>
                        <div >
                            <div style={{fontWeight: "500", marginTop: "1%"}}>{t("Retry-NewPassword")}</div>
                            <Input.Password
                                style={{width: "44%"}}
                                size="middle"
                                placeholder={t('Retry-NewPassword')}
                                autoComplete="off"
                                onChange={(e)=>setState({
                                    ...state,
                                    confirmNewPassword: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div style={{display:"flex",justifyContent:"flex-end"}}>
                        <Button onClick={()=>saveFunc()} type="primary"> {t("SaveChange")}</Button>
                    </div>
                </div>
                </div>
            </div>
    )
}

export default ChangePassword
