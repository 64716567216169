import {combineReducers} from "redux";
import inboxReducer from "./inboxReducer";
import myProfileReducer from "./myProfileReducer";
import flowReducer from "../../../ChatBotBuilder/states/Reducers/flowReducer";
import dashboardReducers from '../../../Dashboard/states/dashboardReducers';
import isLeftSectionLock from '../../../LeftSection/states/Reducer'



const reducers = combineReducers({
    inbox:inboxReducer,
    me:myProfileReducer,
    chatFlow:flowReducer,
    dashboard:dashboardReducers,
    isLeftSectionLock,
})

export default reducers