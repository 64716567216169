import { MinusCircleOutlined, PlusOutlined }              from "@ant-design/icons";
import { Button, Form, Input, Radio, DatePicker, Select, TimePicker } from 'antd';
import moment                                             from 'moment';
// import TimePicker                                         from 'rc-time-picker';
import React, { useEffect, useState }                     from 'react';
import { useTranslation }         from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";
import { GetAllContacts }         from '../Contacts/Api/Api';
import Loader                                             from '../ResuseableComponnets/Loader';
import openNotificationWithIcon from '../ResuseableComponnets/Toaster';
import { getAllSegments }                                 from '../Segments/APIS/Apis';
import { apiall }                                         from '../Settings/Api_mangement/Api/Api';
import { getAllActivatedTags }                            from '../Settings/Tags/API/Api';
import { getAllTemplates }                             from '../Settings/Templates/API';
import { getCampaign, createCampaign, updateCampaign } from './Api/Api';
import 'rc-time-picker/assets/index.css';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const CreateCampaign = () => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    allTags: [],
    allGroups: [],
    allApis: [],
    selectedCategory: "immediate",
    initData: null,
    loader: false,
    time: "10:00",
    currFormValues: null,
    allTemplates:[],
    allContacts:[],
    allSegments:[]
  })
  const [form] = Form.useForm();
  const { id } = useParams()
  const navigate = useNavigate()

  const getCurTime = (date) => {
    const currentTime = !!date ? new Date(date) : new Date();
    let hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesWithZero = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesWithZero} ${ampm}`
  }
  const onFinish = (values) => {
    let data = {
      name: values.name,
      type: values.type,
      scheduleAt: [],
      recurringAt: {},
      templateId: values.templateId,
      segmentId: values.segmentId
    }

    if(values.type === "immediate"){
      const currentTime = new Date();
      let hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutesWithZero = minutes < 10 ? '0' + minutes : minutes;
      delete data.recurringAt
      data = {
        ...data,
        scheduleAt: [{
          date: moment(new Date()).format("YYYY-MM-DD") ,
          time: getCurTime()
        }]
      }
    } else if(values.type === "recurring"){
      data = {
        ...data,
        // recurringAt: {
        //     date: values.recStrtDate,
        //     time: "string",
        //     repeat: values.recRepeat,
        //     every: values.recEvery,
        //     onWeekly: "mon",
        //     onMonthly: 1
        // }
      }
    } else {
      delete data.recurringAt
      data = {
        ...data,
        scheduleAt: values.scheduleAt.map(item=>({
          date: moment(new Date(item.date)).format("YYYY-MM-DD"),
          time: getCurTime(item.time)
        }))
      }
    }

    let callFunction = !!id ? updateCampaign : createCampaign

    callFunction(id, data).then(()=> {
      navigate("/campaigns");
      openNotificationWithIcon('success',
        'Success Message',
        `Campaign has been ${!!id ? "updated" : "created"}`
      )
    }).catch(()=>openNotificationWithIcon('error',
      'Error Message',
      `Error while ${!!id ? "updating" : "creating"} campaign`
    ))
  }
  const getAllTagsAndGroups = async () => {
    let allActiveTags = await getAllActivatedTags()
    let allApis = await apiall()
    setState(prevState => ({
      ...prevState,
      allTags: !!allActiveTags?.data?.data ? allActiveTags.data.data : [],
      allApis: !!allApis?.data?.data ? allApis.data.data : [],
      loader: false
    }))
  }
  const onValuesChange = (changedValues, allValues) => {
    setState({
      ...state,
      currFormValues: allValues
    })
  };

  let weekdays = [
    { label: "Monday", value: "mon" },
    { label: "Tuesday", value: "tue" },
    { label: "Wednesday", value: "wed" },
    { label: "Thursday", value: "thu" },
    { label: "Friday", value: "fri" },
    { label: "Saturday", value: "sat" },
    { label: "Sunday", value: "sun" }
  ];

  const getAllTemplatesFunc = () => {
    getAllTemplates().then(res=>setState(prevState => ({
      ...prevState,
      allTemplates: res.data.data.map(item=>({
          value:item._id,
          label:item.name
      }))
    }))).then(err=>console.log(err))
  }

  const getAllContactsFunc = () => {
    GetAllContacts().then(res=>setState(prevState => ({
      ...prevState,
      allContacts: res.data.data.map(item=>({
          value:item.name,
          label:item.name
      }))
    }))).then(err=>console.log(err))
  }

  const getAllSegmentsFunc = () => {
    getAllSegments().then(res=>setState(prevState => ({
      ...prevState,
      allSegments: res.data.data.map(item=>({
          value:item._id,
          label:item.name
      }))
    }))).then(()=>{})
  }

  const myFunction = async () => {
    setState({
      ...state, loader: true
    })
    !!id && await getCampaign(id).then(res => setState(prevState => ({
      ...prevState,
      initData: res?.data?.data,
      selectedCategory: res?.data?.data?.type
    }))).catch(err => console.log(err, "Error"))
    await getAllTagsAndGroups()
    await getAllTemplatesFunc()
    await getAllContactsFunc()
    await getAllSegmentsFunc()
  }

  useEffect(() => {
    myFunction()
  }, []);

  return (
    state.loader ? <Loader/> : <div className={ "synctic-module-container" }>
      <Form
        { ...formItemLayout }
        form={ form }
        name="register"
        onFinish={ onFinish }
        scrollToFirstError
        initialValues={ !!state.initData && {
          ...state.initData,
          scheduleAt: state.initData.scheduleAt.map(item => ({
            date: moment(item.date),
            time: moment(item.time, "h:mm A")
          })),
        } }
        layout="vertical"
        style={ { width: '100%' } }
        labelAlign="left"
        labelWrap
        wrapperCol={ { flex: 1 } }
        onValuesChange={ onValuesChange }
      >
        <div className="cards-box">
          <h3> { !!id ? "Update Campaign" : "Create Campaign"} </h3>
          <Form.Item
            name="name"
            label={ t("Name") }
            rules={ [
              { type: 'string' },
              { required: true, message: 'Enter here' },
            ] }
          >
            <Input placeholder="Name"/>
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={ [{ required: true, message: 'Enter here' }] }
          >
            <Radio.Group
              onChange={ (event) => setState({
                ...state,
                selectedCategory: event.target.value,
              }) }
            >
              <Radio value="immediate">Immediate</Radio>
              <Radio value="multipleDate">On Multiple Dates</Radio>
              <Radio value="recurring">Recurring</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        { state.selectedCategory === "multipleDate" && (
          <div className="cards-box" style={ { marginTop: 10 } }>
            <h4>Segment Rules</h4>
            <div style={ { marginTop: 10 } }/>
            <Form.List
              name="scheduleAt"
              rules={ [
                {
                  validator: async (_, names) => {
                    if (!names || names.length === 0) {
                      return Promise.reject(new Error("At least one item is required"));
                    }
                    if (names.length > 5) {
                      return Promise.reject(new Error("You can add up to 5 items only"));
                    }
                  },
                },
              ] }
            >
              { (fields, { add, remove }) => (
                <>
                  { fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={ key }
                      style={ {
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 8,
                        gap:30
                      } }
                    >
                      <Form.Item
                        { ...restField }
                        label="Campaign Scheduled Date"
                        name={ [name, "date"] }
                        style={ { width: "40%" } }
                        rules={ [{ required: true, message: "Select date" }] }
                      >
                        <DatePicker style={ { width: "100%" } }/>
                      </Form.Item>
                      <Form.Item
                        { ...restField }
                        name={ [name, "time"] }
                        label="Campaign Scheduled Time"
                        style={ { width: "40%" } }
                        rules={ [{ required: true, message: "Select time" }] }
                      >
                          <TimePicker
                            style={{
                              width:"100%"
                            }}
                            format={"h:mm A"}
                          />
                      </Form.Item>
                      <div>
                        <MinusCircleOutlined onClick={ () => remove(name) }/>
                      </div>

                    </div>
                  )) }
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={ () => add() }
                      block
                      disabled={form.getFieldValue("scheduleAt")?.length === 5}
                      icon={ <PlusOutlined/> }
                    >
                      Add Item
                    </Button>
                  </Form.Item>
                </>
              ) }
            </Form.List>
          </div>
        ) }

        {
          state.selectedCategory === "recurring" && <div>
            <div className="cards-box" style={ {
              marginTop: 10
            } }>
              <Form.Item
                name="recStrtDate"
                label={ "Start Date" }
              >
                <DatePicker style={ { width: "40%" } }/>
              </Form.Item>
              <Form.Item
                name="recEndDate"
                label={ "End Date" }
              >
                <DatePicker style={ { width: "40%" } }/>
              </Form.Item>
              <Form.Item
                name="recAt"
                label={ "At" }
              >
                <TimePicker
                  defaultValue={moment(`${state.initData?.recAt}`, "hh:m A")}
                  format={"h:mm A"}
                />
              </Form.Item>
              <div style={ {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              } }>
                <Form.Item
                  name="recRepeat"
                  label={ "Repeat" }
                  style={ {
                    width: "40%"
                  } }
                >
                  <Select
                    defaultValue={ "daily" }
                    style={ {
                      width: '100%',
                    } }
                    options={ [
                      {
                        value: 'daily',
                        label: 'Daily',
                      },
                      {
                        value: 'weekly',
                        label: 'Weekly',
                      },
                      {
                        value: 'monthly',
                        label: 'Monthly',
                      },
                    ] }
                  />
                </Form.Item>
                <Form.Item
                  name="recEvery"
                  label={ "Every" }
                  style={ {
                    width: "40%"
                  } }
                >
                  <Select
                    defaultValue={ "" }
                    style={ {
                      width: '100%',
                    } }
                    options={ state.currFormValues?.repeat === "weekly" ? [
                        {
                          value: 'daily1',
                          label: 'Daily 1',
                        }, {
                          value: 'daily2',
                          label: 'Daily 2',
                        }, {
                          value: 'daily3',
                          label: 'Daily 3',
                        }, {
                          value: 'daily4',
                          label: 'Daily 4',
                        }, {
                          value: 'daily5',
                          label: 'Daily 5',
                        }, {
                          value: 'daily6',
                          label: 'Daily 6',
                        }
                      ] :
                      state.currFormValues?.repeat === "daily" ?
                        [{
                          value: "singleDay",
                          label: "Single Day"
                        }] :
                        [{
                          value: "singleMonth",
                          label: "Single Month"
                        }]
                    }
                  />
                </Form.Item>
              </div>

              { state.currFormValues?.repeat === "weekly" &&  <Form.Item
                name="recOn"
                label={ "On" }
                style={ {
                  width: "100%"
                } }
              >
                <Select
                  mode="multiple"
                  placeholder="Select days"
                  style={ { width: "100%" } }
                  options={ weekdays }
                />
              </Form.Item> }

              {
                state.currFormValues?.repeat === "monthly" &&  <Form.Item
                  name="montlyDates"
                  label={ "On" }
                  style={ {
                    width: "100%"
                  } }
                >
                  <DatePicker placeholder="Select days" multiple maxTagCount="responsive" />
                </Form.Item>
              }

            </div>
          </div>
        }
        <div className={"cards-box"} style={{
          marginTop:10
        }}>
          <h5>Message Template</h5>
          <p>Select your message template type</p>
          <Form.Item
            name="whtatsapp-templates"
            label={ "Message type" }
            style={ {
              width: "40%"
            } }
          >
            <Select
              placeholder="Select days"
              value={"whatsapp-templates"}
              defaultValue={"whatsapp-templates"}
              style={ { width: "100%" } }
              options={ [
                {
                  label:"whatsapp-templates",
                  value:"Whatsapp Templates"
                }
              ] }
            />
          </Form.Item>
          <Form.Item
            name="templateId"
            label={ "Message" }
            style={ {
              width: "40%"
            } }
          >
            <Select
              placeholder="Select template"
              style={ { width: "100%" } }
              options={ !!state.allTemplates && state.allTemplates }
            />
          </Form.Item>
        </div>

        <div className={"cards-box"} style={{
          marginTop:10
        }}>
          <h5>Import Segments</h5>
          <Form.Item
            name="segments"
          >
            <Radio.Group>
              <Radio value="ent-audience">Entire audience</Radio>
              <Radio value="active24">Active 24 hours</Radio>
              <Radio value="list">List upload</Radio>
            </Radio.Group>
          </Form.Item>
          {
            state.currFormValues?.segments !== "active24" &&
            <Form.Item
              name="segmentId"
            >
            <Select
              placeholder="Select Segment"
              style={ { width: "40%" } }
              options={ state.currFormValues?.segments === "ent-audience" ? state.allContacts : state.currFormValues?.segments === "list" ? state.allSegments : [] }
            />
            </Form.Item>
          }
          <Form.Item style={{
            width:"40%",
            marginTop:10
          }}>
            {/*<Button style={{*/}
            {/*  float:"right"*/}
            {/*}} type="primary">*/}
            {/*  Import*/}
            {/*</Button>*/}
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            { !!id ? "Update" : "Submit" }
          </Button>
        </Form.Item>
      </Form>

    </div>
  )
};
export default CreateCampaign;