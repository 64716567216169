import React, { useEffect, useState } from "react";
import { BiSolidNavigation } from "react-icons/bi";
import { Radio, Button, Input , Modal} from "antd";
import { RoleCreate, Roleone, roleupdate, roledelete } from "./Api/Api";
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import openNotificationWithIcon from "../../ResuseableComponnets/Toaster";

const CreateRole = () => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
    access: {
      analytic: {
         view: false
       },
      report: {
         view: false 
        },
      botInbox: { 
        view: false
       },
      health: { 
        view: false
       },
      contact: {
         view: false,
          delete: false, 
          manage: false
         },
      channel: {
         view: false,
          delete: false,
           manage: false
           },
      setting: {
         view: false, 
         delete: false,
          manage: false
         },
      users: {
         view: false,
          delete: false,
           manage: false
           },
      teams: {
         view: false,
          delete: false,
           manage: false 
          },
      roles: { 
        view: false,
         delete: false,
          manage: false
         },
      inbox: {
         view: false, 
         delete: false,
          manage: false 
        },
      campaign: {
         view: false, 
         delete: false,
          manage: false
         },
      botBuilder: {
         view: false,
          delete: false,
           manage: false 
          },
      template: {
         view: false,
          delete: false,
           manage: false
           },
    },
    isModalOpen:false
  });

  const showModal = () => {
    setState((prevState) => ({
      ...prevState,
      isModalOpen: true,
    }));
  };


  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      isModalOpen: false,
    }));
  };

  const fetchData = () => {
    Roleone(id)
      .then((res) => {
        const { name, description, access } = res.data.data;
        setState((prevState) => ({
          name,
          description,
          access: {
            ...prevState.access,
            ...access,
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
      });
  };

  const DeleteFunc = () => {
    roledelete(id)
      .then((res) => {
        navigate("/settings/user-roles");
        
        if (res.data && res.data.data) {
          const { name, description, access } = res.data.data;
          setState({
            name: name,
            description: description,
            access: {
              ...state.access,
              ...access,
            },
          });
          openNotificationWithIcon('success', 'Success Message', res.data.message);
        } else {
          openNotificationWithIcon('success', 'Success Message', res.message || 'Role deleted successfully');
        }
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
        openNotificationWithIcon('error', 'Error Message', error.message || 'An error occurred');
      });
  };
  
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
  
  const handleFeatureChange = (feature, permission) => {
    const updatedPermissions = Object.keys(state.access[feature]).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    updatedPermissions[permission] = true;

    setState((prevState) => ({
      ...prevState,
      access: {
        ...prevState.access,
        [feature]: updatedPermissions, 
      },
    }));
  };
  const RoleCreateFunc = () => {
    const { name, description, access } = state;
    const data = {
      name,
      description,
      access: {
        ...access,
      },
    };
    RoleCreate(data)
      .then((res) => {
        navigate("/settings/user-roles");
        if (res.data.data) {
          openNotificationWithIcon('success',
            'Success Message',
            res.data?.message
        )
        }
        else{
          openNotificationWithIcon('success',
            'Success Message',
            res.message
        )
        }
      })
      .catch((error) => console.log(error) );
  };
  

  const RoleUpdateFunc = () => {
    const { name, description, access } = state;
    const data = {
      name,
      description,
      access: {
        ...access,
      },
    };

    roleupdate(id, data)
      .then((res) => {
        navigate("/settings/user-roles");
        if (res.data.data) {
          openNotificationWithIcon('success',
            'Success Message',
            res.data?.message
        )
        }
        else{
          openNotificationWithIcon('success',
            'Success Message',
            res.message
        )
        }
      })
      .catch((error) => console.error("Error updating role:", error));
  };

  const handleClick = () => {
    if (id) {
      RoleUpdateFunc();
    } else {
      RoleCreateFunc();
    }
  };
  return (
    <div className='synctic-module-container'>
<div>
      <div className="cards-box">
        <div>
          <div className="synctic-module-def">
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <BiSolidNavigation className="icon" />
                  <div className="heading">Create Role</div>
                </div>
                <div
                  style={{
                    display:
                      state.access.length > 0 ||
                      state.name.length > 0 ||
                      state.description.length > 0
                        ? "block"
                        : "none",
                  }}
                >
                  <div onClick={() => showModal()}>
                    <div
                    className="delete-role"
                      style={{
                        display: "flex",
                        gap: 5,
                        marginTop: "calc(25% + 20px",
                        padding: "2px 7px 2px 2px"
                      }}
                    >
                      <MdDelete style={{ fontSize: 20, cursor: "pointer" }} />
                      <div style={{ fontSize: 15, fontWeight: "500",cursor:"pointer" }}>
                        Delete Role
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-heading">New Roles</div>
            </div>
          </div>
        </div>
      </div>

      <div className="cards-box" style={{ marginTop: 10 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <div>
            <h4>Name</h4>
            <Input
              placeholder="Basic usage"
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <h4>Description</h4>
            <TextArea
              placeholder="Autosize height based on content lines"
              autoSize={{ minRows: 2, maxRows: 6 }}
              value={state.description}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
            />
          </div>
          <h2 style={{ marginTop: "1%" }}>Permission</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: "2%",
          }}
        >
          {Object.keys(state.access).map((feature) => (
            <div
              key={feature}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <h5>{feature.charAt(0).toUpperCase() + feature.slice(1)}</h5>
              <div style={{ display: "flex" }}>
                {Object.keys(state.access[feature]).map((permission) => (
                  <Radio
                    key={permission}
                    onChange={(e) =>
                      handleFeatureChange(feature, permission, e.target.checked)
                    }
                    checked={state.access[feature][permission]}
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    <h5>{permission.charAt(0).toUpperCase() + permission.slice(1)}</h5>
                  </Radio>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button type="primary" onClick={handleClick}>
            {id ? "Update" : "Save"}
          </Button>
        </div>
      </div>
      <Modal title="Delete Role" open={state.isModalOpen} onOk={()=>DeleteFunc()} onCancel={handleCancel}>
        <p> Deleting this role will remove it and will change affected users' role to member. </p>
      </Modal>
    </div>
    </div>
  )
}

export default CreateRole
