import axios from "../../Authentication/headers"

export function getAllCampaign(){
  return axios.get(`/campaign/all`)
}

export function activate(id){
  return axios.get(`/campaign/activate/${id}`)
}

export function deActivate(id){
  return axios.get(`/campaign/deactivate/${id}`)
}

export function deleteCampaign(id){
  return axios.get(`/campaign/delete/${id}`)
}

export function getCampaign(id){
  return axios.get(`/campaign/${id}`)
}

export function createCampaign(id, data){
  return axios.post(`/campaign/create`, data)
}

export function updateCampaign(id, data){
  return axios.put(`/campaign/update/${id}`, data)
}

