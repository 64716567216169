import axios from "../../Authentication/headers";

export function getAllFlows() {
    return axios.get(`/flow/all`)
}
export function getFlow (id) {
    let ifTemplate =  window.location.pathname.includes('/chat-bot/templates/')
    if(ifTemplate){
        return axios.get(`/templateFlow/${id}`)
    }
    else{
        return axios.get(`/flow/${id}`)
    }    
}

export function activateFlowApi(id) {
    return axios.get(`/flow/activateFlow/${id}`)
}

export function deActivateFlowApi(id) {
    return axios.get(`/flow/deactivateFlow/${id}`)
}

export function deActivateAllFlows(){
    return axios.get(`/flow/deactivateAllFlow`)
}

export function flowTesterMsg(data){
    return axios.post('/flow/tester', data)
}


export function publishFlow(id, data){
    let ifTemplate =  window.location.pathname.includes('/chat-bot/templates/')
    if(ifTemplate){
        return axios.put(`/templateFlow/publishFlow/${id}`, data)
    }
    else{
        return axios.put(`/flow/publishFlow/${id}`, data)
    }  
}

export function saveMessages(id, data){
    let ifTemplate =  window.location.pathname.includes('/chat-bot/templates/')
    if(ifTemplate){
        return axios.put(`/templateFlow/saveMessages/${id}`, data)
    }
    else{
        return axios.put(`/flow/saveMessages/${id}`, data)
    }  
}


// Build from templates
export function getAllTemplatesFlows() {
    return axios.get(`/templateFlow/all`)
}

export function activateTemplateFlowApi(id) {
    return axios.get(`/templateFlow/activateFlow/${id}`)
}

// export function getFlow (id) {
//     return axios.get(`/flow/${id}`)
// }
//

export function deActivateTemplatesFlowApi(id) {
    return axios.get(`/flow/deactivateFlow/${id}`)
}
//
// export function flowMessage(id, data){
//     return axios.put(`/flow/saveMessages/${id}`, data)
// }
//
export function deActivateTemplatesAllFlows(){
    return axios.get(`/templateFlow/deactivateAllFlow`)
}
//
// export function flowTesterMsg(data){
//     return axios.post('/flow/tester', data)
// }