import React, {useEffect, useState} from 'react';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { Input, Button } from 'antd';
import {getPasswordPolicy, savePasswordPolicy} from "./API/Api";
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';
import "./Paaswordpolicy.scss"
const Passwordpolicy = () => {
  const {t} =useTranslation();
  const [state, setState] = useState({
    name: "",
    expirationDays: null,
    reuseCount: null,
    lockoutThreshold: null,
    isLoader:false
  })
  const getFunc = () => {
    setState({...state, isLoader: true})
      getPasswordPolicy()
          .then(res=> {
            let data = res?.data?.data
            setState({
              ...data,
              isLoader: false
            })
          })
          .catch(()=>setState({
            ...state,
            isLoader: false
          }))
  }

  const saveFunc = () => {
    setState({...state, isLoader: true})
      savePasswordPolicy({
        expirationDays: state.expirationDays,
        reuseCount: state.reuseCount,
        lockoutThreshold: state.lockoutThreshold,
        name:state.name
      })
          .then(()=>getFunc())
          .catch(()=>setState({
            ...state,
            isLoader: false
          }))
  }

  useEffect(() => {
    getFunc()
  }, []);
  return (
      state.isLoader ? <Loader/> :
      <div className="synctic-module-container" style={{ display: 'flex', flexDirection: 'column' }}>
        <SettingsHeader
          heading={t("Paaswordpolicy")}
          description={t("Configurepasswordpolicy")}
        />
        <div className='cards-box' style={{marginTop:"10px"}}>
        <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
          <div>
            <div className='Paaswordpolicy-label'>
            <div style={{fontWeight:"600"}}>{t("Paaswordpolicy")}</div>
            <Input
            style={{width:"45%"}}
                onChange={(e)=>setState({
              ...state,
              name: e.target.value
            })} value={state.name} label="Policy name" width="240%" />
            </div>
            <div style={{marginTop:"7px"}}>
                         <div style={{fontWeight:"600"}}>{t("PasswordExpirationperiod")}</div>
            <Input value={state.expirationDays}
            style={{width:"45%"}}
            size="middle"
               onChange={(e)=>setState({
                 ...state,
                    expirationDays: e.target.value
                  })} label="Password Expiration period (Days)" width="180%" />
             </div>
          </div>
          <div>
            <div style={{marginTop:"3px"}}>
            <div style={{fontWeight:"600"}}>{t("PasswordReuseCount")}</div>
            <Input 
            style={{width:"45%"}}
            size="middle"
            value={state.reuseCount} onChange={(e)=>setState({
              ...state,
              reuseCount: e.target.value
            })} label="Password Reuse Count" width="240%" />
            </div>
            <div style={{marginTop:"8px"}}>
            <div style={{fontWeight:"600"}}>{t("AccountLockoutthreshold")}</div>
            <Input
            style={{width:"45%"}}
            size="middle"
             value={state.lockoutThreshold}  onChange={(e)=>setState({
              ...state,
              lockoutThreshold: e.target.value
            })} label="Account Lockout threshold" width="223%" />
            </div>
          </div>
        </div>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <Button onClick={()=>saveFunc()} type="primary">
            {t("Save")}
          </Button>
          </div>
        </div>
      </div>
  );
};

export default Passwordpolicy;
