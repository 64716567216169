import React, { useEffect } from 'react';
import io from 'socket.io-client';
import ActionsCreators from "./Modules/Inbox/states";
import {useDispatch}                                 from "react-redux";
import { getAllWhatsappConversations, updatedInbox } from './Modules/Inbox/states/Actions';

const socket = io('wss://socket.tiebot.io');
const Sockets = () => {
    const dispatch= useDispatch()
    useEffect(() => {
        socket.on('connect', () => {
            console.log('connected to server');
        })
        socket.on('whatsapp_message', (message) => {
            {dispatch(ActionsCreators.updateWhatsappConversation(message))}
        })

        socket.on('whatsapp_inbox', (message) => {
            console.log('whatsapp_inbox', message)
            {dispatch(ActionsCreators.updateWhatsappAllConversation(message))}
        })

        socket.on('new_messenger_message', (message) => {
            {dispatch(ActionsCreators.updateMessengerConversation(message))}
        })

        socket.on('reloadInbox', () => {
            {dispatch(ActionsCreators.getAllWhatsappConversations())}
        })

        socket.on('updatedInbox', (inboxObject) => {
            console.log('updatedInbox', inboxObject)
            {dispatch(ActionsCreators.updatedInbox(inboxObject))}
        })

        socket.on('markAsRead', (readMessage) => {
            {dispatch(ActionsCreators.markAsReadAction(readMessage))}
        })

        socket.on('markAsDelivered', (readMessage) => {
            {dispatch(ActionsCreators.markAsDeliveredAction(readMessage))}
        })

        socket.on('markAsSent', (sentMessaage) => {
            {dispatch(ActionsCreators.markAsSent(sentMessaage))}
        })

        socket.on('forwardChat', (forwardChat) => {
            {dispatch(ActionsCreators.addForwardChat(forwardChat))}
        })

        socket.on('disconnect', () => {
            console.log('Socket disconnecting');
        })

    }, []);
};

export default Sockets;