
import {Form, Input, Button} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import CustomSelectBox from "./fields/option";
import generateLoopBack from "../hooks/generateLoopBack";
import {useNodes} from "../../allnode";
import { useEffect, useState } from "react";

export default function Loopback({showMessage}){
    const {nodes, showEdit, edges} = useNodes();
    const [loopBackList, setLoopBackList] = useState(null);
    useEffect(()=>{
        setLoopBackList([])
        generateLoopBack(nodes, edges, showEdit)
        .then((val)=>{
            setLoopBackList(val)
        })
    },[])
    if(!window.location.pathname.includes('/chat-bot/templates')){
        return(
            <>
                {loopBackList === null && <span>Getting Loopback list</span>}
                {loopBackList?.length === 0 ? <span className="grey-text">Loopback not found</span>:
                <div
                className={'message-loopback-section message-inner-section form-section active'}>
                <h4 className="sub-heading" style={{marginBottom:10, textAlign:'start'}}>Assign Loopback</h4>
                <Form.List name="loopback" initialValue={[{code:'', loopback : ''}]}>
                    {(fields, { add, remove }) => (
                        <>
                        {fields.map((field, index) => (
                            <div key={field.key} className="loop-back-row mb-4" >
                                <div className="d-flex align-items-center w-100 mb-3">
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'code']}
                                        className="style-input w-100 mr-2"
                                    >
                                        <Input placeholder="Enter Loop back code" />
                                    </Form.Item>
                                    <CustomSelectBox
                                        {...field}
                                        options={loopBackList}
                                        name={[field.name, 'loopback']}
                                        id={`loopId${index}`}
                                        className="style-select w-100 ml-2"
                                        label="Select loopback"
                                    />
                                </div>
                                <div>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'description']}
                                        className="style-input"
                                    >
                                    <Input placeholder="Enter to M show main menu" />
                                    </Form.Item>
                                </div>
                            
                            </div>
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'center', gap: 10, marginTop: 10 }}>
                        <div> 
                        
                        {fields.length == 0 ? <h3>Add Loopback</h3> : (
                            <Button
                                type="primary"
                                icon={<MinusCircleOutlined />}
                                onClick={() => remove(fields.length - 1)}
                                className="mx-3"
                            />
                            )}
                            <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add()}
                            className="mx-3"
                            />
                            </div>
                        </div>
                        </>
                    )}
            </Form.List>
    
                </div>
                }
            </>
        )
    }
    else if(showMessage){
        return(
            <div className="grey-text text-center my-4 text-uppercase">Loopback not available</div>
        )
    }
  
}