import React, { useState } from "react";
import "./Dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tag } from "antd";
import { useDispatch } from "react-redux";
import {
  filterSumSearch,
  agentPerformanceFilter,
  conversationFilter,
  botStatsFilter,
  CustomerVoiceFilter,
} from "./components/Api/Api";
import Overview from "./components/overview/overview";
import { FilterFilled, ExportOutlined } from "@ant-design/icons";
import TiebotDateFilters from "./components/reuseableComponents/TiebotDateFilters";
import AgentPerformance from "./components/AgentPerformance/AgentPerformance";
import TuneIcon from "@mui/icons-material/Tune";
import AgentConversations from "./components/AgentConversations/AgentConversations";
import VoiceOfCustomer from "./components/VoiceOfCustmer/VoiceOfCustmer";
import BotConversataions from "./components/BotConversataions/BotConversataions";
import WhatsappInsights from "./components/WhatsappInsights/WhatsappInsights";
import {
  getDashboardOverview,
  AllAgentPerformance,
  GetConversionData,
  getBotData,
  getVoiceCustomer,
} from "./states/actions";
import { 
    useTranslation
 } from
  "react-i18next";

const Dashboard = () => {
    const {t} = useTranslation()
  const [state, setState] = useState({
    activeTab: "1",
    isFilterOpen: false,
    startDate: "",
    endDate: "",
    isModalOpen: false,
    mode: "",
    filterDuration: "",
  });
  const dispatch = useDispatch();

  const items = [
    {
      key: "1",
      label: t("Overview"),
    },
    {
      key: "2",
      label: t("Agent Performance"),
    },
    {
      key: "3",
      label: t("Agent Conversations"),
    },
    {
      key: "4",
      label: t("Voice Of Customer"),
    },
    {
      key: "5",
      label: t("Bot Conversations"),
    },
    /*  {
            key: '6',
            label: 'Whatsapp Insights'
        }, */
  ];

  function handleTabChange(key) {
    setState({ ...state, activeTab: key, filterDuration: "" });
  }

  const closeFilterModal = (start, end, duration) => {
    let data =
      duration === "custom"
        ? {
            startDate: start,
            endDate: end,
          }
        : {
            filterDuration: duration,
          };

    if (duration === "clear") {
      dispatch(getDashboardOverview());
      setState({
        ...state,
        isFilterOpen: !state.isFilterOpen,
        filterDuration: duration,
      });
    } else {
      filterSumSearch(data)
        .then((res) => {
          console.log(res, 7765444);
          setState({
            ...state,
            isFilterOpen: !state.isFilterOpen,
            filterDuration: duration,
            startDate: start,
            endDate: end,
          });
          dispatch(getDashboardOverview(res?.data?.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const PerformanceFilter = (start, end, duration) => {
    let data =
      duration === "custom"
        ? {
            startDate: start,
            endDate: end,
          }
        : {
            filterDuration: duration,
          };

    if (duration === "clear") {
      dispatch(AllAgentPerformance());
      setState({
        ...state,
        isFilterOpen: !state.isFilterOpen,
        filterDuration: duration,
      });
    } else {
      agentPerformanceFilter(data)
        .then((res) => {
          console.log(res, 73543545546123);
          setState({
            ...state,
            isFilterOpen: !state.isFilterOpen,
            filterDuration: duration,
            startDate: start,
            endDate: end,
          });
          dispatch(AllAgentPerformance(res?.data?.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const conversationFilterData = (start, end, duration) => {
    let data =
      duration === "custom"
        ? {
            startDate: start,
            endDate: end,
          }
        : {
            filterDuration: duration,
          };

    if (duration === "clear") {
      dispatch(GetConversionData());
      setState({
        ...state,
        isFilterOpen: !state.isFilterOpen,
        filterDuration: duration,
      });
    } else {
      conversationFilter(data)
        .then((res) => {
          console.log(res, 73543545546123);
          setState({
            ...state,
            isFilterOpen: !state.isFilterOpen,
            filterDuration: duration,
            startDate: start,
            endDate: end,
          });
          dispatch(GetConversionData(res?.data?.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const botStatsFilterData = (start, end, duration) => {
    let data =
      duration === "custom"
        ? {
            startDate: start,
            endDate: end,
          }
        : {
            filterDuration: duration,
          };

    if (duration === "clear") {
      dispatch(getBotData());
      setState({
        ...state,
        isFilterOpen: !state.isFilterOpen,
        filterDuration: duration,
      });
    } else {
      botStatsFilter(data)
        .then((res) => {
          console.log(res, 73543545546123);
          setState({
            ...state,
            isFilterOpen: !state.isFilterOpen,
            filterDuration: duration,
            startDate: start,
            endDate: end,
          });
          dispatch(getBotData(res?.data?.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const VoiceCustomer = (start, end, duration) => {
    let data =
      duration === "custom"
        ? {
            startDate: start,
            endDate: end,
          }
        : {
            filterDuration: duration,
          };

    if (duration === "clear") {
      dispatch(getVoiceCustomer());
      setState({
        ...state,
        isFilterOpen: !state.isFilterOpen,
        filterDuration: duration,
      });
    } else {
      CustomerVoiceFilter(data)
        .then((res) => {
          console.log(res, 73543545546123);
          setState({
            ...state,
            isFilterOpen: !state.isFilterOpen,
            filterDuration: duration,
            startDate: start,
            endDate: end,
          });
          dispatch(getVoiceCustomer(res?.data?.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const modalOpenFunc = () => {
    setState({ ...state, isModalOpen: !state.isModalOpen, mode: "" });
  };

  const changeMode = () => {
    setState({ ...state, mode: "" });
  };
  return (
    <div
      className="synctic-module-container"
      style={{ height: `calc(100vh - 5rem)` }}
    >
      <div
        className="cards-box"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          defaultActiveKey={state.activeTab}
          items={items}
          onChange={handleTabChange}
        />
        <div
          style={{
            display: "flex",
            gap: 14,
            alignItems: "center",
          }}
        >
          {state.filterDuration !== "clear" && (
            <Tag
              color="magenta"
              style={{
                display: state.filterDuration.length > 0 ? "block" : "none",
              }}
            >
              {state.filterDuration === "custom"
                ? `${state.filterDuration}  ${state.startDate} to ${state.endDate}`
                : state.filterDuration}
            </Tag>
          )}
          <TuneIcon
            onClick={() =>
              setState({ ...state, isModalOpen: true, mode: "filter" })
            }
            style={{
              fontSize: 20,
              display: "none",
              cursor: "pointer",
            }}
          />
          <ExportOutlined
            onClick={() =>
              setState({ ...state, isModalOpen: true, mode: "export" })
            }
            style={{ fontSize: 20, display: "none" }}
          />
          <FilterFilled
            style={{
              fontSize: 20,
              display:
                state.activeTab === "1" ||
                state.activeTab === "2" ||
                state.activeTab === "3" ||
                state.activeTab === "5" ||
                state.activeTab === "4"
                  ? ""
                  : "none",
            }}
            onClick={() =>
              setState({
                ...state,
                isFilterOpen: !state.isFilterOpen,
                mode: "timeFilter",
              })
            }
          />
        </div>
      </div>
      {state.activeTab === "1" && (
        <Overview
          title={state.activeTab}
          modalOpenFunc={modalOpenFunc}
          isModalOpen={state.isModalOpen}
        />
      )}
      {state.activeTab === "2" && (
        <AgentPerformance
          title={state.activeTab}
          modalOpenFunc={modalOpenFunc}
          isModalOpen={state.isModalOpen}
          mode={state.mode}
        />
      )}
      {state.activeTab === "3" && (
        <AgentConversations
          title={state.activeTab}
          modalOpenFunc={modalOpenFunc}
          isModalOpen={state.isModalOpen}
          mode={state.mode}
        />
      )}
      {state.activeTab === "4" && (
        <VoiceOfCustomer
          title={state.activeTab}
          modalOpenFunc={modalOpenFunc}
          isModalOpen={state.isModalOpen}
          mode={state.mode}
        />
      )}
      {state.activeTab === "5" && (
        <BotConversataions
          title={state.activeTab}
          modalOpenFunc={modalOpenFunc}
          isModalOpen={state.isModalOpen}
          mode={state.mode}
        />
      )}

      {state.activeTab === "6" && (
        <WhatsappInsights
          title={state.activeTab}
          modalOpenFunc={modalOpenFunc}
          isModalOpen={state.isModalOpen}
          mode={state.mode}
        />
      )}

      <TiebotDateFilters
        isFilterOpen={state.isFilterOpen}
        closeFilterModal={
          state.activeTab === "1"
            ? closeFilterModal
            : state.activeTab === "2"
            ? PerformanceFilter
            : state.activeTab === "3"
            ? conversationFilterData
            : state.activeTab === "5"
            ? botStatsFilterData
            : state.activeTab === "4"
            ? VoiceCustomer
            : null
        }
        defaultValue={state.filterDuration}
        closeModal={() =>
          setState({ ...state, isFilterOpen: !state.isFilterOpen })
        }
      />
    </div>
  );
};
export default Dashboard;
