import { UnorderedListOutlined }                                                           from "@ant-design/icons";
import AttachFileIcon
                                                                                           from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon
                                                                                           from '@mui/icons-material/EmojiEmotions';
import SendIcon
                                                                                           from '@mui/icons-material/Send';
import ListItem
                                                                                           from "@mui/material/ListItem";
import ListItemText
                                                                                           from "@mui/material/ListItemText";
import Popover                                                                             from '@mui/material/Popover';
import TextField
                                                                                           from '@mui/material/TextField';
import { Dropdown, Menu, Modal, Upload, Drawer, Tabs, Radio, Tooltip, DatePicker, Button } from "antd";
import axios                                                                               from 'axios';
import EmojiPicker                                                                         from 'emoji-picker-react';
import moment                                                                              from "moment";
import React, { useState, useEffect, useRef }                                              from "react";
import "./Inbox.scss";
import { FaArrowLeft }                                                                     from "react-icons/fa";
import { useDispatch, useSelector }                                                        from "react-redux";
import { useLocation }                                                                     from 'react-router-dom';
import excelImage
                                                                                           from '../../assets/svg/files/excel.png';
import pdfImage
                                                                                           from '../../assets/svg/files/pdf.svg';
import Loader
                                                                                           from "../ResuseableComponnets/Loader";
import openNotificationWithIcon
                                                                                           from "../ResuseableComponnets/Toaster";
import {
  getAllQuickReply
}                                                                                          from "../Settings/Quickreply/API/Api";
import AllConversations                                                                    from "./AllConversations";

import {
  aiGenerateMsg,
  exportReport,
  getForwardChats,
  getInboxContactInformation,
  getChatData,
  openedConversations, getAllChats
} from "./Apis";
import ChatPanel          from "./ChatPanel/chatPanel";
import ContactInfo        from "./ContactInfo";
import { inboxActiveTab } from './states/Actions';
import Audio              from './Audio';
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const Inbox = (props) => {
  const {t} = useTranslation()
  const [state, setState] = useState({
    attachmentsModal: false,
    newChatSelection: false,
    showChat: false,
    open: false,
    allConversation: null,
    replyMesssage: "",
    attachments: [],
    channel: 'WhatApp',
    chatPanelLoader: false,
    filterName: "1",
    allQuickReply: [],
    fileList: [],
    openDrawer: false,
    mode: '',
    selectedFile: 'pdf',
    exportDateRange: [],
    exportDateFormatted: [],
    isLoader: false,
    conatactFilter: '1',
    interactiveHistory: [],
    collapse: true,
    isChatExpired: true,
    isAudioBlob: false,
    removeFiles: false,
    showBottomSection: false,
    allForwardChats: [],
    allChatInfo: null,
    myAllChats: [],
    allConversationsLoader: false,
    inProgressAndOpened: [],
    unSelectInteraction: false,
    activeTab: 'all'
  })

  const [anchorEl, setAnchorEl] = React.useState(null);

  const location = useLocation()
  const dispatch = useDispatch()
  const pathName = location.pathname.split('/inbox/')[1]
  let channelName = !!pathName && pathName.split('/')[0]
  let filterName = !!pathName && pathName.split('/')[1]
  const fileInputRef = useRef(null);
  const handleChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.slice(-1);

    setState({ ...state, fileList: fileList });
  };

  const handleFileChange = async (e) => {
    const formData = new FormData();
    Array.from(e.target.files).forEach((file) => {
      formData.append(`media`, file);
    });

    const dataObject = {
      body: {
        conversationId: state.allChatInfo?.conversationId,
        message: state.replyMesssage,
      },
    };

    formData.append('data', JSON.stringify(dataObject.body));
    await axios.post(props.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
      }
    })
      .then(() => {
        setState({ ...state, replyMesssage: '', openDrawer: false, removeFiles: false, fileList: [] })
      })
      .catch((err) => {
        console.log(err, 'Err')
      });
    e.target.value=null
  };

  const unSelectInteractionFunc = () => {
    setState({ ...state, unSelectInteraction: !state.unSelectInteraction })
  }

  const multimediaSendFunc = () => {
    const formData = new FormData();
    Array.from(state.fileList).forEach((file) => {
      formData.append(`media`, file.originFileObj);
    });

    const dataObject = {
      body: {
        conversationId: state.allChatInfo?.conversationId,
        message: state.replyMesssage,
      },
    };

    formData.append('data', JSON.stringify(dataObject.body));
    axios.post(props.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
      }
    })
      .then(() => {
        setState({ ...state, replyMesssage: '', fileList: [], openDrawer: false, removeFiles: false })
      })
      .catch((err) => {
        console.log(err, 'Err')
      });
  }

  const handleUpload = () => {
    setState({ ...state, replyMesssage: '' })
    let formData = new FormData()
    const dataObject = {
      body: {
        conversationId: state.allChatInfo?.conversationId,
        message: state.replyMesssage,
      },
    };

    formData.append('data', JSON.stringify(dataObject.body));
    !!state.replyMesssage && axios.post(props.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
      }
    })
      .then(() => setState({ ...state, replyMesssage: '', removeFiles: false, fileList: [] }))
      .catch((err) => {
        openNotificationWithIcon('error',
          'Error Message',
          err?.response?.data?.message
        )
      });
  }

  const changeInboxTab = (value) => {
    setState({ ...state, activeTab: value })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const showChat = (value) => {
    setState({ ...state, showChat: value, newChatSelection: false })
  }

  // const getAllConversation = (data) => {
  //   setState({ ...state, showChat: true, allConversation: data })
  // }
  const onEmojiClick = (event) => {
    setState({
      ...state, replyMesssage: state.replyMesssage + event.emoji
    })
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleUpload();
    }
  };

  const loaderInChatPanel = (value) => {
    setState({ ...state, chatPanelLoader: value })
  }
  const onChange = (key) => {
    setState(prevState => ({ ...prevState, activeTab: key }))
    unSelectInteractionFunc()
    setState({ ...state, filterName: key, allConversationsLoader: true, showChat: false })
    key === 'all' && getOpenAndInProgress()
    key === 'forward' && setState({ ...state, allConversationsLoader: true })
    key === 'forward' && dispatch(inboxActiveTab('forward'))
    key === 'forward' && getForwardChats().then(response => {
      setState({
        ...state,
        myAllChats: response.data.data,
        allConversationsLoader: false,
        showChat: false,
        activeTab: key
      })
      dispatch({
        type: "GET_ALL_WHATSAPP_CONVERSATIONS",
        payload: response.data.data
      })
    }).catch(() => setState({ ...state, allConversationsLoader: false, showChat: false, activeTab: key }))
    key !== 'all' && key !== 'forward' && getOpenedConversations(key)
  };

  const getOpenAndInProgress = async () => {
    setState({ ...state, myAllChats: [], allConversationsLoader: true, showChat: false })
    dispatch(inboxActiveTab('all'))
    await getAllChats().then(res => {
      dispatch({
        type: "GET_ALL_WHATSAPP_CONVERSATIONS",
        payload: [...res.data.data]
      })
      setState({
        ...state,
        allConversationsLoader: false,
        showChat: false,
        myAllChats: [...res.data.data],
        activeTab: 'all'
      })}).catch(() => setState({
      ...state,
      allConversationsLoader: false,
      showChat: false
    }))
  }
  const onChangeContacts = (key) => {
    setState({ ...state, conatactFilter: key }
    )
    key === '2' && getInboxContactInformation(state?.allChatInfo?.conversationId)
      .then(res => {
        setState(prevState => ({ ...state, interactiveHistory: res.data?.data, conatactFilter: '2' }))
      })
      .catch(err => console.log(err, 'Error'))
  };
  const quickReplyFunc = () => {
    getAllQuickReply().then(res => setState({
      ...state,
      allQuickReply: res.data.data
    })).catch(err => console.log(err))
  }

  const exportReportFunc = () => {
    setState({ ...state, isLoader: true })
    let data = {
      startDate: new Date(state.exportDateRange[0]),
      endDate: new Date(state.exportDateRange[1]),
      exportType: state.selectedFile,
      conversationId: state?.allConversation?.conversationId
    }
    exportReport(data)
      .then(res => {
        setState({
          ...state,
          exportDateRange: [],
          attachmentsModal: false,
          isLoader: false
        })
        window.location.href = res.data.data.file;
        openNotificationWithIcon(
          'success',
          'Success message',
          res.data?.message
        )
      })
      .catch(err => console.log(err, 'Error'))
  }

  const items = [
    {
      key: 'all',
      label: t('All')
    },
    {
      key: 'in-progress',
      label: t('In Progress')
    },
    {
      key: 'open',
      label: t('Opened')
    },
    {
      key: 'waiting',
      label: t('Waiting')
    },
    {
      key: 'solved',
      label: t('Closed')
    },
    {
      key: 'spam',
      label: t('Spam')
    },
    {
      key: 'forward',
      label: t('Forwarded')
    },
    {
      key: 'exit',
      label: t('Exit')
    }
  ];

  const BotTabs= [
    {
      key: 'all',
      label: t('All')
    }
  ]

  const contactTabs = [
    {
      key: '1',
      label: t('Profile')
    },
    {
      key: '2',
      label: t('History')
    }
  ]


  const handleDateRangeChange = (dates, dateStrings) => {
    setState({ ...state, exportDateRange: dates, exportDateFormatted: dateStrings })
  };

  let myId = useSelector(state => state?.me?.me?.id)
  let showMessageBar = state.allChatInfo?.assign?.to?.id === myId
  const showMessageBarFunc = () => {
    // console.log()
  }

  const getOpenedConversations = (status) => {
    setState(prevState => ({ ...prevState, allConversationsLoader: true, activeTab: status }))
    dispatch(inboxActiveTab(status))
    openedConversations(status).then(response => {
        setState({
          ...state,
          myAllChats: response.data.data,
          allConversationsLoader: false,
          showChat: false,
          activeTab: status
        })
        dispatch({
          type: "GET_ALL_WHATSAPP_CONVERSATIONS",
          payload: response.data.data,
        })
      }
    ).catch(() => setState({
      ...state,
      allConversationsLoader: false,
      showChat: false,
      activeTab: status
    }))
  }

  const openRightSection = () => {
    setState({
      ...state,
      openDrawer: true,
      mode: '',
      selectedFile: 'pdf',
      conatactFilter: '1'
    })
  }
  const openRangePicker = () => {
    setState({
      ...state,
      attachmentsModal: true,
      mode: '',
      selectedFile: 'pdf'
    })
  }

  const aiGenerateMsgFunc = (data) => {
    aiGenerateMsg({ question: data }).then(res => setState({
      ...state,
      replyMesssage: res.data?.data?.aiReply
    })).catch(() => console.log())
  }

  const assignedAgentToInteraction = (data) => {
    setState({ ...state, showBottomSection: data, chatPanelLoader: false })
  }

  const getAllChat = async (id, _id, contactInfo, response) => {
    console.log()
  }

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const getChatInformation = async (id, loader) => {
    setState({ ...state, chatPanelLoader: loader ? loader : false })
    await getChatData(id).then(response => {
        setState({
          ...state,
          allChatInfo: response.data.data,
          showChat: true,
          chatPanelLoader: false
        })
      }
    ).catch(() => console.log())
  }
  useEffect(() => {
    quickReplyFunc()
    setState({
      ...state,
      channel: channelName,
      filterName: filterName
    })
  }, []);

  const handleButtonClick = () => {
    // Trigger file input click event
    fileInputRef.current.click();
  };

  const isBlobExist = (value) => {
    setState({...state, isAudioBlob: value})
  }

  useEffect(() => {
    getOpenAndInProgress()
  }, [])

  return <div className={ 'synctic-module-container' } style={ { height: 'calc( 100vh - 4rem)' } }>
    <div className={ 'cards-box tab-container' } style={ { padding: 0 } }>
      <Tabs
        defaultActiveKey={ state.activeTab }
        activeKey={state.activeTab}
        items={ props.isBotInbox ? BotTabs : items }
        onChange={ !props.isBotInbox && onChange }
        style={ { paddingLeft: 24, paddingRight: 24 } }
      />
    </div>
    <div className="chat-main-container">
      <div style={ { position: 'relative', overflow: 'visible' } }
           className={ `chat-left-panel cards-box ${ state.collapse ? 'collapse' : "" }` }>
        <Button
          style={ {
            position: 'absolute',
            zIndex: 1,
            right: -20,
            bottom: 90,
            background: '#10163a'
          } }
          icon={ <FaArrowLeft color={ 'white' } style={ {
            color: 'white',
            transform: !state.collapse ? 'rotate(180deg)' : ''
          } }/> }
          onClick={ () => {
            setState({ ...state, collapse: !state.collapse });
          } }
        />
        <AllConversations
          conversations={ state.myAllChats }
          showChat={ showChat }
          // getAllConversation={ getAllConversation }
          getChat={ props.getChat }
          channel={ props.channel }
          channelLogo={ props.channelLogo }
          loaderInChatPanel={ loaderInChatPanel }
          filterName={ state.filterName }
          getAllChat={ getAllChat }
          getChatInformation={ getChatInformation }
          dispatch={ dispatch }
          allConversationsLoader={ state.allConversationsLoader }
          unSelectInteraction={ state.unSelectInteraction }
          activeTab={ state.activeTab }
          myId={myId}
          isBotInbox = {props.isBotInbox}
        />
      </div>
      {
        state.chatPanelLoader ? <Loader width={ '75%' }/> :
          !!state.allChatInfo && !!state.showChat &&
          <div
            className="chat-right-panel cards-box"
          >
            <ChatPanel
              conversations={ state.myAllChats }
              assignStatusFunc={ props.assignStatusFunc }
              assignAgentFunc={ props.assignAgentFunc }
              channel={ props.channel }
              loaderInChatPanel={ loaderInChatPanel }
              openRightSectionOpen={ openRightSection }
              openRangePicker={ openRangePicker }
              aiGenerateMsgFunc={ aiGenerateMsgFunc }
              getAllChat={ getAllChat }
              showMessageBar={ showMessageBar }
              showMessageBarFunc={ showMessageBarFunc }
              isBotInbox={ props?.isBotInbox }
              getChat={ props.getChat }
              assignedAgentToInteraction={ assignedAgentToInteraction }
              showBottomSection={ state.showBottomSection }
              getChatInformation={ getChatInformation }
              allChatInfo={ state.allChatInfo }
              tabId={ state.filterName }
              showChat={ showChat }
              changeInboxTab={ changeInboxTab }
            />
            {
              !props?.isBotInbox &&
              showMessageBar &&
              state.allChatInfo.status !== 'spam' &&
              state.allChatInfo.status !== 'solved' &&
              state.activeTab !== 'forward' &&
              state.allChatInfo.status !== 'open' &&
              <div style={ {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
                width: "100%",
                gap: 10,
                paddingBottom: 6
              } }>
                <div style={ { display: "flex", alignItems: "center", gap: 10, width: "100%" } }>
                  {
                    !state.isAudioBlob &&
                    <Dropdown overlay={ (
                      <Menu>
                        {
                          state?.allQuickReply.length > 0 &&
                          state?.allQuickReply.map((item, idx) => <Menu.Item key={ idx }>
                              <div onClick={ () => {
                                setState({ ...state, replyMesssage: item.name })
                              }
                              }> { item.name } </div>
                            </Menu.Item>
                          )
                        }
                      </Menu>
                    ) } trigger={ ['click'] }>
                      <a onClick={ (e) => e.preventDefault() }>
                        <Tooltip
                          placement="top"
                          title={ "Quick Replies" }>
                          <UnorderedListOutlined style={ {
                            fontSize: '14px',
                            cursor: 'pointer'
                          } }/>
                        </Tooltip>
                      </a>
                    </Dropdown> }

                  {
                    !state.isAudioBlob && <Tooltip
                      placement="top"
                      title={ "Add attachment" }>
                      {/*<MoreOutlined onClick={ () => setState({*/ }
                      {/*  ...state,*/ }
                      {/*  openDrawer: true,*/ }
                      {/*  removeFiles: true,*/ }
                      {/*  mode: 'attachment'*/ }
                      {/*}) } style={ {*/ }
                      {/*  fontSize: 22,*/ }
                      {/*  opacity: 0.8,*/ }
                      {/*  cursor: 'pointer'*/ }
                      {/*} }/>*/ }
                      <input
                        type="file"
                        ref={ fileInputRef }
                        style={ { display: 'none' } }
                        multiple
                        onChange={ handleFileChange }
                      />
                      <AttachFileIcon onClick={ handleButtonClick }
                                      style={ { cursor: 'pointer', transform: 'rotate(45deg)' } }/>
                    </Tooltip> }
                  {
                    !state.isAudioBlob &&
                    <Tooltip
                      placement="top"
                      title={ "Add emoji" }>
                      <EmojiEmotionsIcon
                        aria-describedby={ id }
                        variant="contained"
                        onClick={ handleClick }
                        style={ {
                          fontSize: 18,
                          opacity: .8,
                          cursor: 'pointer'
                        } }/>
                    </Tooltip>
                  }
                  {
                    !state.isAudioBlob &&
                    <Popover
                      id={ id }
                      open={ open }
                      anchorEl={ anchorEl }
                      onClose={ handleClose }
                      anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'left',
                      } }
                    >
                      <EmojiPicker onEmojiClick={ onEmojiClick }/>
                    </Popover>
                  }
                  {
                    !state.isAudioBlob &&
                    <TextField
                      id="outlined-size-small"
                      placeholder="Enter message"
                      size="small"
                      style={ { width: "92%" } }
                      value={ state.replyMesssage }
                      onKeyDown={ handleKeyDown }
                      onChange={ (e) => setState({ ...state, replyMesssage: e.target.value }) }
                    />
                  }
                </div>
                {/*<Audio*/}
                {/*  isBlobExist={isBlobExist}*/}
                {/*  data={{*/}
                {/*    conversationId: state.allChatInfo.conversationId,*/}
                {/*    message: state.replyMesssage,*/}
                {/*  }}*/}
                {/*  url={props.url}*/}
                {/*/>*/}

                <SendIcon onClick={ !!state.replyMesssage ? handleUpload : null }
                          style={ {
                            fontSize: 28,
                            color: 'rgb(4, 200, 200)',
                            cursor: state.replyMesssage.trim() === '' ? '' : 'pointer',
                            opacity: state.replyMesssage.trim() === '' ? .3 : 1
                          } }/>
              </div>
            }
          </div>
      }

      <Drawer closable={ state.mode !== 'attachment' && false }
              height={ state.mode !== 'attachment' && 200 }
              placement={ state.mode !== 'attachment' ? 'right' : 'bottom' }
              title={ !state.mode === 'attachment' ? "Contact Information" : null }
              onOpen={ () => setState({
                ...state,
                conatactFilter: '1'
              }) }
              onClose={ () => {
                setState({
                  ...state,
                  openDrawer: false,
                  fileList: [],
                  conatactFilter: null,
                  removeFiles: false
                })
              } }
              open={ state.openDrawer }
      >
        <div style={ { height: '100%', overflowWrap: 'anywhere' } }>
          {
            state.mode !== 'attachment' && state.conatactFilter !== null ?
              <div>
                <Tabs
                  defaultActiveKey={ state.conatactFilter }
                  items={ contactTabs }
                  onChange={ onChangeContacts }
                  indicatorSize={ (origin) => origin - 16 }
                  style={ { paddingLeft: 24, paddingRight: 24 } }
                />
                {
                  state.conatactFilter === '1' ? !!state.allChatInfo && !!state.showChat &&
                    <ContactInfo
                      contactInfo={ state.allChatInfo }
                      showMessageBar={ showMessageBar }
                      getChatInformation={getChatInformation}
                    /> :
                    <ListItem alignItems="flex-start" style={ {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                      height: 'calc(100vh - 7rem)',
                      overflowY: 'scroll',
                      overflowWrap: 'anywhere'
                    } }>
                      {
                        !!state.interactiveHistory?.interactiveHistory && state.interactiveHistory.interactiveHistory.map((item, idx) =>
                          <ListItemText
                            primary={ <div style={ {
                              fontWeight: 600,
                              fontSize: 14,
                              opacity: .8
                            } }>{ item?.assignBy?.email }</div> }
                            secondary={ <div key={ idx }>
                              <div style={ {
                                fontSize: 13,
                                fontWeight: 550
                              } }>Topic: { item?.topic }</div>
                              <div style={ {
                                fontSize: 13,
                                fontWeight: 550
                              } }>Duration: { item?.ticketDuration }</div>
                              <div style={ {
                                fontSize: 13,
                                fontWeight: 550
                              } }>Start time: {
                                moment(item?.ticketStartTime).format('MMMM Do YYYY, h:mm a')
                              }</div>
                              <div style={ {
                                fontSize: 13,
                                fontWeight: 550
                              } }>End time: {
                                moment(item?.ticketEndTime).format('MMMM Do YYYY, h:mm a')
                              }</div>
                              <hr/>
                            </div>
                            }
                            style={ { width: '100%' } }
                          />)
                      }
                    </ListItem>
                }

              </div>
              :
              state.mode === 'attachment' && state.removeFiles &&
              <div style={ {
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                flexDirection: 'column'
              } }>
                <div style={ { display: 'flex', gap: 10, alignItems: 'center' } }>
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture"
                    defaultFileList={ [...state.fileList] }
                    className="upload-list-inline"
                    onChange={ handleChange }
                  >
                    {
                      state.fileList.length < 1 &&
                      <div style={ {
                        padding: 20,
                        borderRadius: '50%',
                        background: 'rgba(255, 219, 38, 0.4)',
                        width: 'fit-content'
                      } }>
                        <AttachFileIcon style={ {
                          fontSize: 30,
                          transform: 'rotate(45deg)',
                          opacity: 0.8,
                          cursor: 'pointer',
                        } }/>
                      </div>
                    }
                  </Upload>
                  {
                    state.fileList.length > 0 && <SendIcon
                      onClick={ () => multimediaSendFunc() }
                      style={ {
                        fontSize: 28,
                        color: 'rgb(4, 200, 200)',
                        cursor: 'pointer'
                      } }/> }
                </div>
                <p style={ { textAlign: 'center' } }>Attachment</p>
              </div>
          }
        </div>
      </Drawer>

      <Modal
        title={ 'Export Conversation' }
        open={ state.attachmentsModal }
        onOk={ () => exportReportFunc() }
        okButtonProps={ { disabled: state.isLoader || !state?.exportDateRange || state?.exportDateRange?.length < 1 } }
        cancelButtonProps={ { disabled: state.isLoader } }
        okText={ 'Export' }
        onCancel={ () => setState({
          ...state,
          mode: '',
          attachmentsModal: false,
          selectedFile: 'pdf',
          exportDateRange: []
        })
        }
      >
        {
          state.isLoader ? <Loader height={ '10vh' }/> :
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <RangePicker value={ state.exportDateRange } onChange={ handleDateRangeChange }/>
              <div style={ {
                display: 'flex',
                justifyContent: 'center',
                gap: 30,
                marginTop: 30
              } }>
                <Radio.Group
                  value={ state.selectedFile }
                  onChange={ e => setState({
                    ...state,
                    selectedFile: e.target.value
                  }) }
                  name="radiogroup"
                  defaultValue={ state.selectedFile }
                >
                  <Radio value={ 'pdf' } checked={ state.selectedFile === 'pdf' }><img style={ { width: 50 } }
                                                                                       alt={ 'pdf' }
                                                                                       src={ pdfImage }/></Radio>
                  <Radio value={ 'xlsx' } checked={ state.selectedFile === 'xlsx' }><img
                    style={ { width: 50 } }
                    src={ excelImage }/></Radio>
                </Radio.Group>
              </div>
            </div>
        }
      </Modal>
    </div>
  </div>
}

export default Inbox