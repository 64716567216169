import React, { useEffect, useState } from 'react';
import "./Workspace.scss";
import WorkspaceData from './WorkspaceData';
import { Tabs } from 'antd';
import { ChannelListApi } from './Api/Api';

const Workspace = () => {
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        ChannelListApi()
            .then(res => {
                const newTabs = res.data.data.map((item, idx) => ({
                    key: `${idx + 1}`,
                    tab: item.name,
                    content: <WorkspaceData key={item._id} id={item._id} />
                }));
                setTabs(newTabs);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);
    const tabPosition = 'left';

    return (
        <div className={'synctic-module-container'} style={{overflow:"hidden"}} >
            <div className={'cards-box'} style={{ padding: 0 }}>
            <Tabs defaultActiveKey="1" tabPosition="top">
                    {tabs.map(tab => (
                        <Tabs.TabPane key={tab.key} tab={tab.tab}>
                            {tab.content}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default Workspace;
