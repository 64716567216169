import { FileFilled }                       from '@ant-design/icons';
import DoneIcon                             from '@mui/icons-material/Done';
import DoneAllIcon                          from '@mui/icons-material/DoneAll';
import GTranslateIcon                       from '@mui/icons-material/GTranslate';
import { Tag, Card, Button, Tooltip, List } from 'antd';
import moment                               from 'moment/moment';
import React                                from 'react';
import ReactAudioPlayer                     from 'react-audio-player';

const Messages = (props) => {
  let { messagesArray } = props
  const ContactMessage = ({ message }) => {
    const contact = message?.contacts[0];

    return (
      <Card title="Contact Information">
        <p>Name: { contact?.formatted_name }</p>
        <p>Phone Number: { contact?.phone }</p>
      </Card>
    );
  };
  const InteractiveListMessage = ({ message }) => {
    const { sections, button } = message?.text?.action;

    return (
      <div>
        <p>{ message?.message?.text?.body?.text }</p>
        {/* Render sections */ }
        { sections.map((section, index) => (
          <Card key={ index } title={ section?.title }>
            {/* Render rows */ }
            <List
              dataSource={ section.rows }
              renderItem={ item => (
                <List.Item>
                  <List.Item.Meta
                    title={ item?.title }
                    description={ item?.description }
                  />
                </List.Item>
              ) }
            />
          </Card>
        )) }
        {/* Render action button */ }
        <Button style={ { width: '100%', marginTop: 10 } } type="primary">{ button }</Button>
      </div>
    );
  };
  const openFileToNextTab = (file) => {
    window.location.href = file;
  }

  const statusFunc = (item) => {
    return  !!item?.readBy?.status ? <DoneAllIcon style={ { color: 'green' } }/> :
      item?.deliveredAt?.status ? <DoneAllIcon/> :
        <DoneIcon/>
  }

  return messagesArray.length > 0 && messagesArray.map((item, idx) =>
    <div style={ { marginTop: 16 } } key={ idx }>
      { item?.messageType && item.messageType === "100" ? <div style={ {
          maxWidth: "100%",
          borderRadius: 5,
          padding: 10,
          background: "rgba(50, 168, 82, .2)",
          textAlign: "center",
          margin: "auto",
          overflowWrap: 'anywhere'
        } }>
          <div style={ { fontSize: 12, fontWeight: 550 } }>{ item.message?.text && item.message.text }</div>
          <div style={ {
            fontSize: 12,
            fontWeight: 550
          } }>{ moment(item.timestamp).format('MMMM Do YYYY, h:mm a') }</div>
        </div> :
        <div style={ { display: "flex", flexDirection: !item?.isIncoming ? "row-reverse" : "" } }>
          <div style={ {
            marginRight: !item?.isIncoming ? 10 : "",
            marginLeft: item?.isIncoming ? 10 : "",
            maxWidth: "40%",
            backgroundColor: item?.isIncoming ? props.channel === "whatsapp" ? "rgba(50, 168, 82)" : "rgb(0, 132, 255)" : "rgb(217,210,202)",
            borderRadius: 10,
            padding: 10,
            color: !!item?.isIncoming ? "white" : "rgba(0,0,0,.7)",
            fontSize: 12,
            fontWeight: 500,
            marginTop: 10,
            overflowWrap: 'anywhere'
          } }>
            {
              <div style={ {
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginBottom: 10
              } }>
                <Tag style={ { cursor: 'pointer' } }
                     color={ item?.isIncoming ? "#87d068" : '#1677ff' }>{ item?.postedBy }</Tag>
                { item?.isIncoming && <Tag
                  onClick={ () => props.aiGenerateMsgFunc(item?.message?.text) }
                  color={ 'blue' }>Reply with bot</Tag> }

              </div>
            }
            {
              item.messageType === 'text' &&
              <div>{ item?.message?.text }</div>
            }
            {
              item?.messageType === 'text' && (item?.message?.text !== item?.message?.translation) &&
              <div style={ {
                padding: 10,
                background: 'rgba(255,255,255,.1)',
                borderRadius: 6
              } }> Translated text <br/> { item?.message?.translation && item?.message?.translation }
              </div>
            }
            {
              item.messageType === 'location' && <div>
                <a style={ { overflowWrap: 'break-word' } } href={ item?.message?.location } target={ '_blank' }>
                  { item?.message?.location }
                </a>
              </div>
            }
            {
              item.messageType === 'interactive' && item?.message?.text?.type === 'button' && <Card>
                <p>{ item.message?.text?.body?.text }</p>
                <div style={ { display: 'flex', justifyContent: 'space-between', gap: 10, width: '100%' } }>
                  { item?.message?.text?.action?.buttons?.length > 0 && item?.message?.text?.action?.buttons.map((button, index) => (
                    <Button style={ { width: '46%' } } type="primary">
                      { button?.reply?.title }
                    </Button>
                  )) }
                </div>
              </Card>
            }

            {
              item.messageType === 'interactive' &&
              item?.message?.interactive?.type === "button_reply" &&
              <Card>
                <p>{ item?.message?.interactive?.button_reply?.title }</p>
              </Card>
            }

            {
              item.messageType === 'contacts' &&
              ContactMessage(item)
            }

            {
              item?.message?.text?.type === 'list' &&
              InteractiveListMessage(item)
            }

            {
              item.messageType === 'interactive' &&
              item?.message?.interactive?.type === "list_reply" &&
              <Card>
                <p style={ { fontWeight: 550 } }>{ item?.message?.interactive?.list_reply?.title }</p>
                <p>{ item?.message?.interactive?.list_reply?.description }</p>
              </Card>
            }


            {
              (
                item?.messageType === 'image' ||
                item?.messageType === 'video' ||
                item?.messageType === 'document' ||
                item?.messageType === 'audio' ||
                item?.messageType === 'sticker'
              ) &&
              <div>
                <div>{ item?.message?.text }</div>
                <MultiMediaMessage
                  openFileToNextTab={ openFileToNextTab }
                  url={ item.message.url }
                  type={ item.messageType }
                />
              </div>
            }
              {/*{*/}
              {/*  item.messageType === "sticker" &&*/}
              {/*    <div>Its Sticker</div>*/}
              {/*}*/}

              {
                item.messageType === "unsupported" &&
                  <div>This is UNSUPPORTED MESSAGE</div>
              }

            {
              item.messageType === "template" && <Card>
                {
                  console.log(item.message, 433331112)
                }
                {
                  item?.message?.header?.type==="text" && <h5>{item.message.header.text}</h5>
                }
                {
                  !!item.message?.body && <p>{item.message?.body}</p>
                }
                {
                  !!item.message?.footer && <p>{item.message?.footer}</p>
                }
                <br/>
                {
                  console.log(typeof item.message.button, item.message.button)
                }
                <div style={{
                  display:"flex",
                  flexDirection:"column",
                  gap:10
                }}>
                {
                  item.message.button.length > 0 && item.message.button.map(data=> data.type === "URL" ?
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <Button type="primary" style={{width:"100%"}}>
                        { data.text }
                      </Button>
                    </a>:data.type==="PHONE_NUMBER" ?
                      <Button type="primary">
                        {data.text + "  " + data.phone_number}
                      </Button> : data.type==="COPY_CODE"? <Button type="primary">
                        {"Copy Code" + " " + data?.example}
                      </Button> : <Button type="primary">
                        {data.text}
                      </Button>
                  )
                }
                </div>
              </Card>
            }


            <div style={ {
              float: "right",
              fontSize: 10,
              fontWeight: 400,
              display: "flex",
              gap: 6,
              alignItems: "center",
              marginTop: 6
            } }>
              { moment(item.timestamp).format('MMMM Do YYYY, h:mm a') }
              {
                !item.isIncoming && statusFunc(item)
              }
              {
                <Tooltip placement="top" title={ item?.message?.translation }>
                  <GTranslateIcon color={ "white" }/>
                </Tooltip>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const MultiMediaMessage = (props) => {
  const { type, url } = props;
  const multimedia = () => {
    switch (type) {
      case "image":
      case "sticker":
        return (
          <div style={ { maxWidth: "100%", overflow: "hidden" } }>
            <img src={ url } alt="Image"
                 style={ { width: "100%", height: "auto", maxHeight: 300, objectFit: 'contain' } }/>
          </div>
        );
      case "video":
        return (
          <video
            src={ url }
            type="video/mp4"
            controls
            style={ { maxWidth: "100%", maxHeight: 400, objectFit: 'contain' } }
          />
        );
      case "audio":
        return <ReactAudioPlayer
          src={ url }
          // autoPlay
          controls
        />;
      case "document":
        return (
          <a href={ url } target="_blank">
            <FileFilled style={ { fontSize: 40 } }/>
          </a>
        );
      default:
        return null;
    }
  }
  return <>{ multimedia() }</>
}
export default Messages;