import { Button, Checkbox, Modal, Table, Tabs,Tag } from "antd";
import React, { useEffect, useState } from "react";
import StackBar from "./StackBar";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import Line from "../../../ResuseableComponnets/Charts/Line";
import ProgressBarVOC from "./ProgressBar";
import "./VoiceOfCustmer.scss";
import { getVoiceCustomer } from "../../states/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

const VoiceOfCustomer = (props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const [state, setState] = useState({
    fd: false,
    fc: false,
    mode: "",
    whatsapp: "",
    instagram: "",
    messenger: "",
    poor: false,
    avg: false,
    great: false,
  });
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 2,
  });
  useEffect(() => {
    dispatch(getVoiceCustomer());
  }, []);

  const Poor = useSelector(
    (state) => state.dashboard?.VoiceCustomer?.feedbackCount?.poor
  );
  const average = useSelector(
    (state) => state.dashboard?.VoiceCustomer?.feedbackCount?.average
  );
  const Great = useSelector(
    (state) => state.dashboard?.VoiceCustomer?.feedbackCount?.Great
  );
  const totalCount = Poor + average + Great;
  const percent = totalCount !== 0 ? Math.floor((Poor / totalCount) * 100) : 0;
  const feedbackStats = useSelector(
    (state) => state.dashboard?.VoiceCustomer?.feedbackStats || []
  );
  console.log(percent,99999)
  const agentFeedBackStats = useSelector(
    (state) => state.dashboard?.VoiceCustomer?.agentFeedBackStats || []
  );
  const feedbackList = useSelector(
    (state) => state.dashboard?.VoiceCustomer?.feedbackList || []
  );

  let { title, value, isModalOpen, mode, modalOpenFunc } = props;
  const modalFooter = [
    <Button key="cancel" onClick={() => modalOpenFunc()}>
      Cancel
    </Button>,
    <Button
      key="apply"
      onClick={() => modalOpenFunc()}
      primary
      style={{ display: mode === "export" ? "none" : "" }}
    >
      Apply
    </Button>,
  ];
  const items = [
    {
      key: "1",
      label: "Channels",
      children: (
        <div>
          <div style={{ marginTop: 6 }} />
          <Checkbox
            checked={state.whatsapp}
            onChange={() => setState({ ...state, whatsapp: !state.whatsapp })}
          >
            Whatsapp
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.messenger}
            onChange={() => setState({ ...state, messenger: !state.messenger })}
          >
            Messenger
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.instagram}
            onChange={() => setState({ ...state, instagram: !state.instagram })}
          >
            Instagram
          </Checkbox>
          <div style={{ marginTop: 4 }} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Agents",
      children: <AgentsFilterTable />,
    },
    {
      key: "3",
      label: "Score",
      children: (
        <div>
          <div style={{ marginTop: 6 }} />
          <Checkbox
            checked={state.poor}
            onChange={() => setState({ ...state, poor: !state.poor })}
          >
            Poor
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.avg}
            onChange={() => setState({ ...state, avg: !state.avg })}
          >
            Average
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.great}
            onChange={() => setState({ ...state, great: !state.great })}
          >
            Great
          </Checkbox>
          <div style={{ marginTop: 4 }} />
        </div>
      ),
    },
  ];

  const SurveysColumns = [
    {
      title: t("Customers Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Customers Phone"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("Solved By"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Score"),
      dataIndex: "quality",
      key: "quality",
      render: (quality) => (
        quality ? (
          <Tag color={
            quality === "poor" ? "red" : 
            quality === "average" ? "blue" :
            quality === "Great" ? "green" : ""
          }>
            {quality}
          </Tag>
        ) : ""
      )
    },
    {
      title: t("Channel"),
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: t("Created"),
      dataIndex: "timestamp",
      key: "timestamp",
    },
  ];
  const feedbackListData = feedbackList.map((item, index) => ({
    key: index,
    name: item.customer.name,
    phone: item.customer.phone,
    email: item.agentInfo.email,
    quality: item.quality,
    channel: item.channel,
    timestamp: item.timestamp
      ? moment(item.timestamp).format("MMMM Do YYYY, h:mm a")
      : "",
  }));
  const feeddata = {
    labels: feedbackStats.label || [],
    datasets: [
      {
        label: t("Average"),
        data: feedbackStats.average || [],
        borderColor: "blue",
      },
      {
        label: t("Great"),
        data: feedbackStats.great || [],
        borderColor: "green",
      },
      {
        label: t("Poor"),
        data: feedbackStats.poor || [],
        borderColor: "red",
      },
    ],
  };

  const data = {
    labels: agentFeedBackStats.label || [],
    datasets: [
      {
        label: "Average",
        data: agentFeedBackStats.average || [],
        backgroundColor: "blue",
        borderColor: "blue",
      },
      {
        label: "Great",
        data: agentFeedBackStats.great || [],
        backgroundColor: "green",
        borderColor: "green",
      },
      {
        label: "Poor",
        data: agentFeedBackStats.poor || [],
        backgroundColor: "red",
        borderColor: "red",
      },
    ],
  };
  return (
    <div>
      <div className={"agents-availability-container"}>
        <div className={"cards-box voc-progress-bar"} style={{ width: "30%" }}>
          <h6>{t("CSAT Breakdown By Response")}</h6>
          <ProgressBarVOC
            titleOne={t("poor")}
            valueOne={Poor || 0}
            titleTwo={t("Average")}
            valueTwo={average || 0}
            titleThree={t("Great")}
            valueThree={Great || 0}
            percent={percent || 0}
          />
        </div>
        <div className={"cards-box"} style={{ width: "70%" }}>
          <h6>{t("Performance")}</h6>
          <Line height={268} data={feeddata} />
        </div>
      </div>
      <div className={"cards-box"} style={{ marginTop: 10 }}>
        <h6>{t("Surveys")}</h6>
        <Table
          columns={SurveysColumns}
          dataSource={feedbackListData}
          style={{ marginTop: 10 }}
          pagination={pagination}
          onChange={(page) => {
            setPagination(page);
          }}
        />
      </div>

      <div style={{ marginTop: 10 }} className={"cards-box"}>
        <StackBar height={210} data={data} heading={t("Feedback")} />
      </div>
      <Modal
        title={
          mode === "export"
            ? "Export Insights"
            : mode === "export" && "Filter Agent Performance"
        }
        open={isModalOpen}
        onCancel={() => {
          modalOpenFunc();
          setState({
            ...state,
            fc: false,
            fd: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
        footer={modalFooter}
        closable={() => {
          modalOpenFunc();
          setState({
            ...state,
            fd: false,
            fc: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
      >
        {mode === "export" ? (
          <div>
            <div style={{ fontWeight: "550" }}>Include breakdown by</div>
            <div style={{ marginTop: 6 }} />
            <Checkbox
              checked={state.fd}
              onChange={() => setState({ ...state, fd: !state.fd })}
            >
              Feedback Details
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.fc}
              onChange={() => setState({ ...state, fc: !state.fc })}
            >
              Feedback Chart
            </Checkbox>
          </div>
        ) : (
          mode === "filter" && (
            <Tabs defaultActiveKey="1" tabPosition={"top"} items={items} />
          )
        )}
      </Modal>
    </div>
  );
};

export default VoiceOfCustomer;
