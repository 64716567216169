import { DatePicker, Modal, Radio }   from "antd";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const TiebotDateFilters = (props) => {
const {t} = useTranslation()
  const [state, setState] = useState({
    startDate: '',
    endDate: '',
    isOpen: false,
    dateRangeType: '',
  })

  const handleDateRangeChange = (e) => {
    setState({ ...state, dateRangeType: e.target.value, startDate: '', endDate: '' })
  };

  const handleDateChange = (dates, dateStrings) => {
    setState({
      ...state,
      dateRangeType: 'custom',
      startDate: dateStrings[0],
      endDate: dateStrings[1]
    })
  };

  useEffect(() => {
    setState({ ...state, dateRangeType: props.defaultValue })
  }, [])

  return <Modal
    title={ t('Filter') }
    open={ props.isFilterOpen }
    okButtonProps={ { disabled: !state.dateRangeType } }
    onOk={ () => {
      let start = state.startDate
      let end = state.endDate
      let filterDuration = state.dateRangeType
      props.closeFilterModal(start, end, filterDuration)
      setState({ ...state })
    } }
    okText={ 'Apply' }
    onCancel={ () => {
      props.closeModal()
      setState({ ...state })
    } }
  >
    <Radio.Group
      style={ {
        display: 'flex',
        flexDirection: 'column',
        gap: 10
      } }
      defaultValue={ props.defaultValue }
      onChange={ handleDateRangeChange }
      value={ state.dateRangeType }
    >
      <Radio value="today">{t("Today")}</Radio>
      <Radio value="yesterday">{t("Yesterday")}</Radio>
      <Radio value="last7days">{t("Last 7 Days")}</Radio>
      <Radio value="last15days">{t("Last 15 Days")}</Radio>
      <Radio value="last30days">{t("Last 30 Days")}</Radio>
      <Radio value="thisMonth">{t("This Month")}</Radio>
      <Radio value="lastMonth">{t("Last Month")}</Radio>
      <Radio value="custom">{t("Custom Range")}</Radio>
      <Radio value="clear">{t("Clear filter")}</Radio>
    </Radio.Group>
    {
      state.dateRangeType === 'custom' &&
      <RangePicker
        onChange={ handleDateChange }
        format="YYYY-MM-DD"
        style={ { marginTop: 10 } }
      />
    }
  </Modal>
}

export default TiebotDateFilters;