import React, {useState} from 'react';
import {Avatar, Dropdown, Menu, Tooltip} from 'antd';
import {UserOutlined, SettingOutlined, LogoutOutlined, LockOutlined} from '@ant-design/icons';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ActionsCreators from "../Inbox/states";
import {useSelector} from 'react-redux';


const HeaderDropDown = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        activeTab: '',
        subMenuData: [],
        imageError: false
    });

    const Name = useSelector(state => state?.me?.me?.firstName);
    const email = useSelector(state => state?.me?.me?.email);
    const profile = useSelector(state => state?.me?.me?.profilePic)


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('my-profile');
        localStorage.removeItem('activeTab');
        dispatch(ActionsCreators.getWhatsappConversationAction(''));
        dispatch(ActionsCreators.getMessengerConversationAction(''));
        dispatch(ActionsCreators.getInstagramConversationAction(''));
        setState({
            ...state,
            activeTab: '',
            subMenuData: []
        });
        navigate('/');
    };

    const menu = (
        <Menu>
            <div style={{opacity: "0.7", marginLeft: "5%", fontSize: "14px"}} key="account">Account</div>
            <Menu.Item key="profile">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Avatar
                        src={state.imageError ? null : profile}
                        style={{
                            marginRight: '8px'
                        }}
                        onError={() => setState({...state, imageError: true})}
                        size="small"
                        icon={!profile && !Name ? <UserOutlined/> : null}
                    >
                        {
                            !!state.imageError && !!email && email.split(' ')[0].charAt(0).toUpperCase()
                        }
                    </Avatar>
                    <div>
                        <div style={{color: '#757575'}}>{Name}</div>
                        <div style={{color: '#757575'}}>{email}</div>
                    </div>
                </div>
            </Menu.Item>
            <Menu.Divider key="divider1"/>
            <div style={{opacity: "0.7", marginLeft: "5%", fontSize: "14px"}} key="tiebot">TIEBOT</div>
            <Menu.Item key="Profile" icon={<LockOutlined/>} onClick={() => navigate("/settings/my_profile")}>
                <Tooltip title="My Profile">
                    My Profile
                </Tooltip>
            </Menu.Item>
            <Menu.Divider key="divider2"/>
            <Menu.Item key="changePassword" icon={<LockOutlined/>}
                       onClick={() => navigate("/settings/Change-Password")}>
                <Tooltip title="Change Password">
                    Change Password
                </Tooltip>
            </Menu.Item>
            <Menu.Item key="Personal-Settings" icon={<SettingOutlined/>}>
                <Tooltip title="Personal Settings">
                    Personal Settings
                </Tooltip>
            </Menu.Item>
            <Menu.Divider key="divider3"/>
            <Menu.Item key="logout" icon={<LogoutOutlined/>} onClick={handleLogout}>
                <Tooltip title="Logout">
                    Logout
                </Tooltip>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <Tooltip title="Profile">
                    <Avatar
                        src={state.imageError ? null : profile}
                        style={{
                            verticalAlign: 'middle',
                            cursor: 'pointer'
                        }}
                        onError={() => setState({...state, imageError: true})}
                        size="small"
                        icon={!profile && !Name ? <UserOutlined/> : null}
                    >
                        {
                            !!state.imageError && !!email && email.split(' ')[0].charAt(0).toUpperCase()
                        }
                    </Avatar>
                </Tooltip>
            </Dropdown>
        </div>
    );
};

export default HeaderDropDown;
