import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export default function openNotificationWithIcon(type, message, description) {
    const icon = type === 'success' ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : <CloseCircleOutlined style={{ color: '#eb2f96' }} />;

    notification.open({
        message: message,
        description: description,
        icon: icon,
        style: {
            className: 'custom-class',
        },
    });
}
