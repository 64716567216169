import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListItem from "@mui/material/ListItem";
import pic1 from "../../assets/media/avatars/300-2.jpg"
import {Input, Modal, Select, Button, Menu, Dropdown, Tag, Radio} from 'antd';
import {SelectProps} from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {assignTagToChat, getAllActivatedTags, getAllTags} from "../Settings/Tags/API/Api";
import Loader from "../ResuseableComponnets/Loader";
import {
    addAttr,
    deleteAttr,
    deleteNote,
    getAttr,
    getInboxContactInformation,
    getNote,
    postNote, saveInboxContactInformation,
    updateAttr,
    updateNote
} from "./Apis";
import HomeIcon from '@mui/icons-material/Home';
import Person2Icon from '@mui/icons-material/Person2';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FlagIcon from '@mui/icons-material/Flag';
import EmailIcon from '@mui/icons-material/Email';
import WcIcon from '@mui/icons-material/Wc';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import SourceIcon from '@mui/icons-material/Source';
import moment from "moment/moment";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {MoreOutlined} from "@ant-design/icons";
import openNotificationWithIcon from "../ResuseableComponnets/Toaster";

const options: SelectProps['options'] = [];

for (let i = 10; i < 36; i++) {
    options.push({
        value: i.toString(36) + i,
        label: i.toString(36) + i,
    });
}

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const ContactInfo = (props) => {
    const [state, setState] = useState({
        isDialogueOpen: false,
        addNote: "",
        notes: [],
        allTags: [],
        selectedTags: [],
        isLoader: false,
        allAttr: [],
        information: null,
        mode: "",
        address: "",
        birthday: "",
        city: "",
        country: "",
        email: "",
        gender: "male",
        state: "",
        zip_code: "",
        name: "",
        phone: "",
        noteId: "",
        isAttrModal: false,
        key: "",
        value: "",
        attrId: "",
        conversationId: ''
    })
    let conversationId = props.contactInfo.conversationId

    const assignTagFunc = (tag) => {
        let data = {
            conversationId: conversationId,
            tag
        }
        assignTagToChat(data).then(() => {
            getInboxContactInfoFunc()
            props.getChatInformation(props.contactInfo.conversationId)
            openNotificationWithIcon('success',
                'Success Message',
                'Tag assign successfully'
            )
        }).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('error',
                'Error Message',
                'Error while assigning tag'
            )
        })
    }
    const getAllTagsFunc = () => {
        getAllActivatedTags().then(res => {
                setState(prevState => ({
                    ...prevState,
                    allTags: res.data.data,
                    isLoader: false,
                    isDialogueOpen: false
                }))
            }
        ).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('Error',
                'Error Message',
                'Error white getting tags'
            )
        })
    }
    const info = props?.contactInfo?.contactInfo && props.contactInfo.contactInfo

    const getAllNotesFunc = () => {
        getNote().then(res => setState({...state, notes: res.data.data})).catch(() => setState({
            ...state,
            isLoader: false
        }))
    }

    const addNoteFunc = () => {
        let data = {
            conversationId: conversationId,
            notes: state.addNote
        }
        postNote(data).then(() => {
            getInboxContactInfoFunc()
            openNotificationWithIcon('success',
                'Success Message',
                'Note posted successfully'
            )
        }).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('error',
                'Error Message',
                'Error while posting note'
            )
        })
    }

    const deleteNoteFunc = () => {
        deleteNote(state.noteId).then(() => {
            getInboxContactInfoFunc()
            openNotificationWithIcon('success',
                'Success Message',
                'Note deleted successfully'
            )
        }).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('error',
                'Error Message',
                'Error while deleting note'
            )
        })
    }

    const updateNoteFunc = () => {
        let data = {
            notes: state.addNote
        }
        updateNote(state.noteId, data).then(() => {
            getInboxContactInfoFunc()
            openNotificationWithIcon('success',
                'Success Message',
                'Note updated successfully'
            )
        }).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('error',
                'Error Message',
                'Error while updating note'
            )
        })
    }

    const getAttrFunc = () => {
        getAttr(conversationId).then(res => {
            setState(prevState => ({
                ...prevState, allAttr: res.data.data
            }))
            getAllTagsFunc()
        }).catch(() => {
                setState({
                    ...state,
                    isLoader: false
                })
                openNotificationWithIcon('error',
                    'Error Message',
                    'Error while getting attributes'
                )
            }
        )
    }

    const addAttrFunc = () => {
        let data = {
            conversationId: conversationId,
            key: state.key,
            keyValue: state.value
        }
        addAttr(data)
            .then(() => {
                getInboxContactInfoFunc()
                openNotificationWithIcon('success',
                    'Success Message',
                    'Attribute added successfully'
                )
            })
            .catch(() => setState({...state, isLoader: false}))
    }

    const deleteAttrFunc = () => {
        deleteAttr(state.attrId).then(() => {
            getInboxContactInfoFunc()
            openNotificationWithIcon('success',
                'Success Message',
                'Update attribute successfully'
            )
        }).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('error',
                'Error Message',
                'Error while deleting attribute'
            )
        })
    }

    const updateAttrFunc = () => {
        let data = {
            key: state.key,
            keyValue: state.value
        }
        updateAttr(state.attrId, data).then(() => {
            getInboxContactInfoFunc()
            openNotificationWithIcon('success',
                'Success Message',
                'Attribute update successfully'
            )
        }).catch(() => {
            setState({...state, isLoader: false})
            openNotificationWithIcon('error',
                'Error Message',
                'Error while updating attribute'
            )
        })
    }
    const getInboxContactInfoFunc = () => {
        setState(prevState => ({
            ...prevState, isLoader: true
        }))
        getInboxContactInformation(conversationId)
            .then(res => {
                    setState(prevState => ({
                        ...prevState,
                        information: res.data.data,
                        isLoader: false,
                        address: res.data.data.chatInitiator.address,
                        birthday: res.data.data.chatInitiator.birthday,
                        city: res.data.data.chatInitiator.city,
                        country: res.data.data.chatInitiator.country,
                        email: res.data.data.chatInitiator.email,
                        gender: res.data.data.chatInitiator.gender,
                        state: res.data.data.chatInitiator.state,
                        zip_code: res.data.data.chatInitiator.zip_code,
                        name: res.data.data.chatInitiator.name,
                        phone: res.data.data.chatInitiator.phone,
                        isDialogueOpen: false,
                        isAttrModal: false,
                        selectedTags: res.data.data.assignTag,
                        conversationId: res.data.data.conversationId
                    }))
                }
            )
            .catch(() => {
                setState({...state, isLoader: false})
                openNotificationWithIcon('error',
                    'Error Message',
                    'Error while getting contact info'
                )
            })
    }

    const chatInitiator = state?.information?.chatInitiator

    const saveInboxContactFunc = () => {
        setState({...state, isLoader: true})
        let data = {
            address: state.address,
            birthday: state.birthday,
            city: state.city,
            country: state.country,
            email: state.email,
            gender: state.gender,
            state: state.state,
            zip_code: state.zip_code,
            name: state.name,
            phone: state.phone,
            conversationId: conversationId
        }
        saveInboxContactInformation(data)
            .then(() => getInboxContactInfoFunc())
            .catch(() => setState({...state, isLoader: false}))
    }

    useEffect(() => {
        getInboxContactInfoFunc()
        getAttrFunc()
    }, [props?.contactInfo?.conversationId]);

    return state.isLoader ? <Loader/> : <div className="contact_info_main">
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    // variant="dot"
                >
                    <Avatar alt={
                        chatInitiator?.name
                    } src={ "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEBwzA9VEjgBydUx2ZTVWaWKx2abMJ5daAAXmSqfl9evKnaV4Rer_PkKz_r8_m76Ph0wOd-yjd5&psid=7436819382996141&width=1024&ext=1704237886&hash=AfqcnOSUP1eLiEWW0GZvImCJkOMvK1iMJ2HDICr6RYePzg" }/>
                </StyledBadge>
            </ListItemAvatar>
            <ListItemText
                primary={<div style={{
                    fontWeight: 550,
                    fontSize: 16,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div>
                        {chatInitiator?.name}
                    </div>
                    <EditIcon onClick={() => setState({...state, isDialogueOpen: true, mode: "contactEdit"})}
                              style={{
                                  fontSize: 16, color: "blue", display: !props.showMessageBar ? 'none' : 'block'
                              }}/>
                </div>
                }
                secondary={
                    <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        {chatInitiator?.phone}
                        <br/>
                        {'ID: ' + state.conversationId}
                    </Typography>
                }
            />
        </ListItem>
        <div style={{display: "flex", flexDirection: "column", gap: 6, color: 'rgba(0, 0, 0, 0.6)'}}>
            <div><HomeIcon className='contact_info_icons'/> {chatInitiator?.address}</div>
            <div><Person2Icon className='contact_info_icons'/> {chatInitiator?.birthday}</div>
            <div><LocationCityIcon className='contact_info_icons'/> {chatInitiator?.city}</div>
            <div><FlagIcon className='contact_info_icons'/> {chatInitiator?.country}</div>
            <div><EmailIcon className='contact_info_icons'/> {chatInitiator?.email}</div>
            <div><WcIcon className='contact_info_icons'/> {chatInitiator?.gender}</div>
            <div><HouseboatIcon className='contact_info_icons'/> {chatInitiator?.state}</div>
            <div><SourceIcon className='contact_info_icons'/> {chatInitiator?.zip_code}</div>
        </div>
        <hr/>
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={<div style={{
                    fontWeight: 600,
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: .8
                }}>Attributes<AddIcon onClick={() => setState({...state, isAttrModal: true, mode: '', value: '', key: ''})}
                                      style={{
                                          fontSize: 16,
                                          color: "blue",
                                          display: !props.showMessageBar ? 'none' : 'block'
                                      }}/></div>}
                secondary={
                    state.information?.attributes.map((item, idx) => <div key={idx} style={{
                            fontSize: 14,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <div style={{width: "95%"}}>

                                {item.key + ` : ` + item.keyValue}
                            </div>
                            <Dropdown overlay={(
                                <Menu>
                                    <Menu.Item key="1">
                                        <div onClick={() => setState({
                                            ...state,
                                            mode: "delete",
                                            isAttrModal: true,
                                            attrId: item._id
                                        })}>
                                            Delete Attribute
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <div onClick={() => setState({
                                            ...state,
                                            mode: "update",
                                            isAttrModal: true,
                                            attrId: item._id,
                                            key: item.key,
                                            value: item.keyValue,
                                        })}>
                                            Update Attribute
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            )} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <MoreOutlined
                                        style={{fontSize: '14px', display: !props.showMessageBar ? 'none' : 'block'}}/>
                                </a>
                            </Dropdown>
                        </div>
                    )
                }
            />
        </ListItem>

        <hr/>
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={<div style={{fontWeight: 600, fontSize: 14, opacity: .8}}>Tags</div>}
                secondary={
                    <div style={{marginTop:8}}>
                        {
                                <Tag color="orange">{state.selectedTags}</Tag>
                        }
                        <Dropdown overlay={(
                            <Menu>
                                {
                                    state?.allTags.length > 0 &&
                                    state?.allTags.map((item, idx) => <Menu.Item key={idx}>
                                            <div onClick={() => assignTagFunc(item.name)
                                            }>
                                                {item.name}
                                            </div>
                                        </Menu.Item>
                                    )
                                }

                            </Menu>
                        )} trigger={['click']}>
                            <a style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: 10
                            }} onClick={(e) => e.preventDefault()}>
                                <div style={{
                                    fontSize: 14,
                                    fontWeight: 550
                                }}>Add Tag
                                </div>
                                <ArrowDropDownIcon style={{
                                    fontSize: '20px',
                                    opacity: .7,
                                    display: !props.showMessageBar ? 'none' : 'block'
                                }}/>
                            </a>
                        </Dropdown>
                    </div>
                }
            />
        </ListItem>
        <hr/>
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={<div style={{
                    fontWeight: 600,
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: .8
                }}>Notes<AddIcon onClick={() => setState({...state, isDialogueOpen: true, mode: ''})}
                                 style={{
                                     fontSize: 16,
                                     color: "blue",
                                     display: !props.showMessageBar ? 'none' : 'block'
                                 }}/></div>}
                secondary={
                    state.information?.notes.map((item, idx) => <div key={idx} style={{
                            fontSize: 14,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <div style={{width: "95%"}}>
                                <div style={{
                                    fontSize: 14,
                                    fontWeight: 550
                                }}>{item?.notes}</div>
                                <div style={{fontSize: 12}}>{moment(item.timestamp).format('MMMM Do YYYY, h:mm a')}</div>
                            </div>
                            <Dropdown overlay={(
                                <Menu>
                                    <Menu.Item key="1">
                                        <div onClick={() => setState({
                                            ...state,
                                            mode: "delete",
                                            isDialogueOpen: true,
                                            addNote: item.notes,
                                            noteId: item._id
                                        })}>
                                            Delete Note
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <div onClick={() => setState({
                                            ...state,
                                            mode: "update",
                                            isDialogueOpen: true,
                                            addNote: item.notes,
                                            noteId: item._id
                                        })}>
                                            Update Note
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            )} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <MoreOutlined
                                        style={{fontSize: '14px', display: !props.showMessageBar ? 'none' : 'block'}}/>
                                </a>
                            </Dropdown>
                        </div>
                    )
                }
            />
        </ListItem>

        <Modal
            title={state.mode === "delete" ? "Are you sure to delete this?" : state.mode === "update" ? "Update" : "Add Attribute"}
            open={state.isAttrModal}
            onOk={() => state.mode === "delete" ? deleteAttrFunc() : state.mode === "update" ? updateAttrFunc() : addAttrFunc()}
            onCancel={() => setState({...state, isAttrModal: false})}
        >
            {
                state.mode === "delete" ? " " : <div style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "grid",
                    gridTemplateColumns: "42% 42%"
                }}>
                    <Input onChange={(e) => setState({...state, key: e.target.value})}
                           style={{marginTop: 10, marginBottom: 20}}
                           value={state.key}
                           placeholder="Enter Key"/>
                    <Input onChange={(e) => setState({...state, value: e.target.value})}
                           style={{marginTop: 10, marginBottom: 20}}
                           value={state.value}
                           placeholder="Enter Value"/>
                </div>
            }
        </Modal>


        <Modal
            title={state.mode === "contactEdit" ?
                "Save Information" :
                state.mode === "update" ? "Update Note" : state.mode === "delete"
                    ? "Are you sure to delete this?" :
                    `Add Note`
            }
            open={state.isDialogueOpen}
            onOk={() => state.mode == "contactEdit" ?
                saveInboxContactFunc() :
                state.mode === "update" ? updateNoteFunc() : state.mode === "delete" ? deleteNoteFunc() : addNoteFunc()
            }
            onCancel={() => setState({...state, isDialogueOpen: false})}
        >
            {
                state.mode === "contactEdit" ?
                    <div>
                        <Input
                            onChange={(e) => setState({...state, name: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Name"
                            value={state.name}
                            disabled={true}
                        />
                        <Input
                            onChange={(e) => setState({...state, phone: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Phone"
                            value={state.phone}
                            disabled={true}
                        />
                        <Input
                            onChange={(e) => setState({...state, email: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Email"
                            value={state.email}
                        />
                        <Radio.Group
                            style={{marginTop: 10}}
                            onChange={(e) => setState({...state, gender: e.target.value})} value={state.gender}>
                            <Radio value={'Male'} checked={state.gender === 'Male'}>Male</Radio>
                            <Radio value={'Female'} checked={state.gender === 'Female'}>Female</Radio>
                            <Radio value={'Other'} checked={state.gender === 'Other'}>Other</Radio>
                        </Radio.Group>
                        <Input
                            onChange={(e) => setState({...state, address: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Address"
                            value={state.address}
                        />
                        <Input
                            onChange={(e) => setState({...state, birthday: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Birthday"
                            value={state.birthday}
                        />
                        <Input
                            onChange={(e) => setState({...state, country: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Country"
                            value={state.country}
                        />
                        <Input
                            onChange={(e) => setState({...state, city: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="City"
                            value={state.city}
                        />
                        <Input
                            onChange={(e) => setState({...state, state: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="State"
                            value={state.state}
                        />
                        <Input
                            onChange={(e) => setState({...state, zip_code: e.target.value})}
                            style={{marginTop: 10}}
                            placeholder="Zipcode"
                            value={state.zip_code}
                        />
                    </div> : state.mode === "update" ? <div>
                        <Input.TextArea value={state.addNote}
                                        onChange={(e) => setState({...state, addNote: e.target.value})}
                                        style={{marginTop: 10, marginBottom: 20}} showCount maxLength={100}
                                        placeholder="Enter Note"/>
                    </div> : state.mode === "delete" ? <div></div> :
                        <Input.TextArea onChange={(e) => setState({...state, addNote: e.target.value})}
                                        style={{marginTop: 10, marginBottom: 20}} showCount maxLength={100}
                                        placeholder="Enter Note"/>
            }
        </Modal>
    </div>
}

export default ContactInfo