import axios from "../../../Authentication/headers";

export function ChannelListApi() {
    return axios.get('/channel/wabaList')
}

export function profileInformation(id) {
    return axios.get(`/channel/profileInformation/${id}`);
  }

  export function CreateProfile(id) {
    return axios.put(`/channel/wabaUpdate/${id}`);
  }