import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space, Table, Modal, Dropdown, Menu} from 'antd';
import {MoreOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import EditIcon from '@mui/icons-material/Edit';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader                                                                               from ".././ResuseableComponnets/Loader";
import { getAllSegments, activateSegment, deActivateSegment, deleteSegment } from './APIS/Apis';

const AllSegments = (props) => {
  const [state, setState] = useState({
    openDialogue: false,
    name: "",
    isLoader: false,
    data: [],
    selectedData: null,
    mode: ""
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const navigate = useNavigate()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('title'),
    },
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('body'),
    },
    {
      title: "Category",
      dataIndex: 'category',
      key: 'category',
      ...getColumnSearchProps('category'),
    },
    {
      title: "Slug",
      dataIndex: 'slug',
      key: 'slug',
      ...getColumnSearchProps('slug'),
    },
    {
      title: "Actions",
      dataIndex: 'action',
      key: 'action',
      ...getColumnSearchProps('action'),
    },
  ];

  const updatedArray = state.data.map((obj) => ({
    ...obj,
    'status': obj.status ? "Active" : "De-activated",
    action: (
      <Dropdown overlay={(
        <Menu>
          <Menu.Item key="1">
            <div onClick={() => setState({
              ...state,
              openDialogue: true,
              selectedData: obj,
              mode: "activate"
            })}>
              <DoneIcon/> Activate
            </div>
          </Menu.Item>
          <Menu.Item key="2">
            <div onClick={() => setState({
              ...state,
              openDialogue: true,
              selectedData: obj,
              mode: "de-activate"
            })}>
              <RemoveIcon/> De-activate
            </div>
          </Menu.Item>
          <Menu.Item key="3">
            <div onClick={() => setState({
              ...state,
              openDialogue: true,
              selectedData: obj,
              mode: "delete"
            })}>
              <DeleteIcon/> Delete
            </div>
          </Menu.Item>
          <Menu.Item key="4">
            <div onClick={() =>navigate(`/segments/${obj.id}`)}>
              <EditIcon/> Edit
            </div>
          </Menu.Item>
        </Menu>
      )} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()}>
          <MoreOutlined style={{fontSize: '14px'}}/>
        </a>
      </Dropdown>
    )
  }))
  const getAllFunc = () => {
    setState({...state, isLoader: true})
    getAllSegments().then(res => setState({
      ...state,
      openDialogue: false,
      isLoader: false,
      data: res?.data?.data,
      mode: ""
    })).catch((err) => console.log(err))
  }
  const deActivateFunc = ()=> {
    let id = state.selectedData.id
    deActivateSegment(id)
      .then(()=>getAllFunc())
      .catch(()=>setState({...state, isLoader: false}))
  }

  const activateFunc = ()=> {
    let id = state.selectedData.id
    activateSegment(id)
      .then(()=>getAllFunc())
      .catch(()=>setState({...state, isLoader: false}))
  }

  const deleteFunc = ()=> {
    let id = state.selectedData.id
    deleteSegment(id)
      .then(()=>getAllFunc())
      .catch(()=>setState({...state, isLoader: false}))
  }

  useEffect(() => {
    getAllFunc()
  }, []);


  return (
    state.isLoader ? <Loader/> :
      <div className="synctic-module-container">
        <div className='cards-box' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='heading'>Segmengts</div>
          <Button
            type="primary"
            style={{ float: 'right' }}
            onClick={() => {
              setState({...state, openDialogue: true, mode: "add"})
            }}
          >
            <IoIosAddCircleOutline
              style={{ fontSize: '15px', color: '#fff', marginRight: '12px', marginTop: '-2%' }}
            />
            Add Segment
          </Button>
        </div>

        <Modal
          title={ state.mode==="activate" ?
            "Are you sure you want to activate this?" :
            state.mode==="de-activate" ?
              "Are you sure you want to de-activate this?" :
              state.mode==="delete" ?
                "Are you sure you want to delete this?":
                state.mode==="update" ?
                  "Update":
                  "Add New Tag"
          }
          open={state.openDialogue}
          onCancel={() => setState({
            ...state,
            openDialogue: false,
            selectedData: null,
            mode:""
          })}
          onOk={() => {
            state.mode==="de-activate" ? deActivateFunc() :
              state.mode==="activate" ? activateFunc() :
                state.mode==="delete" && deleteFunc()
          }}
        />

        <Table
          pagination={pagination}
          onChange={(page) => {
            setPagination(page);
          }}
          style={{ marginTop: 10 }}
          columns={columns}
          dataSource={updatedArray}
        />
      </div>
  );
}

export default AllSegments;
