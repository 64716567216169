import React, {useState, useEffect} from "react";
import {Form, Input, Button, Checkbox, Radio, Tabs} from "antd";
import {MinusCircleOutlined, PlusOutlined, EditOutlined, UndoOutlined} from "@ant-design/icons";
import {IoIosAdd, IoIosRemove} from "react-icons/io";
import MediaFile from "./fields/media";
import TextArea from "antd/es/input/TextArea";
import CustomRadioGroup from "./fields/radio";
import {useNodes} from "../../allnode";
import MediaRenderer from "./fields/mediaRender";
import Loopback from "./loopBack";


const DynamicForm =(props)=> {
    const {showEdit} = useNodes();
    const [ifMedia, setIfMedia] = useState(false)
    const [fallbackResponse, setFallbackResponse] = useState(false);
    const [textOption, setTextOption] = useState({
        text: true,
        media: false,
        loopback: false,
        template: false,
    });

    // submitting form
    const showFallback = () => {
        setFallbackResponse((prevState) => !prevState);
    };

    const showMedia = () => {
        setIfMedia((prevState) => !prevState);
    };

    const switchTextSection = async (section) => {
        setTextOption((prevState) => {
            const updatedOptions = {...prevState};
            Object.keys(updatedOptions).forEach((key) => {
                updatedOptions[key] = key === section;
            });
            return updatedOptions;
        });
    };

    useEffect(() => {
        // set media
        if (showEdit?.data?.fileKey && showEdit?.data?.url) {
            setIfMedia(true)
        }
        setTextOption({
            text: true,
            media: false,
            loopback: false,
            template: false
        });
    }, [showEdit])

    return (
        <>
                {/* response section */}
                <div className="response-container mt-4">
                    <h3 className="sub-heading text-left">Bot Response</h3>
                    <div className="main-btn mb-4">
                        <div className="button-container">
                            <Button
                            onClick={() => switchTextSection("text")}
                            className={textOption.text && "active"}
                            >
                            Text
                            </Button>
                            <Button
                            onClick={() => switchTextSection("media")}
                            className={textOption.media && "active"}
                            >
                            Media
                            </Button>
                            <Button
                            onClick={() => switchTextSection("loopback")}
                            className={textOption.loopback && "active"}
                            >
                            Loopback
                            </Button>
                        </div>
                    </div>
                    <div
                        className={'message-text-section message-inner-section form-section' + (textOption.text ? 'active' : '')}>
                        <Form.Item
                            name="message"
                        >
                            <TextArea className="style-text-area" placeholder="Enter Message"/>
                        </Form.Item>
                        <div className="bot-menu-list">
                            <h3>Bot Menu</h3>
                            <div style={{marginTop: 8}}/>
                            <Form.List name="options" initialValue={['']}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={field.key} className="form-list-item">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "value"]}
                                                    fieldKey={[field.fieldKey, "value"]}
                                                    className="style-input"
                                                    rules={[
                                                        {
                                                            required: index === 0 ? false : true,
                                                            message: 'Option cant be empty',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Option"/>
                                                </Form.Item>

                                            </div>
                                        ))}
                                        <div style={{display:'flex', justifyContent:'end', gap:10, marginRight:10}}>
                                            {fields.length !== 0 && (
                                                <Button
                                                    type="primary"
                                                    icon={<MinusCircleOutlined
                                                    />}
                                                    onClick={() => remove(fields.length - 1)}
                                                />
                                            )}
                                            <Button
                                                type="primary"
                                                icon={<PlusOutlined
                                                />}
                                                onClick={() => add()}
                                            />
                                        </div>
                                    </>
                                )}
                            </Form.List>
                        </div>
                        <div className="fallback-response" style={{marginTop:20}}>
                            <div className="main-radio-btn">
                                <Form.Item name="botToHuman" valuePropName="checked">
                                    <Checkbox className="Checkbox">Bot to human handover</Checkbox>
                                </Form.Item>
                                <Button type="primary" onClick={showFallback} style={{height:36}}>
                                    {fallbackResponse ? (
                                        <>
                                            {
                                                <IoIosRemove
                                                    style={{fontSize: "20px"}}
                                                />
                                            }{" "}
                                            All Fallback Response{" "}
                                        </>
                                    ) : (
                                        <>
                                            {
                                                <IoIosAdd
                                                    style={{fontSize: "20px"}}
                                                />
                                            }{" "}
                                            All Fallback Response{" "}
                                        </>
                                    )}
                                </Button>
                            </div>
                            {fallbackResponse && (
                                <Form.Item name="fallBackResponse">
                                    <TextArea
                                        className="style-text-area"
                                        placeholder="Enter Message"
                                    />
                                </Form.Item>
                            )}
                        </div>
                    </div>

                    <div
                        className={'message-media-section message-inner-section form-section' + (textOption.media ? 'active' : '')}>
                        <Form.Item
                            name="message"
                            rules={[
                                {
                                    message: "Enter Media Caption",
                                },
                            ]}
                        >
                            <TextArea className="style-text-area" placeholder="Enter Caption"/>
                        </Form.Item>

                        {ifMedia && showEdit?.data?.fileKey && showEdit?.data?.fileKey ?
                            <div className="media-container position-relative"><EditOutlined className="edit-media-btn"
                                                                                             onClick={showMedia}/><MediaRenderer
                                type={showEdit?.data?.mediaType} url={showEdit?.data?.url} showMedia={showMedia}/>
                            </div> :
                            <div className="position-relative">
                                {showEdit?.data?.url && showEdit?.data?.mediaType &&
                                    <UndoOutlined className="edit-media-btn" onClick={showMedia}
                                                  title="show previous one"/>}
                                <div className="d-flex justify-content-center">
                                    <CustomRadioGroup label="Select media type" name="mediaType">
                                        <Radio value="image">Image</Radio>
                                        <Radio value="document">Document</Radio>
                                        <Radio value="audio">Audio</Radio>
                                        <Radio value="video">Video</Radio>
                                    </CustomRadioGroup>
                                </div>
                                <div className="d-flex justify-content-center flex-column align-items-center">
                                    <MediaFile name="media" label="Upload Media"/>
                                    <h5>PNG and JPEG: Up to 5MB, MP3 and MP4: Up to 16MB, PDF: Up to 25MB</h5>
                                </div>
                            </div>
                        }
                    </div>
                    {/* Loop back list */}
                    {textOption.loopback &&    
                        <Loopback showMessage={true} />
                    }
                    
                </div>
        </>
    );
};

export default DynamicForm;
