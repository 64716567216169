import React from 'react'
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/media/misc/Komkast Symbol transparent.png"


const Footer = () => {
    const { t } = useTranslation();

  return (
    <div>
        <div style={{display:"flex",gap:"7px",justifyContent:"center",marginBottom:"20px"}} /* className="d-flex fw-semibold text-primary fs-base gap-5" */>
        <div style={{ color: "rgb(54, 58, 119)" ,fontWeight:"bold"}}>Powered by KomKast global technologies LLC ©</div>
        <div>
            <img src={Logo} alt='' style={{height:"30px" ,marginTop:"-8px"}}/>
        </div>
        <a
          href="https://tiebot.io/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div style={{ color: "rgb(54, 58, 119)" ,fontWeight:"bold"}}>Privacy Policy</div>
        </a>
        <div style={{border:"1px solid #686565",height:"13px",marginTop:"4px"}}></div>
        <a
          href="https://tiebot.io/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div style={{ color: "rgb(54, 58, 119)" ,fontWeight:"bold"}}>Terms & Condition</div>
        </a>
        </div>
    </div>
  )
}

export default Footer
