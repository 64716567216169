import React, { useRef, useState ,useEffect} from 'react';
import { Button, Input, Space, Table,Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { submission} from "./Api/Api" 
import "./Api.scss"
import moment from "moment";
import { PiDownloadSimpleBold } from "react-icons/pi";
import ApiDownload from "./Apidownload"




const ApiSubmission = (props) => {
  const [state, setState] = useState({
    ApiData: [],
    loading: true,
    modal:false
  });

  const modalOpen = () => {
    setState(prevState => ({
      ...prevState,
      modal: true
    }));
  };
  const { id } = props;

  const fetchData = () => {
    setState({...state, loading: true})
    submission(id)
        .then(response => {
            const formattedData = response.data.data.map(obj => ({
                ...obj,
                created: moment(obj.created).format('MMMM Do YYYY, h:mm a')
            }));
            setState(prevState => ({
                ...prevState,
                ApiData: formattedData,
                loading: false
            }));
            console.log(response,6666)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setState(prevState => ({...prevState, loading: false}));
        });
}
useEffect(()=>{
    fetchData()
},[])



  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex )}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
             
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
        title: t("Name"),
        dataIndex: ['userInfo', 'name'], 
        key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: "API Name",
      dataIndex: ['api', 'name'],
      key: 'apiName',
      render: (text, record) => record.api.name,
    },
    {
        title: t("Phone"),
        dataIndex: ['userInfo', 'phone'], 
        key: 'phone',
    },{
        title: t("Type"),
        dataIndex: 'type',
        key: 'type',
      },{
        title: t("Created")  ,
        dataIndex: 'created',
        key: 'created',
      },
      {
        title: t("Response"),
        dataIndex: 'response',
        key: 'created',
        render: (text, record) => record && record.response 
      },
          
      {
        title: t("Downloads"),
        dataIndex: 'Downloads',
        key: 'Downloads',
      width: 150,
      render: (_, record) => (
        <a onClick={modalOpen}>
          <PiDownloadSimpleBold style={{ fontSize: '20px', cursor: 'pointer' }} />
        </a>
      )
    },
    
  ];
  
  

  return (
    <div className='modal-table'>
      <Modal
            title= "Download File"
                visible={state.modal}
                onCancel={() => setState(prevState => ({ ...prevState, modal: false }))}
                footer={null} 
                width={500}
                >
                <div>
                  <ApiDownload/>
                </div>
                </Modal>
      <Table
      style={{marginTop:"2%"}}
        columns={columns}
        dataSource={state.ApiData}
      />
  </div>
  );
};

export default ApiSubmission;
