
const initialState = {
  users: [],
  allConversations: [],
  allWhatsappConversations: [],
  allMessengerConversations: [],
  allInstagramConversations: [],
  data: [],
  inboxActiveTab:''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_WHATSAPP_CONVERSATIONS":
      return {
        ...state,
        allWhatsappConversations: action.payload
      };
    case "GET_ALL_MESSENGER_CONVERSATIONS":
      return {
        ...state,
        allMessengerConversations: action.payload
      };
    case "GET_ALL_INSTAGRAM_CONVERSATIONS":
      return {
        ...state,
        allInstagramConversations: action.payload
      };
    case "GET_WHATSAPP_CONVERSATION":
      let data = state.allWhatsappConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: [...action.payload.messages, ...obj.messages] };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allWhatsappConversations: data
      };
    case "MARK_MESSAGE_AS_READ":
      let chatindex = state.allWhatsappConversations.findIndex(obj => obj?.conversationId === action.payload.conversationId);
      if (chatindex !== -1) {
        const messageIndex = state.allWhatsappConversations[chatindex]?.messages.findIndex(item => item?.waMessageId === action.payload.messageId);
        if (messageIndex !== -1) {
          const updatedAllWhatsappConversations = state.allWhatsappConversations.map((conversation, index) => {
            if (index === chatindex) {
              const updatedMessages = conversation.messages.map((message, msgIndex) => {
                if (msgIndex === messageIndex) {
                  return {
                    ...message,
                    readBy: {
                      ...message.readBy,
                      status: true
                    }
                  };
                }
                return message;
              });
              return {
                ...conversation,
                messages: updatedMessages
              };
            }
            return conversation;
          });
          return  {
            ...state,
            allWhatsappConversations: updatedAllWhatsappConversations
          };
        } else {
          return  {
            ...state,
            allWhatsappConversations: state.allWhatsappConversations
          };
        }
      }

    case "MARK_MESSAGE_AS_DELIVERED":
      let selectedConv = state.allWhatsappConversations.findIndex(obj => obj?.conversationId === action.payload.conversationId);
      if (selectedConv !== -1) {
        const messageIndex = state.allWhatsappConversations[selectedConv]?.messages.findIndex(item => item?.waMessageId === action.payload.messageId);
        if (messageIndex !== -1) {
          const updatedAllWhatsappConversations = state.allWhatsappConversations.map((conversation, index) => {
            if (index === selectedConv) {
              const updatedMessages = conversation.messages.map((message, msgIndex) => {
                if (msgIndex === messageIndex) {
                  return {
                    ...message,
                    deliveredAt: {
                      ...message.deliveredAt,
                      status: true
                    }
                  };
                }
                return message;
              });
              return {
                ...conversation,
                messages: updatedMessages
              };
            }
            return conversation;
          });
          return  {
            ...state,
            allWhatsappConversations: updatedAllWhatsappConversations
          };
        } else {
          return  {
            ...state,
            allWhatsappConversations: state.allWhatsappConversations
          };
        }
      }

    case "MARK_MESSAGE_AS_SENT":
      let conversationIdx = state.allWhatsappConversations.findIndex(obj => obj?.conversationId === action.payload.conversationId);
      if (conversationIdx !== -1) {
        const messageIndex = state.allWhatsappConversations[conversationIdx]?.messages.findIndex(item => item?.waMessageId === action.payload.messageId)
        if (messageIndex !== -1) {
          const updatedAllWhatsappConversations = state.allWhatsappConversations.map((conversation, index) => {
            if (index === conversationIdx) {
              const updatedMessages = conversation.messages.map((message, msgIndex) => {
                if (msgIndex === messageIndex) {
                  return {
                    ...message,
                    sentAt: {
                      ...message.sentAt,
                      status: true
                    }
                  };
                }
                return message;
              });
              return {
                ...conversation,
                messages: updatedMessages
              };
            }
            return conversation;
          });
          return  {
            ...state,
            allWhatsappConversations: updatedAllWhatsappConversations
          };
        }
      }

    case "UPDATE_INBOX_CONVERSATION":
      let updateConv = state.allWhatsappConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, status:action.payload.status, unreadMessages:action.payload.unreadMessages, assign:action.payload.assign };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allWhatsappConversations: updateConv
      };

      case "REMOVE_WHATSAPP_CONVERSATION":
      let filteredChats = state.allWhatsappConversations.filter((obj) => obj.conversationId!==action.payload.conversationId)
      return {
        ...state,
        allWhatsappConversations: [...filteredChats]
      };

    case "DELETE_WHATSAPP_CONVERSATION":
      let updatedData = state.allWhatsappConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: [] };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allWhatsappConversations: updatedData
      };
    case "GET_MESSENGER_CONVERSATION":
      let messengerConversation = state.allMessengerConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: action.payload.messages };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allMessengerConversations: messengerConversation
      };
    case "GET_INSTAGRAM_CONVERSATION":
      let instagramConversation = state.allInstagramConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: action.payload.messages };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allInstagramConversations: instagramConversation
      };
    case "UPDATE_WHATSAPP_CONVERSATION":
      let updatedConversation = state.allWhatsappConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: [...obj.messages, action.payload] };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allWhatsappConversations: updatedConversation
      };
    case "UPDATE_MESSENGER_CONVERSATION":
      let updatedMessengerConversation = state.allMessengerConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: [...obj.messages, action.payload] };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allMessengerConversations: updatedMessengerConversation
      };
    case "UPDATE_INSTAGRAM_CONVERSATION":
      let updatedInstagramConversation = state.allInstagramConversations.map((obj, idx) => {
        if (obj.conversationId === action.payload.conversationId) {
          return { ...obj, messages: [...obj.messages, action.payload] };
        }
        else {
          return obj;
        }
      });
      return {
        ...state,
        allInstagramConversations: updatedInstagramConversation
      };
    case "UPDATE_All_WHATSAPP_CONVERSATION":
      let getMessages = state.allWhatsappConversations.find(item => item?.conversationId === action.payload?.conversationId)
      let updatedArray = state.allWhatsappConversations.filter(obj =>
        obj.conversationId !== action.payload.conversationId
      )
      console.log(getMessages, 664421111)
      return {
        ...state,
        allWhatsappConversations: !!getMessages && !getMessages?.isForwarded ?
          [
          {
            ...action.payload,
            messages: !!getMessages?.messages ? getMessages.messages : action.payload.messages
          },
          ...updatedArray
        ] : state.allWhatsappConversations
      };

    case "ADD_FORWARD_CHAT":
      return {
        ...state,
        allWhatsappConversations: state.inboxActiveTab === 'forward' ?
          [
            action.payload,
            ...state.allWhatsappConversations
          ] :
          [...state.allWhatsappConversations]
      };

    case "INBOX_ACTIVE_TAB":
      return {
        ...state,
        inboxActiveTab: action.payload
      };

    default:
      return state;
  }
};

export default reducer