import React, { useTransition } from 'react';
import FormBuilder from "./components/FormBuilder/FormBuilder";
import {Tabs} from 'antd';
import Api_management from "../../Settings/Api_mangement/Api_mangement";
import { useTranslation } from 'react-i18next';

const BotSettings = () => {
    const {t} = useTranslation()
    const items = [
        {
            key: '1',
            label: t('Form Builder'),
            children: <FormBuilder/>,
        },
        {
            key: '2',
            label: t('Api Management'),
            children: <Api_management/>,
        }
    ];
    return <div className={'synctic-module-container'} style={{overflow:'hidden'}}>
        <div className={'cards-box'} style={{padding:0}}>
            <Tabs
                defaultActiveKey="1"
                tabPosition={'left'}
                items={items}
            />
        </div>
    </div>
}

export default BotSettings