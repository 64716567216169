import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

const ConvPerAgentChart = ({  data }) => {
    const Nodata = !data || !data.labels || !data.datasets || data.labels.length === 0 || data.datasets.length === 0;
    const dummy = {
        labels: ['Data is not available'],
        datasets: [
            {
                data: [1], 
                backgroundColor: ["#E0E0E0"], 
                hoverBackgroundColor: ["#E0E0E0"],
            },
        ],
    };

    return (
        <div style={{ height: 250, marginTop: 10, width: '100%' }}>
            <Doughnut 
                style={{ margin: "auto" }} 
                height={300} 
                width={300} 
                data={Nodata ? dummy : data} 
            />
        </div>
    );
}

export default ConvPerAgentChart;
