import React, { useState, useEffect } from "react";
import Cards from "../reuseableComponents/Cards";
import "./overview.scss";
import ProgressChart from "./ProgressChart";
import BarChart from "../../../Reports/Barchart";
import LineChart from "../../../Reports/LineChart";
import { Checkbox, Modal, Button } from "antd";
import { tierUpdate } from "../Api/Api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getDashboardOverview,
  getSpeedMeter,
} from "../../states/actions";
import { useTranslation } from "react-i18next";

const Overview = (props) => {
  const [state, setState] = useState({
    tCustomers: false,
    mac: false,
  });
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const deliveredMessages = useSelector((state) => state.dashboard?.overview?.receivedMessages || []);
  const deliveredMessagesData = {
    label: deliveredMessages?.label || [],
    value: deliveredMessages?.value || [],
  };
  const sendMessages = useSelector((state) => state.dashboard?.overview?.sentMessages || []);  
    const sendMessagesData = {
    label: sendMessages?.label || [],
    value: sendMessages?.value || [],
    };
  const sent = useSelector((state) => state.dashboard?.overview?.sent);
  const receive = useSelector((state) => state.dashboard?.overview?.receive);
  const totalCustomer = useSelector(
    (state) => state.dashboard?.overview?.totalCustomer
  );
  const activeUserData = useSelector((state) => {
    const activeUser = state.dashboard?.overview?.activeUser;
    return {
      labels: activeUser?.label ?? ["Data is not available"],
      datasets: [
        {
          label: "Active Users",
          data: activeUser?.users ?? [0],
          backgroundColor: "#10163a",
          borderColor: "#10163a",
        },
      ],
    };
  });
  const speedmeterData = useSelector((state) => state.dashboard?.Speed);
  const percentage = speedmeterData
    ? (speedmeterData.available / speedmeterData.limit) * 100
    : 0;


  const fetchTier = () => {
    tierUpdate()
      .then((res) => {
        dispatch(getSpeedMeter(res?.data?.data));
      })
      .catch((error) => {
        console.error("Error fetching analytic stats data:", error);
      });
  };
  useEffect(() => {
    dispatch(getDashboardOverview())
    fetchTier();
  }, []);
  let { title, value, modalOpenFunc, isModalOpen } = props;
  const data = {
    labels: ["January", "February", "March", "May", "June"],
    datasets: [
      {
        data: [65, 59, 80, 81, 56],
        backgroundColor: "#10163a", // Bar color
        borderWidth: 1, // Border width,
        width: "70%",
      },
    ],
  };
  const modalFooter = [
    <Button key="cancel" onClick={() => modalOpenFunc()}>
      Cancel
    </Button>,
  ];
  return (
    <div>
      <div className="count-card-container">
        <Cards title={t("Total-Message-Sent")} value={sent || 0} />
        <Cards title={t("Total-Message-Received")} value={receive || 0} />
        <Cards title={t("Total-Customers")} value={totalCustomer || 0} />
      </div>
      <div
        className="count-card-container"
        style={{
          height: 250,
          display: "grid",
          gridTemplateColumns: "22% 22% 56%",
          justifyContent: "space-between",
        }}
      >
        <div className="cards-box">
          {speedmeterData &&
          speedmeterData.available !== null &&
          speedmeterData.limit !== null &&
          speedmeterData.available > 0 ? (
            <ProgressChart
              titleOne={t("Used")}
              valueOne={speedmeterData.available}
              titleTwo={t("Tier-Limit")}
              valueTwo={speedmeterData.limit}
              heading={t("Month Active Users (Current Month)")}
              percentage={percentage}
            />
          ) : (
            <ProgressChart
              titleOne={"Used"}
              valueOne={speedmeterData?.available || 0}
              titleTwo={"Tier Limit"}
              valueTwo={speedmeterData?.limit || 0}
              heading={"Month Active Users (Current Month)"}
              percentage={percentage || 0}
            />
          )}
        </div>
        <div className="cards-box">
          {speedmeterData &&
          speedmeterData.available !== null &&
          speedmeterData.limit !== null &&
          speedmeterData.available > 0 ? (
            <ProgressChart
              titleOne={t("Total Agent Seats")}
              valueOne={speedmeterData.available}
              titleTwo={t("Active Agent Seats")}
              valueTwo={speedmeterData.limit}
              heading={t("Agent Seats")}
              percentage={percentage}
            />
          ) : (
            <ProgressChart
              titleOne={"Total Agent Seats"}
              valueOne={speedmeterData?.available || 0}
              titleTwo={"Active Agent Seats"}
              valueTwo={speedmeterData?.limit || 0}
              heading={"Agent Seats"}
              percentage={percentage || 0}
            />
          )}
        </div>
        <div style={{ width: "97%" }} className={"cards-box"}>
          <BarChart
            height={210}
            data={activeUserData || data}
            heading={t("Monthly Active Users (Last Six Months)")}
          />
        </div>
      </div>
      <LineChart heading={t("Messages Received")} data={deliveredMessagesData} />
      <LineChart heading={t("Messages Sent")} data={sendMessagesData} />
      <Modal
        title={"Export Insights"}
        open={isModalOpen}
        onCancel={() => {
          modalOpenFunc();
          setState({ ...state, mac: false, tCustomers: false });
        }}
        footer={modalFooter}
        closable={() => {
          modalOpenFunc();
          setState({ ...state, mac: false, tCustomers: false });
        }}
      >
        <div style={{ fontWeight: "550" }}>Include breakdown by</div>
        <div style={{ marginTop: 6 }} />
        <Checkbox
          checked={state.mac}
          onChange={() => setState({ ...state, mac: !state.mac })}
        >
          Monthly Active Users
        </Checkbox>
        <div style={{ marginTop: 4 }} />
        <Checkbox
          checked={state.tCustomers}
          onChange={() => setState({ ...state, tCustomers: !state.tCustomers })}
        >
          Total Customers
        </Checkbox>
      </Modal>
    </div>
  );
};
export default Overview;
