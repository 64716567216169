import axios from "../../../Authentication/headers"

export function whatsappAnalytic(){
    return axios.get(`/message/analytic/overview`)
}
export function analyticPerformance(){
    return axios.get(`/message/analytic/agentPerformance`)
}
export function tierUpdate(){
    return axios.get(`/account/tierUpdate`)
}
export function loginActivity(){
    return axios.get(`/account/loginActivity`)
}
export function conversationStats(){
    return axios.get(`/message/analytic/conversationStats`)
}
export function AllBotData(){
    return axios.get(`/message/analytic/botStats`)
}
export function filterSumSearch(data){
    return axios.post('/message/analytic/overviewFilter', data)
}
export function agentPerformanceFilter(data){
    return axios.post('/message/analytic/agentPerformanceFilter', data)
}
export function conversationFilter(data){
    return axios.post('/message/analytic/conversationFilter', data)
}
export function botStatsFilter(data){
    return axios.post('/message/analytic/botStatsFilter', data)
}
export function AllcustomerVoice(){
    return axios.get(`/message/analytic/customerVoice`)
}
export function CustomerVoiceFilter(data){
    return axios.post('/message/analytic/customerVoiceFilter', data)
}