import React, { useState ,useEffect} from 'react';
import { Table , Modal } from 'antd';
import { FormsubmissionApi} from "./Apis/api" 
import moment from "moment";
import { PiDownloadSimpleBold } from "react-icons/pi";
import Formdownload from './Formdownload';
import { useParams } from 'react-router-dom';
import SettingsHeader from '../../../../ResuseableComponnets/SettingsHeader';
import { FaEye } from "react-icons/fa";
import FormData from './FormData';
import { useTranslation } from 'react-i18next';

const Formsubmission = () => {
  const {t} = useTranslation()
  const [state, setState] = useState({
    ApiData: [],
    DataType: [],
    filters:[],
    loading: true,
    selectedRecord: null,

  });

  const {id} = useParams();

  const modalOpen = () => {
    setState(prevState => ({
      ...prevState,
      modal: true
    }));
  };


  const NewOpen = (record) => {
    setState(prevState => ({ ...prevState, Modal: true, selectedRecord: record }));
  };



  const handleTableChange = (pagination, filters, sorter) => {
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize
      },
      filters: filters,
    }));
    fetchData({
      ...pagination,
      filters,
      sorter
    });
  };
  const fetchData = () => {
    setState({...state, loading: true})
    FormsubmissionApi(id)
        .then(response => {
            const formattedData = response.data.data.map(obj => ({
                ...obj,
                created: moment(obj.created).format('MMMM Do YYYY, h:mm a')
            }));
            setState(prevState => ({
                ...prevState,
                ApiData: formattedData,
                loading: false
            }));
            console.log(response,9999)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setState(prevState => ({...prevState, loading: false}));
        });
}

useEffect(()=>{
    fetchData()
},[])

  const columns = [
    {
      title: t('Full Name'),
      dataIndex: ['userInfo', 'name'], 
      key: t('name'),
      fixed: 'left',
      filters: [
        { text: 'Newest', value: 'newest' },
        { text: 'Oldest ', value: 'Oldest ' }
      ],
      onFilter: (value, record) => {
        console.log(record.userInfo.name,8888)
        return record.userInfo.name.includes(value);
      }
    },
    {
      title: t('Phone'),
      dataIndex: ['userInfo', 'phone'], 
      key: 'phone',
      fixed: 'left',
      filters: [
        { text: 'Newest', value: 'newest' },
        { text: 'Oldest ', value: 'Oldest ' }
      ],
      onFilter: (value, record) => {
        return record.userInfo.phone.includes(value);
      }

    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Newest', value: 'newest' },
        { text: 'Oldest ', value: 'Oldest ' }
      ],
      onFilter: (value, record) => {
        return record.userInfo.type.includes(value);
      }
    },
    {
        title: t("Created"),
        dataIndex: 'created',
        key: 'created',
      filters: [
        { text: 'Newest', value: 'newest' },
        { text: 'Oldest ', value: 'Oldest ' }
      ],
      onFilter: (value, record) => {
        return record.created.includes(value);
      }
    },
    {
      title: t("Downloads"),
      dataIndex: 'Downloads',
      key: 'Downloads',
    render: (_, record) => (
      <a onClick={modalOpen}>
        <PiDownloadSimpleBold style={{ fontSize: '20px', cursor: 'pointer' }} />
      </a>
    )
  },
  {
    title: t("View Submissions"),
    dataIndex: 'Downloads',
    key: 'Downloads',
    render: (_, record) => (
      <a onClick={() => NewOpen(record)}>
        <FaEye style={{ cursor: 'pointer' }} />
      </a>
    )
  },  
  ];



  return (
    <div  className="synctic-module-container">
      <SettingsHeader
      heading={t("Form Submission")}
      />
      <Modal
        title={t("Submission")}
        visible={state.Modal}
        onCancel={() => setState(prevState => ({ ...prevState, Modal: false }))}
        footer={null}
        width={700}
      >
        <FormData selectedRecord={state.selectedRecord} />
      </Modal>
                <Modal
                title= "Download File"
                visible={state.modal}
                onCancel={() => setState(prevState => ({ ...prevState, modal: false }))}
                footer={null} 
                width={500}
                >
                <div>
                  <Formdownload/>
                </div>
                </Modal>
                <Table columns={columns}
                style={{marginTop:"10px"}}
                dataSource={state.ApiData}
                  onChange={handleTableChange}
                  />
        
    </div>
  );
};

export default Formsubmission;
