import { useState, useEffect } from "react";
import "./Workspace.scss";
import { BsFillBuildingsFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader';
import { Modal, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Input, Select, Button } from 'antd';
import { profileInformation } from './Api/Api';
import axios from "../../../Modules/Authentication/headers";
import openNotificationWithIcon from "../../ResuseableComponnets/Toaster";



const WorkspaceData = (props) => {
    const { t } = useTranslation();
    const { TextArea } = Input;
    const [state, setState] = useState({
        address: "",
        description: "",
        vertical: "",
        about: "",
        email: "",
        websites: [],
        profile_picture_url: "",
        fileList: [],
        previewVisible: false,
        previewTitle: '',
        previewImage: '',
        Loader: false,
    });


    const profileData = () => {
        const formData = new FormData();
        const data = {
            address: state.address,
            description: state.description,
            vertical: state.vertical,
            about: state.about,
            email: state.email,
            websites:state.websites
        };

        formData.append('data', JSON.stringify(data));
        const config = {     
            headers: { 'Content-Type': 'multipart/form-data' }
        };
    
        if (props.id) {
            axios.put(`/channel/wabaUpdate/${props.id}`, formData, config)
            .then((res) => {
                openNotificationWithIcon('success',
                    'Success Message',
                    res.data?.message
                )           
             })
            .catch((error) => {
                console.error(error);
            });
        }
    };
    
    const fetchData = () => {
    !! props.id &&  profileInformation(props.id)
            .then((res) => {
                setState(prevState => ({
                    ...prevState,
                    address: res?.data?.data?.address,
                    description: res?.data?.data?.description,
                    vertical: res?.data?.data?.vertical,
                    about: res?.data?.data?.about,
                    email: res?.data?.data?.email,
                    websites: res?.data?.data?.websites
                }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState(prevState => ({
            ...prevState,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        }));
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleChange = ({ fileList }) => {
        setState(prevState => ({
            ...prevState,
            fileList
        }));
    };

    const handleCancel = () => setState(prevState => ({
        ...prevState,
        previewVisible: false
    }));

    const options = [
        "other",
        "auto",
        "beauty", 
        "apparel",
        "edu",
        "entertain",
        "event_plan",
        "finance", 
        "grocery",
        "govt", 
        "hotel",
        "health", 
        "nonprofit", 
        "prof_services",
        "retail", 
        "travel", 
        "restaurant"
    ];
    

    return (
        <div className="synctic-module-container" style={{height:"calc(-5rem + 90vh)"}}>
            <SettingsHeader
                icon={<BsFillBuildingsFill className='icon' />}
                heading={t("Workspace")}
                description={t("Manage-Your-WorkSpace-Settings")}
            />

            <div className='cards-box' style={{ marginTop: "10px" }}>
                <div className="profile-pic-container">
                    <Upload
                        listType="picture-card"
                        fileList={state.fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        accept="image/*"
                    >
                        {state.fileList.length >= 1 ? null : (
                            <div>
                                <UploadOutlined style={{ fontSize: '20px' }} />
                                <div style={{ textAlign: 'center' }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                    <Modal visible={state.previewVisible} title={state.previewTitle} footer={null} onCancel={handleCancel}>
                        <img alt="Preview" style={{ width: '100%' }} src={state.previewImage} />
                    </Modal>
                </div>

                <form style={{ display: "flex", flexDirection: "column" }}>
                    <div className='main-name-api'>
                        <div className='api-label-name'>Address</div>
                        <Input placeholder="Address"
                            value={state.address}
                            className='api-name-input'
                            onChange={(e) => setState(prevState => ({ ...prevState, address: e.target.value }))}
                        />
                    </div>

                    <div className='main-name-api'>
                        <div className='api-label-name'>Description</div>
                        <TextArea rows={4} placeholder="Description"
                            value={state.description}
                            className='api-name-input'
                            onChange={(e) => setState(prevState => ({ ...prevState, description: e.target.value }))}
                        />
                    </div>

                    <div className='main-name-api'>
                        <div className='api-label-name'>Options</div>
                        <Select
                            style={{ width: "100%", marginTop: "1%" }}
                            value={state.vertical}
                            onChange={(value) => setState(prevState => ({ ...prevState, vertical: value }))}
                        >
                            {options.map((option) => (
                                <Select.Option key={option} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                        
                    </div>

                    <div className='main-name-api'>
                        <div className='api-label-name'>About</div>
                        <Input placeholder="About"
                            value={state.about}
                            className='api-name-input'
                            onChange={(e) => setState(prevState => ({ ...prevState, about: e.target.value }))}
                        />
                    </div>

                    <div className='main-name-api'>
                        <div className='api-label-name'>Email</div>
                        <Input placeholder="Email"
                            value={state.email}
                            className='api-name-input'
                            onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                        />
                    </div>

                    <div className='main-name-api'>
            <div className='api-label-name' style={{ display: state.websites?.length > 0 ? "block" : "none" }}>
                Websites
            </div>
            <div style={{ display: "flex", gap: "10px", marginTop: "1%" }}>
                <Input 
                    placeholder="Website 1"
                    value={state.websites[0] || ""} 
                    className='api-name-input'
                    onChange={(e) => {
                        const newWebsites = [...state.websites];
                        newWebsites[0] = e.target.value;  
                        setState(prevState => ({ ...prevState, websites: newWebsites }));
                    }}
                />
                
                <Input 
                    placeholder="Website 2"
                    value={state.websites[1] || ""} 
                    className='api-name-input'
                    onChange={(e) => {
                        const newWebsites = [...state.websites];
                        newWebsites[1] = e.target.value; 
                        setState(prevState => ({ ...prevState, websites: newWebsites }));
                    }}
                />
            </div>
        </div>
                </form>

                <div style={{ display: "flex", gap: "10px", marginTop: "2%" }}>
                    <Button type="primary"
                     onClick={profileData}
                      style={{ margin: "2% auto"
                        , width: "50%"
                     }}
                      disabled={state.address==="" 
                        || state.description === "" ||
                        state.email==="" || state.vertical==="" || 
                        state.websites === ""
                      }
                      >Submit</Button>
                </div>
            </div>
        </div>
    );
};

export default WorkspaceData;
