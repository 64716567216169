import React from 'react';
import {Popconfirm } from 'antd';

const CustomConfirmPopup = (props) => {
    const confirm = () =>{
        props.form.submit();
    }
    
    const oncancel = () =>{
        console.log("event cancel")
    }


   
  return (
    <Popconfirm
      title="Update Node"
      description="Are you sure you want to update this node?"
      onConfirm={confirm}
      onCancel={oncancel}
    >
    {props.children}
    </Popconfirm>
  );
};
export default CustomConfirmPopup;