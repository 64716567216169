export const isLeftSectionLock = (data) => {
  return (dispatch) => {
    try {
      dispatch({
        type:"CHANGE_LEFT_SECTION_STATUS",
        payload:data
      })
    } catch {
      console.log("Error while getting your profile.")
    }
  }
}