import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const LineChart = ({ heading, data }) => {
    if (!data || !data.label || !data.value) {
        data = {
            label: ['Data is not available'],
            value: [0, 0, 0, 0, 0]
        };
    }

    const chartData = {
        labels: data.label,
        datasets: [
            {
                label: heading,
                data: data.value,
                fill: false,
                backgroundColor: "#DCDDDD",
                borderColor: "#10163a",                
                tension: 0.1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                type: 'linear', 
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
        },
    };
    
    return (
        <div className='cards-box' style={{ height: 250, marginTop: 10, width: '100%' }}>
            <h6>{heading}</h6>
            <Line style={{ margin: "auto", width: "100%", padding: 10 }} data={chartData} options={options} />
        </div>
    );
}

export default LineChart;
