import React, { useState } from 'react';
// import { ReactMic } from 'react-mic';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import axios from '../Authentication/headers';

function Audio(props) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const startRecording = () => {
    setIsRecording(true);
    props.isBlobExist(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const onData = (recordedBlob) => {
    // Do something with the recorded audio data if needed
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blob);
    setAudioUrl(URL.createObjectURL(recordedBlob.blob));
  };

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const sendAudio = () => {
    if (!audioBlob) {
      console.error('No audio recorded');
      return;
    }

    const formData = new FormData();
    formData.append("audio_message", audioBlob);
    formData.append('data', JSON.stringify(props.data));

    axios
      .post('/message/sendAudio', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        // Reset state after successful sending
        setAudioBlob(null);
        setAudioUrl(null);
        props.isBlobExist(false);
      })
      .catch((err) => {
        console.error('Error sending audio:', err);
        // Handle error and display to user
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <div>
          <div
            style={{
              display: isRecording ? '' : 'none',
            }}
          >
            {/*<ReactMic*/}
            {/*  record={isRecording}*/}
            {/*  className="sound-wave"*/}
            {/*  onStop={onStop}*/}
            {/*  onData={onData}*/}
            {/*  width={170}*/}
            {/*  height={40}*/}
            {/*  strokeColor="#000000"*/}
            {/*/>*/}
          </div>
        </div>
        {!audioBlob && isRecording === false ? (
          <FaMicrophone
            style={{ fontSize: 20, opacity: 0.8 }}
            onClick={startRecording}
          />
        ) : (
          !audioBlob && (
            <FaMicrophoneSlash
              style={{ fontSize: 20, opacity: 0.8 }}
              onClick={stopRecording}
            />
          )
        )}
      </div>

      {!!audioBlob && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <audio style={{ height: 40 }} controls src={audioUrl}></audio>
          <MdDelete
            style={{ fontSize: 20, marginLeft: 10, opacity: 0.8 }}
            onClick={() => {
              setAudioBlob(null);
              setAudioUrl(null);
              props.isBlobExist(false);
            }}
          />
          {/* <MdSend
            style={{ fontSize: 20, marginTop: 10, cursor: 'pointer', marginLeft : "20px" }}
            onClick={sendAudio}
          /> */}
        </div>
      )}
      {/* {audioBlob && (
        <div>
          <MdSend
            style={{ fontSize: 20, marginTop: 10, cursor: 'pointer' }}
            onClick={sendAudio}
          />
        </div>
      )} */}
    </div>
  );
}

export default Audio;
