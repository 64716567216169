import React, { useState, useEffect } from "react";
import { Button, Checkbox, Modal, Table, Tabs } from "antd";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import Cards from "../reuseableComponents/Cards";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBotData } from "../../states/actions";
import { useTranslation } from "react-i18next";
const BotConversataions = (props) => {
  const {t}= useTranslation()
  const dispatch = useDispatch();
  const [state, setState] = useState({
    tCustomers: false,
    mac: false,
    mode: "",
    whatsapp: "",
    instagram: "",
    messenger: "",
  });

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 3,
  });
  const [paginationTwo, setPaginationTwo] = React.useState({
    current: 1,
    pageSize: 3,
  });
  const botCustomers = useSelector((state) => state.dashboard?.Bot?.botCustomers);
  const botReceived = useSelector((state) => state.dashboard?.Bot?.botReceived);
  const averageBotTime = useSelector((state) => state.dashboard?.Bot?.averageBotTime);
  const botSentMessages = useSelector((state) => state.dashboard?.Bot?.botSentMessages
  );
  const BotTableData = useSelector(
    (state) => state.dashboard?.Bot?.engageCustomers || []
  );
  const visitedCategory = useSelector(
    (state) => state.dashboard?.Bot?.visitedCategory || []
  );
  useEffect(() => {
    dispatch(getBotData());
  }, []);
  let { title, value, isModalOpen, mode, modalOpenFunc } = props;
  const modalFooter = [
    <Button key="cancel" onClick={() => modalOpenFunc()}>
      Cancel
    </Button>,
    <Button key="apply" onClick={() => modalOpenFunc()} primary>
      Apply
    </Button>,
  ];
  const Columns = [
    {
      title: t("Trigger Name"),
      dataIndex: "triggerName",
      key: "triggerName",
    },
    {
      title: t("Bot Title"),
      dataIndex: "availableBotTitle",
      key: "availableBotTitle",
    },
    {
      title: t("Count"),
      dataIndex: "count",
      key: "count",
    },
  ];

  const engagedCustomersColumns = [
    {
      title: t("WhatsApp Number"),
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: t("WhatsApp Profile Name"),
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: t("Message Volume"),
      dataIndex: "count",
      key: "count",
    },
  ];
  const AgentsBoardColumns = [
    {
      title: "Agent",
      dataIndex: "agent",
      key: "1",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "2",
    },
    {
      title: "Accepted",
      dataIndex: "accepted",
      key: "3",
    },
    {
      title: "Solved",
      dataIndex: "solved",
      key: "4",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "5",
    },
    {
      title: "Avg. Response Time",
      dataIndex: "avgResponseTime",
      key: "6",
    },
    {
      title: "Avg. Resolution Time",
      dataIndex: "avgResolutionTime",
      key: "7",
    },
  ];

  const AgentsAvailibilityData = [];
  const items = [
    {
      key: "1",
      label: "Channels",
      children: (
        <div>
          <div style={{ fontWeight: "550" }}>Channel</div>
          <div style={{ marginTop: 6 }} />
          <Checkbox
            checked={state.whatsapp}
            onChange={() => setState({ ...state, whatsapp: !state.whatsapp })}
          >
            Whatsapp
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.messenger}
            onChange={() => setState({ ...state, messenger: !state.messenger })}
          >
            Messenger
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.instagram}
            onChange={() => setState({ ...state, instagram: !state.instagram })}
          >
            Instagram
          </Checkbox>
          <div style={{ marginTop: 4 }} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Agents",
      children: <AgentsFilterTable />,
    },
  ];
  const AllTableData = BotTableData.map((item, index) => ({
    key: index,
    customerName: item.customer.name,
    customerPhone: item.customer.phone,
    count: item.count,
  }));

  const Frequency = visitedCategory.map((item, index) => ({
    key: index,
    triggerName: item.botItem.data.triggerName,
    availableBotTitle: item.availableBot.title,
    count: item.count,
  }));

  return (
    <div>
      <div className="count-card-container">
        <Cards title={t("Messages Responded")} value={botSentMessages || 0} />
        <Cards title={t("Messaged Received")} value={botReceived || 0} />
        <Cards title={t("Avg. Chat Time")} value={averageBotTime || 0} />
        <Cards title={t("Total Customers")} value={botCustomers || 0} />
      </div>
      <div className={"cards-box"} style={{ marginTop: 10 }}>
        <h6>{t("Frequency of Categories Inquired")}</h6>
        <Table
          columns={Columns}
          dataSource={Frequency}
          pagination={pagination}
          onChange={(page) => {
            setPagination(page)
          }}
          style={{
            marginTop: 24,
          }}
        />
      </div>
      <div className={"cards-box"} style={{ marginTop: 10 }}>
        <h6>{t("Engaged Customers")}</h6>
        <Table
          pagination={paginationTwo}
          onChange={(page) => {
            setPaginationTwo(page)
          }}
          columns={engagedCustomersColumns}
          dataSource={AllTableData}
          style={{
            marginTop: 24
          }}
        />
      </div>
      <Modal
        title={
          mode === "export"
            ? "Export Insights"
            : mode === "export" && "Filter Agent Performance"
        }
        open={isModalOpen}
        onCancel={() => {
          modalOpenFunc();
          setState({
            ...state,
            mac: false,
            tCustomers: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
        footer={modalFooter}
        closable={() => {
          modalOpenFunc();
          setState({
            ...state,
            mac: false,
            tCustomers: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
      >
        {state.mode === "export" ? (
          <div>
            <div style={{ fontWeight: "550" }}>Include breakdown by</div>
            <div style={{ marginTop: 6 }} />
            <Checkbox
              checked={state.mac}
              onChange={() => setState({ ...state, mac: !state.mac })}
            >
              Total Customers
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.tCustomers}
              onChange={() =>
                setState({ ...state, tCustomers: !state.tCustomers })
              }
            >
              Frequency Of Categorizes Inquired
            </Checkbox>
            <div style={{ marginTop: 4 }} />
          </div>
        ) : (
          mode === "filter" && (
            <Tabs defaultActiveKey="1" tabPosition={"top"} items={items} />
          )
        )}
      </Modal>
    </div>
  );
};
export default BotConversataions;
