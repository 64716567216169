import React, {useState, useEffect} from 'react'
import SettingsHeader from '../../ResuseableComponnets/SettingsHeader'
import "./Chatwidget.scss"
import {IoIosInformationCircle} from "react-icons/io";
import {Button, Input} from 'antd';
import messengerImage from "../../../assets/svg/social-logos/messenger.png";
import whatappImage from "../../../assets/svg/social-logos/whatsappLogo.png";
import instaImage from "../../../assets/svg/social-logos/instagram.svg";
import { HexColorPicker } from "react-colorful";
import { ReactComponent as One } from './assets/svg-1.svg';
import { ReactComponent as Two } from './assets/svg-2.svg';
import { ReactComponent as Three } from './assets/svg-3.svg';
import { ReactComponent as Four } from './assets/svg-4.svg';
import Loader from "../../ResuseableComponnets/Loader";
import { useTranslation } from 'react-i18next';

import {
    getInstagramChatWidget,
    getMessengerChatWidget,
    getWhatsappChatWidget,
    saveInstagramChatWidget,
    saveMessengerChatWidget,
    saveWhatsappChatWidget
} from "./API/Api";
import axios from "axios";
import config from "../../../config";

const Chatwidget = () => {
      const {t} = useTranslation();
    const [state, setState] = useState({
        isMessenger:false,
        isWhatsapp:false,
        isIns:false,
        backgroundColor: "#000000",
        btnColor: "#000000",
        btnIconColor: "#000000",
        isLoader:false,
        companyLogo:"",
        channel:"",
        title:"",
        color:"#000000",
        backgroundOpen:false,
        btnColorOpen:false,
        btnIconColorOpen:false,
        mode:"whatsapp",
        whatappNum:"",
        messengerValue:"",
        instaValue:"",
        greetingMessage:"",
        svg:""
    })

    let configAuth = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
    }
    const handleChange = (event) => {
        let file = event.target.files[0];
        if (!file) {
            return;
        }
        setState({...state, companyLogo: file})
    };

    const saveFunction = () => {
        setState({...state, isLoader: true})
        const formData = new FormData();
           let data = {
               title: state.title,
               message: state.greetingMessage,
               channel: state.channel,
               widgetIcon: state.svg,
               style: {
                   backgroundColor: state.backgroundColor,
                   btnColor: state.btnColor,
                   btnIconColor: state.btnIconColor
               }
           }
        formData.append(`companyLogo`, state.companyLogo)
        formData.append('data', JSON.stringify(data));

        state.mode==="insta" ?
            axios.post(config.api.baseUrl + '/chatWidget/saveInstagram', formData, configAuth).then(()=>getAllData()).catch(()=>setState({...state, isLoader: false})) :
            state.mode==="whatsapp" ?
                axios.post(config.api.baseUrl + '/saveWhatsapp', formData, configAuth).then(()=>getAllData()).catch(()=>setState({...state, isLoader: false})) :
                axios.post(config.api.baseUrl + '/chatWidget/saveMessenger', formData, configAuth).then(()=>getAllData()).catch(()=>setState({...state, isLoader: false}))
    }

    const getAllData = () => {
        let getDataWidet = state.mode==="whatsapp" ? getWhatsappChatWidget() : state.mode==="messenger" ? getMessengerChatWidget() : getInstagramChatWidget()
        getDataWidet.then(res=> {
            let data = res.data.data
            setState({
                ...state,
                title: data.title,
                companyLogo: data.companyLogo,
                channel:data.channel,
                widgetIcon:data.widgetIcon,
                greetingMessage: data.message,
                backgroundColor: data.style.backgroundColor,
                btnColor: data.style.btnColor,
                btnIconColor: data.style.btnIconColor,
                isLoader: false
            })
        }).catch(()=>setState({...state, isLoader: false}))
        console.log(state.widgetIcon,87376636)
    }

    console.log(state, "22222222221114")

    useEffect(() => {
        setState({...state, isLoader: true})
        getAllData()
    }, [state.mode]);
    return (
        state.isLoader ? <Loader/> :
        <>
            <div className='synctic-module-container'>
                <SettingsHeader
                    heading={t("ChatWidget")}
                    description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's."
                />
                <div className='cards-box' style={{marginTop:10}}>
                <div className='chat-widget-container'>

                    <div className='Clickoniconbelow'>{t("Clickoniconbelow-dis")}
                    </div>

                    <div style={{display: "flex", gap: "20px"}}>
                        <div onClick={()=>setState({
                            ...state,
                            mode: "whatsapp"
                        })}
                             style={{
                                 padding:8,
                                 background:state.mode==="whatsapp" && "lightgrey",
                                 borderRadius:6
                        }}>
                            <img src={whatappImage} alt="img" className='whatsapp' style={{width:34}}/>
                        </div>
                        <div onClick={()=>setState({
                            ...state,
                            mode: "messenger"
                        })}
                             style={{
                                 padding:8,
                                 background:state.mode==="messenger" && "lightgrey",
                                 borderRadius:6
                        }}>
                            <img src={messengerImage} alt="img" style={{width:34}}/>
                        </div>
                        <div onClick={()=>setState({
                            ...state,
                            mode: "insta"
                        })}
                             style={{
                                 padding:8,
                                 background:state.mode==="insta" && "lightgrey",
                                 borderRadius:6
                        }}>
                            <img src={instaImage} alt="img" style={{width:30}}/>
                        </div>
                    </div>

                    {
                        state.mode==="whatsapp" && <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", gap: "10px", marginTop: "2%", alignItems:"center"}}>
                                <img src={whatappImage} style={{width:20}}/>
                                <div style={{fontWeight: "600", fontSize: "13px"}}>{t("Whatsapp")}</div>
                                <IoIosInformationCircle style={{fontSize: 16}}/>
                            </div>
                            <Input
                                style={{width: "50%", marginTop: "1%"}}
                                size="middle"
                                placeholder="Enter whatsapp number"
                                value={state.channel}
                                onChange={(e)=>setState({...state, channel: e.target.value})}
                                autoComplete="off"
                            />
                        </div>
                    }

                    {
                        state.mode==="messenger" && <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", gap: "10px", marginTop: "2%", alignItems:"center"}}>
                                <img src={messengerImage} style={{width:24}}/>
                                <div style={{fontWeight: "600", fontSize: "13px"}}>{t("Messenger")}</div>
                                <IoIosInformationCircle style={{fontSize: 16}}/>
                            </div>
                            <Input
                                style={{width: "50%", marginTop: "1%"}}
                                size="middle"
                                placeholder="Enter your email"
                                value={state.channel}
                                onChange={(e)=>setState({...state, channel: e.target.value})}
                                autoComplete="off"
                            />
                            <div style={{fontSize: "12px", opacity: '.7'}}> {t("Pleaseselectthe-dis")}
                            </div>
                        </div>
                    }

                    {
                        state.mode==="insta" &&
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", gap: "10px", marginTop: "2%", alignItems:"center"}}>
                                <img src={instaImage} style={{width:20}}/>
                                <div style={{fontWeight: "600", fontSize: "13px"}}>{t("Instagram")}</div>
                                <IoIosInformationCircle style={{fontSize: 16}}/>
                            </div>
                            <Input
                                style={{width: "50%", marginTop: "1%"}}
                                size="middle"
                                placeholder="Enter your email"
                                value={state.channel}
                                onChange={(e)=>setState({...state, channel: e.target.value})}
                                autoComplete="off"
                            />
                            <div style={{fontSize: "12px", opacity: '.7'}}>
                                {t("Pleaseselectthe-dis")}
                            </div>
                        </div>
                    }


                    <div className='Appearance'>Appearance</div>

                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <div style={{fontWeight: "500"}}>{t("Title")}</div>
                        <Input
                            style={{width: "50%"}}
                            size="middle"
                            placeholder="Enter title"
                            value={state.title}
                            autoComplete="off"
                            onChange={(e)=>setState({...state, title: e.target.value})}
                        />
                    </div>

                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <div style={{fontWeight: "500"}}>{t("backgroundColor")}</div>
                        <div onClick={()=>setState({
                            ...state,
                            backgroundOpen: !state.backgroundOpen
                        })}
                             style={{
                            height:60,
                            width:60,
                            borderRadius:10,
                            backgroundColor:!!state.backgroundColor ? state.backgroundColor : "#000000",
                                 marginTop:10
                        }}/>
                        {
                            state.backgroundOpen && <HexColorPicker color={state.backgroundColor} onChange={e=>setState({...state, backgroundColor:e, backgroundOpen: false })} />
                        }
                    </div>

                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <div style={{fontWeight: "500"}}>{t("ButtonColor")}</div>
                        <div onClick={()=>setState({
                            ...state,
                            btnColorOpen: !state.btnColorOpen
                        })}
                             style={{
                                 height:60,
                                 width:60,
                                 borderRadius:10,
                                 backgroundColor:!!state.btnColor ? state.btnColor : "#000000",
                                 marginTop:10
                             }}/>
                        {
                            state.btnColorOpen && <HexColorPicker color={state.btnColor} onChange={e=>setState({...state, btnColor:e, btnColorOpen: false })} />
                        }
                    </div>


                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <div style={{fontWeight: "500"}}>{t("ButtonIconcolor")}</div>
                        <div onClick={()=>setState({
                            ...state,
                            btnIconColorOpen: !state.btnIconColorOpen
                        })}
                             style={{
                                 height:60,
                                 width:60,
                                 borderRadius:10,
                                 backgroundColor:!!state.btnIconColor ? state.btnIconColor : "#000000",
                                 marginTop:10
                             }}/>
                        {
                            state.btnIconColorOpen && <HexColorPicker color={state.btnIconColor} onChange={e=>setState({...state, btnIconColor:e, btnIconColorOpen: false })} />
                        }
                    </div>


                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <div style={{fontWeight: "500"}}>{t("Companylogo")}</div>
                        {
                            !!state.companyLogo ?<div> <img style={{height:100,
                                width:100,
                                    borderRadius:10}} src={state.companyLogo} alt="img"/>
                                <p onClick={()=>setState({...state, companyLogo: ""})}>{t("Remove")}</p>
                            </div> :
                                <>
                                <label style={{marginTop:10}} htmlFor="file-input" className="upload-icon">
                                <div style={{
                                    height:100,
                                    width:100,
                                    display:"flex",
                                    borderRadius:10,
                                    justifyContent:"center",
                                    alignItems:"center",
                                    backgroundColor:"rgba(0,0,0,.2)"
                                }}>
                                    <p style={{margin:0}}>{t("Upload")}</p>
                                </div>
                            </label>
                                    <input
                                        id="file-input"
                                        onChange={handleChange}
                                        type="file"
                                        name="file"
                                        multiple
                                        style={{display: "none"}}
                                    />
                                </>
                        }
                    </div>

                    <div className='ChooseWidgetIcons'>{t("ChooseWidgetIcons")}</div>

                    <div style={{display: "flex", gap: "20px", marginTop: "17px"}}>
                        <div onClick={()=>setState({...state, svg: "one"})} style={{backgroundColor: state.svg==="one" && "lightgray", padding:8, borderRadius:6}}>
                        <One width="28" height="28" fill="red" />
                        </div>
                        <div onClick={()=>setState({...state, svg: "two"})} style={{backgroundColor:state.svg==="two" && "lightgray", padding:8, borderRadius:6}}>
                        <Two width="28" height="28" fill="currentColor" />
                        </div>
                        <div onClick={()=>setState({...state, svg: "three"})} style={{backgroundColor:state.svg==="three" && "lightgray", padding:8, borderRadius:6}}>
                        <Three width="28" height="28" fill="currentColor" />
                        </div>
                        <div onClick={()=>setState({...state, svg: "four"})} style={{backgroundColor:state.svg==="four" && "lightgray", padding:8, borderRadius:6}}>
                        <Four width="28" height="28" fill="currentColor" />
                        </div>
                    </div>

                    <div style={{marginTop: "2%", fontSize: "15px", fontWeight: "600"}}>{t("CustomizeyourButton")}</div>
                    <div style={{display: "flex", flexDirection: "column", marginTop: "2%"}}>
                        <div style={{fontWeight: "500"}}>{t("GreetingMessage")}</div>
                        <Input
                            style={{width: "50%"}}
                            size="middle"
                            placeholder={t("Enter-Email")}
                            value={state.greetingMessage}
                            onChange={(e)=>setState({...state, greetingMessage:e.target.value })}
                            autoComplete="off"
                        />
                    </div>

                    <Button onClick={()=>saveFunction()} style={{marginTop: "2%", backgroundColor: "green", color: "white"}} size="large"
                            placeholder="Enter your email" value="" autoComplete="off">
                        {t("GenerateWidgetCode")}
                    </Button>
                </div>
                </div>
            </div>
        </>
    )
}

export default Chatwidget
