import React from "react";
import { Table } from 'antd';
import { useTranslation } from "react-i18next";

const FormData = ({ selectedRecord }) => {
  const {t} = useTranslation()
  const columns = [
    {
      title: t('Info'),
      dataIndex: 'Info',
      key: 'Info',
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column", opacity: ".7" }}>
          <h3>Question</h3>
          <div>{record.question}</div>
          <hr></hr>
          <h3>Answer</h3>
          {typeof record.answer === "object" && (
            <div>
              {Object.keys(record.answer).map((key, index) => (
                <div key={index}>
                  <div style={{fontSize:"16px",color:"black"}}> {record.answer[key]}</div>
                </div>
              ))}
            </div>
          )}
          {typeof record.answer !== "object" && <div>{record.answer}</div>}
        </div>
      )
    },
  ];
  


  return (
    <div>
      <Table columns={columns}
    dataSource={selectedRecord.data} 
        />
    </div>
  );
};

export default FormData;
