import {
  FileImageFilled,
  FileTextFilled,
  VideoCameraFilled,
  DeleteFilled,
  BellFilled,
  NotificationFilled,
  LockFilled
}                        from '@ant-design/icons';
import ClassicEditor     from '@ckeditor/ckeditor5-build-classic';
import { CKEditor }      from '@ckeditor/ckeditor5-react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import Popover                                                    from '@mui/material/Popover';
import { Select, Input, Space, Radio, Checkbox, Button, Tooltip } from 'antd';
import axios                                                      from 'axios';
import EmojiPicker                                                from 'emoji-picker-react';
import React, { useState, useRef, useEffect }                     from 'react';
import './Templates.scss';
import { DeviceFrameset }                                         from 'react-device-frameset';
import { useParams }                                              from 'react-router-dom';
import InstagramBackgroundImage
                                                                  from '../../../assets/backgroundImages/whastappback.png';
import config                                                      from '../../../config';
import { getTemplateById, createAuthTemplate, updateAuthTemplate } from './API';

const { Option } = Select

const EditTemplates = () => {
  const [state, setState] = useState({
    teamplateName: "",
    language: "",
    category: "utility",
    headerType: 'text',
    mediaType: '',
    buttonType: 'call-to-action',
    selectedButton: 'none',
    allButtons: [],
    headerText: "",
    headerSample: "",
    bodyText: '',
    footerText: '',
    headerMedia: null,
    selectedButtonValues: [],
    bodyVariables: [],
    mediaFile: '',
    authCategory: 'autofill',
    expiresIn: 15,
    authCopyCode: 'Copy Code',
    packageName: "",
    appSignatureHash: "",
    addSecrityRecommendation: false,
    addExpireTime: true,
    authTextToShow: `<b>12345</b> is your verification code. `,
    websiteSampledata:""
  })
  const fileInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { id } = useParams()
  const addBtnFunc = (e) => {
    let data = e === 'visit-website' ? {
      type: 'url',
      text: '',
      country: '',
      url: '',
      urlType: '',
      phoneNumber: '',
      offerCode: '',
      sampleData: state.websiteSampledata
    } : e === 'call-phone-number' ? {
      type: 'phone_number',
      text: '',
      country: '',
      url: '',
      urlType: '',
      phoneNumber: '',
      offerCode: ''
    } : e === 'copy-offer-code' ? {
      type: 'offer_code',
      text: '',
      country: '',
      url: '',
      urlType: '',
      phoneNumber: '',
      offerCode: ''
    } : {
      action: e,
      text: ""
    }
    return setState(prevState => ({
      ...prevState,
      allButtons: [...state.allButtons, data],
      selectedButton: e,
      selectedButtonValues: [...state.allButtons, data].map(item => item.type)
    }))
  }
  const onChangeBtn = (item, idx) => {
    return <Select
      defaultValue={ item.type }
      value={ item.type }
      style={ {
        width: '100%',
      } }
      onChange={
        (e) => {
          let newData = [...state.allButtons]
          newData[idx].type = e
          setState({ ...state, allButtons: newData, selectedButtonValues: newData.map(item => item.type) })
        }
      }
      options={ [
        {
          value: 'visit-website',
          label: 'Visit website',
          disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('visit-website')
        },
        {
          value: 'call-phone-number',
          label: 'Call phone number',
          disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('call-phone-number')
        },
        {
          value: 'copy-offer-code',
          label: 'Copy offer code',
          disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('copy-offer-code')
        },
      ] }
    />
  }

  const onChangeBtnText = (item, idx) => {
    return <Input value={ item.text } onChange={ e => {
      let newData = [...state.allButtons]
      newData[idx].text = e.target.value
      setState({ ...state, allButtons: newData })
    } } placeholder={ 'Button Text' }
                  maxLength={ 20 } showCount
    />
  }
  const deleteFunc = (index) => {
    state.allButtons.splice(index, 1)
    setState(prevState => ({
      ...prevState,
      allButtons: [...state.allButtons],
      selectedButtonValues: [...state.allButtons].map(item => item.type)
    }))
  }

  const handleImageChange = async (event) => {
    const file = await event.target.files[0]

    if (file) {
      setState({ ...state, headerMedia: URL.createObjectURL(file), mediaFile: file })
    }
    event.target.value = null
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const deleteBodyVariable = (item, idx) => {
    const updatedVariables = [...state.bodyVariables];
    updatedVariables.splice(idx, 1);
    setState(prevState => ({
      ...prevState,
      bodyText: state.bodyText.replace(item.value, ""),
      bodyVariables: updatedVariables
    }));
  }

  const Languages = [
    {
      "code": "af",
      "language": "Afrikaans"
    },
    {
      "code": "sq",
      "language": "Albanian"
    },
    {
      "code": "ar",
      "language": "Arabic"
    },
    {
      "code": "az",
      "language": "Azerbaijani"
    },
    {
      "code": "bn",
      "language": "Bengali"
    },
    {
      "code": "bg",
      "language": "Bulgarian"
    },
    {
      "code": "ca",
      "language": "Catalan"
    },
    {
      "code": "zh_CN",
      "language": "Chinese (Simplified)"
    },
    {
      "code": "zh_HK",
      "language": "Chinese (Hong Kong)"
    },
    {
      "code": "zh_TW",
      "language": "Chinese (Traditional)"
    },
    {
      "code": "hr",
      "language": "Croatian"
    },
    {
      "code": "cs",
      "language": "Czech"
    },
    {
      "code": "da",
      "language": "Danish"
    },
    {
      "code": "nl",
      "language": "Dutch"
    },
    {
      "code": "en",
      "language": "English"
    },
    {
      "code": "en_GB",
      "language": "English (UK)"
    },
    {
      "code": "en_US",
      "language": "English (US)"
    },
    {
      "code": "et",
      "language": "Estonian"
    },
    {
      "code": "fil",
      "language": "Filipino"
    },
    {
      "code": "fi",
      "language": "Finnish"
    },
    {
      "code": "fr",
      "language": "French"
    },
    {
      "code": "fr_CA",
      "language": "French (Canada)"
    },
    {
      "code": "ka",
      "language": "Georgian"
    },
    {
      "code": "de",
      "language": "German"
    },
    {
      "code": "el",
      "language": "Greek"
    },
    {
      "code": "gu",
      "language": "Gujarati"
    },
    {
      "code": "ha",
      "language": "Hausa"
    },
    {
      "code": "he",
      "language": "Hebrew"
    },
    {
      "code": "hi",
      "language": "Hindi"
    },
    {
      "code": "hu",
      "language": "Hungarian"
    },
    {
      "code": "id",
      "language": "Indonesian"
    },
    {
      "code": "ga",
      "language": "Irish"
    },
    {
      "code": "it",
      "language": "Italian"
    },
    {
      "code": "ja",
      "language": "Japanese"
    },
    {
      "code": "kn",
      "language": "Kannada"
    },
    {
      "code": "kk",
      "language": "Kazakh"
    },
    {
      "code": "ko",
      "language": "Korean"
    },
    {
      "code": "ky",
      "language": "Kyrgyz"
    },
    {
      "code": "lo",
      "language": "Lao"
    },
    {
      "code": "lv",
      "language": "Latvian"
    },
    {
      "code": "lt",
      "language": "Lithuanian"
    },
    {
      "code": "mk",
      "language": "Macedonian"
    },
    {
      "code": "ms",
      "language": "Malay"
    },
    {
      "code": "ml",
      "language": "Malayalam"
    },
    {
      "code": "mr",
      "language": "Marathi"
    },
    {
      "code": "nb",
      "language": "Norwegian"
    },
    {
      "code": "fa",
      "language": "Persian"
    },
    {
      "code": "pl",
      "language": "Polish"
    },
    {
      "code": "pt_BR",
      "language": "Portuguese (Brazil)"
    },
    {
      "code": "pt_PT",
      "language": "Portuguese (Portugal)"
    },
    {
      "code": "pa",
      "language": "Punjabi"
    },
    {
      "code": "ro",
      "language": "Romanian"
    },
    {
      "code": "ru",
      "language": "Russian"
    },
    {
      "code": "sr",
      "language": "Serbian"
    },
    {
      "code": "sk",
      "language": "Slovak"
    },
    {
      "code": "sl",
      "language": "Slovenian"
    },
    {
      "code": "es",
      "language": "Spanish"
    },
    {
      "code": "es_AR",
      "language": "Spanish (Argentina)"
    },
    {
      "code": "es_ES",
      "language": "Spanish (Spain)"
    },
    {
      "code": "es_MX",
      "language": "Spanish (Mexico)"
    },
    {
      "code": "sw",
      "language": "Swahili"
    },
    {
      "code": "sv",
      "language": "Swedish"
    },
    {
      "code": "ta",
      "language": "Tamil"
    },
    {
      "code": "te",
      "language": "Telugu"
    },
    {
      "code": "th",
      "language": "Thai"
    },
    {
      "code": "tr",
      "language": "Turkish"
    },
    {
      "code": "uk",
      "language": "Ukrainian"
    },
    {
      "code": "ur",
      "language": "Urdu"
    },
    {
      "code": "uz",
      "language": "Uzbek"
    },
    {
      "code": "vi",
      "language": "Vietnamese"
    },
    {
      "code": "zu",
      "language": "Zulu"
    }
  ]

  const PhoneCountries = [
    {
      "name": "Afghanistan",
      "code": "AF",
      "mobile_code": "+93"
    },
    {
      "name": "Albania",
      "code": "AL",
      "mobile_code": "+355"
    },
    {
      "name": "Algeria",
      "code": "DZ",
      "mobile_code": "+213"
    },
    {
      "name": "Andorra",
      "code": "AD",
      "mobile_code": "+376"
    },
    {
      "name": "Angola",
      "code": "AO",
      "mobile_code": "+244"
    },
    {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "mobile_code": "+1"
    },
    {
      "name": "Argentina",
      "code": "AR",
      "mobile_code": "+54"
    },
    {
      "name": "Armenia",
      "code": "AM",
      "mobile_code": "+374"
    },
    {
      "name": "Australia",
      "code": "AU",
      "mobile_code": "+61"
    },
    {
      "name": "Austria",
      "code": "AT",
      "mobile_code": "+43"
    },
    {
      "name": "Azerbaijan",
      "code": "AZ",
      "mobile_code": "+994"
    },
    {
      "name": "Bahamas",
      "code": "BS",
      "mobile_code": "+1"
    },
    {
      "name": "Bahrain",
      "code": "BH",
      "mobile_code": "+973"
    },
    {
      "name": "Bangladesh",
      "code": "BD",
      "mobile_code": "+880"
    },
    {
      "name": "Barbados",
      "code": "BB",
      "mobile_code": "+1"
    },
    {
      "name": "Belarus",
      "code": "BY",
      "mobile_code": "+375"
    },
    {
      "name": "Belgium",
      "code": "BE",
      "mobile_code": "+32"
    },
    {
      "name": "Belize",
      "code": "BZ",
      "mobile_code": "+501"
    },
    {
      "name": "Benin",
      "code": "BJ",
      "mobile_code": "+229"
    },
    {
      "name": "Bhutan",
      "code": "BT",
      "mobile_code": "+975"
    },
    {
      "name": "Bolivia",
      "code": "BO",
      "mobile_code": "+591"
    },
    {
      "name": "Bosnia and Herzegovina",
      "code": "BA",
      "mobile_code": "+387"
    },
    {
      "name": "Botswana",
      "code": "BW",
      "mobile_code": "+267"
    },
    {
      "name": "Brazil",
      "code": "BR",
      "mobile_code": "+55"
    },
    {
      "name": "Brunei",
      "code": "BN",
      "mobile_code": "+673"
    },
    {
      "name": "Bulgaria",
      "code": "BG",
      "mobile_code": "+359"
    },
    {
      "name": "Burkina Faso",
      "code": "BF",
      "mobile_code": "+226"
    },
    {
      "name": "Burundi",
      "code": "BI",
      "mobile_code": "+257"
    },
    {
      "name": "Cabo Verde",
      "code": "CV",
      "mobile_code": "+238"
    },
    {
      "name": "Cambodia",
      "code": "KH",
      "mobile_code": "+855"
    },
    {
      "name": "Cameroon",
      "code": "CM",
      "mobile_code": "+237"
    },
    {
      "name": "Canada",
      "code": "CA",
      "mobile_code": "+1"
    },
    {
      "name": "Central African Republic",
      "code": "CF",
      "mobile_code": "+236"
    },
    {
      "name": "Chad",
      "code": "TD",
      "mobile_code": "+235"
    },
    {
      "name": "Chile",
      "code": "CL",
      "mobile_code": "+56"
    },
    {
      "name": "China",
      "code": "CN",
      "mobile_code": "+86"
    },
    {
      "name": "Colombia",
      "code": "CO",
      "mobile_code": "+57"
    },
    {
      "name": "Comoros",
      "code": "KM",
      "mobile_code": "+269"
    },
    {
      "name": "Congo (Congo-Brazzaville)",
      "code": "CG",
      "mobile_code": "+242"
    },
    {
      "name": "Costa Rica",
      "code": "CR",
      "mobile_code": "+506"
    },
    {
      "name": "Croatia",
      "code": "HR",
      "mobile_code": "+385"
    },
    {
      "name": "Cuba",
      "code": "CU",
      "mobile_code": "+53"
    },
    {
      "name": "Cyprus",
      "code": "CY",
      "mobile_code": "+357"
    },
    {
      "name": "Czechia (Czech Republic)",
      "code": "CZ",
      "mobile_code": "+420"
    },
    {
      "name": "Democratic Republic of the Congo",
      "code": "CD",
      "mobile_code": "+243"
    },
    {
      "name": "Denmark",
      "code": "DK",
      "mobile_code": "+45"
    },
    {
      "name": "Djibouti",
      "code": "DJ",
      "mobile_code": "+253"
    },
    {
      "name": "Dominica",
      "code": "DM",
      "mobile_code": "+1"
    },
    {
      "name": "Dominican Republic",
      "code": "DO",
      "mobile_code": "+1"
    },
    {
      "name": "Ecuador",
      "code": "EC",
      "mobile_code": "+593"
    },
    {
      "name": "Egypt",
      "code": "EG",
      "mobile_code": "+20"
    },
    {
      "name": "El Salvador",
      "code": "SV",
      "mobile_code": "+503"
    },
    {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "mobile_code": "+240"
    },
    {
      "name": "Eritrea",
      "code": "ER",
      "mobile_code": "+291"
    },
    {
      "name": "Estonia",
      "code": "EE",
      "mobile_code": "+372"
    },
    {
      "name": "Eswatini (fmr. Swaziland)",
      "code": "SZ",
      "mobile_code": "+268"
    },
    {
      "name": "Ethiopia",
      "code": "ET",
      "mobile_code": "+251"
    },
    {
      "name": "Fiji",
      "code": "FJ",
      "mobile_code": "+679"
    },
    {
      "name": "Finland",
      "code": "FI",
      "mobile_code": "+358"
    },
    {
      "name": "France",
      "code": "FR",
      "mobile_code": "+33"
    },
    {
      "name": "Gabon",
      "code": "GA",
      "mobile_code": "+241"
    },
    {
      "name": "Gambia",
      "code": "GM",
      "mobile_code": "+220"
    },
    {
      "name": "Georgia",
      "code": "GE",
      "mobile_code": "+995"
    },
    {
      "name": "Germany",
      "code": "DE",
      "mobile_code": "+49"
    },
    {
      "name": "Ghana",
      "code": "GH",
      "mobile_code": "+233"
    },
    {
      "name": "Greece",
      "code": "GR",
      "mobile_code": "+30"
    },
    {
      "name": "Grenada",
      "code": "GD",
      "mobile_code": "+1"
    },
    {
      "name": "Guatemala",
      "code": "GT",
      "mobile_code": "+502"
    },
    {
      "name": "Guinea",
      "code": "GN",
      "mobile_code": "+224"
    },
    {
      "name": "Guinea-Bissau",
      "code": "GW",
      "mobile_code": "+245"
    },
    {
      "name": "Guyana",
      "code": "GY",
      "mobile_code": "+592"
    },
    {
      "name": "Haiti",
      "code": "HT",
      "mobile_code": "+509"
    },
    {
      "name": "Holy See",
      "code": "VA",
      "mobile_code": "+39"
    },
    {
      "name": "Honduras",
      "code": "HN",
      "mobile_code": "+504"
    },
    {
      "name": "Hungary",
      "code": "HU",
      "mobile_code": "+36"
    },
    {
      "name": "Iceland",
      "code": "IS",
      "mobile_code": "+354"
    },
    {
      "name": "India",
      "code": "IN",
      "mobile_code": "+91"
    },
    {
      "name": "Indonesia",
      "code": "ID",
      "mobile_code": "+62"
    },
    {
      "name": "Iran",
      "code": "IR",
      "mobile_code": "+98"
    },
    {
      "name": "Iraq",
      "code": "IQ",
      "mobile_code": "+964"
    },
    {
      "name": "Ireland",
      "code": "IE",
      "mobile_code": "+353"
    },
    {
      "name": "Israel",
      "code": "IL",
      "mobile_code": "+972"
    },
    {
      "name": "Italy",
      "code": "IT",
      "mobile_code": "+39"
    },
    {
      "name": "Jamaica",
      "code": "JM",
      "mobile_code": "+1"
    },
    {
      "name": "Japan",
      "code": "JP",
      "mobile_code": "+81"
    },
    {
      "name": "Jordan",
      "code": "JO",
      "mobile_code": "+962"
    },
    {
      "name": "Kazakhstan",
      "code": "KZ",
      "mobile_code": "+7"
    },
    {
      "name": "Kenya",
      "code": "KE",
      "mobile_code": "+254"
    },
    {
      "name": "Kiribati",
      "code": "KI",
      "mobile_code": "+686"
    },
    {
      "name": "Kuwait",
      "code": "KW",
      "mobile_code": "+965"
    },
    {
      "name": "Kyrgyzstan",
      "code": "KG",
      "mobile_code": "+996"
    },
    {
      "name": "Laos",
      "code": "LA",
      "mobile_code": "+856"
    },
    {
      "name": "Latvia",
      "code": "LV",
      "mobile_code": "+371"
    },
    {
      "name": "Lebanon",
      "code": "LB",
      "mobile_code": "+961"
    },
    {
      "name": "Lesotho",
      "code": "LS",
      "mobile_code": "+266"
    },
    {
      "name": "Liberia",
      "code": "LR",
      "mobile_code": "+231"
    },
    {
      "name": "Libya",
      "code": "LY",
      "mobile_code": "+218"
    },
    {
      "name": "Liechtenstein",
      "code": "LI",
      "mobile_code": "+423"
    },
    {
      "name": "Lithuania",
      "code": "LT",
      "mobile_code": "+370"
    },
    {
      "name": "Luxembourg",
      "code": "LU",
      "mobile_code": "+352"
    },
    {
      "name": "Madagascar",
      "code": "MG",
      "mobile_code": "+261"
    },
    {
      "name": "Malawi",
      "code": "MW",
      "mobile_code": "+265"
    },
    {
      "name": "Malaysia",
      "code": "MY",
      "mobile_code": "+60"
    },
    {
      "name": "Maldives",
      "code": "MV",
      "mobile_code": "+960"
    },
    {
      "name": "Mali",
      "code": "ML",
      "mobile_code": "+223"
    },
    {
      "name": "Malta",
      "code": "MT",
      "mobile_code": "+356"
    },
    {
      "name": "Marshall Islands",
      "code": "MH",
      "mobile_code": "+692"
    },
    {
      "name": "Mauritania",
      "code": "MR",
      "mobile_code": "+222"
    },
    {
      "name": "Mauritius",
      "code": "MU",
      "mobile_code": "+230"
    },
    {
      "name": "Mexico",
      "code": "MX",
      "mobile_code": "+52"
    },
    {
      "name": "Micronesia",
      "code": "FM",
      "mobile_code": "+691"
    },
    {
      "name": "Moldova",
      "code": "MD",
      "mobile_code": "+373"
    },
    {
      "name": "Monaco",
      "code": "MC",
      "mobile_code": "+377"
    },
    {
      "name": "Mongolia",
      "code": "MN",
      "mobile_code": "+976"
    },
    {
      "name": "Montenegro",
      "code": "ME",
      "mobile_code": "+382"
    },
    {
      "name": "Morocco",
      "code": "MA",
      "mobile_code": "+212"
    },
    {
      "name": "Mozambique",
      "code": "MZ",
      "mobile_code": "+258"
    },
    {
      "name": "Myanmar",
      "code": "MM",
      "mobile_code": "+95"
    },
    {
      "name": "Namibia",
      "code": "NA",
      "mobile_code": "+264"
    },
    {
      "name": "Nauru",
      "code": "NR",
      "mobile_code": "+674"
    },
    {
      "name": "Nepal",
      "code": "NP",
      "mobile_code": "+977"
    },
    {
      "name": "Netherlands",
      "code": "NL",
      "mobile_code": "+31"
    },
    {
      "name": "New Zealand",
      "code": "NZ",
      "mobile_code": "+64"
    },
    {
      "name": "Nicaragua",
      "code": "NI",
      "mobile_code": "+505"
    },
    {
      "name": "Niger",
      "code": "NE",
      "mobile_code": "+227"
    },
    {
      "name": "Nigeria",
      "code": "NG",
      "mobile_code": "+234"
    },
    {
      "name": "North Korea",
      "code": "KP",
      "mobile_code": "+850"
    },
    {
      "name": "North Macedonia",
      "code": "MK",
      "mobile_code": "+389"
    },
    {
      "name": "Norway",
      "code": "NO",
      "mobile_code": "+47"
    },
    {
      "name": "Oman",
      "code": "OM",
      "mobile_code": "+968"
    },
    {
      "name": "Pakistan",
      "code": "PK",
      "mobile_code": "+92"
    },
    {
      "name": "Palau",
      "code": "PW",
      "mobile_code": "+680"
    },
    {
      "name": "Palestine State",
      "code": "PS",
      "mobile_code": "+970"
    },
    {
      "name": "Panama",
      "code": "PA",
      "mobile_code": "+507"
    },
    {
      "name": "Papua New Guinea",
      "code": "PG",
      "mobile_code": "+675"
    },
    {
      "name": "Paraguay",
      "code": "PY",
      "mobile_code": "+595"
    },
    {
      "name": "Peru",
      "code": "PE",
      "mobile_code": "+51"
    },
    {
      "name": "Philippines",
      "code": "PH",
      "mobile_code": "+63"
    },
    {
      "name": "Poland",
      "code": "PL",
      "mobile_code": "+48"
    },
    {
      "name": "Portugal",
      "code": "PT",
      "mobile_code": "+351"
    },
    {
      "name": "Qatar",
      "code": "QA",
      "mobile_code": "+974"
    },
    {
      "name": "Romania",
      "code": "RO",
      "mobile_code": "+40"
    },
    {
      "name": "Russia",
      "code": "RU",
      "mobile_code": "+7"
    },
    {
      "name": "Rwanda",
      "code": "RW",
      "mobile_code": "+250"
    },
    {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "mobile_code": "+1"
    },
    {
      "name": "Saint Lucia",
      "code": "LC",
      "mobile_code": "+1"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC",
      "mobile_code": "+1"
    },
    {
      "name": "Samoa",
      "code": "WS",
      "mobile_code": "+685"
    },
    {
      "name": "San Marino",
      "code": "SM",
      "mobile_code": "+378"
    },
    {
      "name": "Sao Tome and Principe",
      "code": "ST",
      "mobile_code": "+239"
    },
    {
      "name": "Saudi Arabia",
      "code": "SA",
      "mobile_code": "+966"
    },
    {
      "name": "Senegal",
      "code": "SN",
      "mobile_code": "+221"
    },
    {
      "name": "Serbia",
      "code": "RS",
      "mobile_code": "+381"
    },
    {
      "name": "Seychelles",
      "code": "SC",
      "mobile_code": "+248"
    },
    {
      "name": "Sierra Leone",
      "code": "SL",
      "mobile_code": "+232"
    },
    {
      "name": "Singapore",
      "code": "SG",
      "mobile_code": "+65"
    },
    {
      "name": "Slovakia",
      "code": "SK",
      "mobile_code": "+421"
    },
    {
      "name": "Slovenia",
      "code": "SI",
      "mobile_code": "+386"
    },
    {
      "name": "Solomon Islands",
      "code": "SB",
      "mobile_code": "+677"
    },
    {
      "name": "Somalia",
      "code": "SO",
      "mobile_code": "+252"
    },
    {
      "name": "South Africa",
      "code": "ZA",
      "mobile_code": "+27"
    },
    {
      "name": "South Korea",
      "code": "KR",
      "mobile_code": "+82"
    },
    {
      "name": "South Sudan",
      "code": "SS",
      "mobile_code": "+211"
    },
    {
      "name": "Spain",
      "code": "ES",
      "mobile_code": "+34"
    },
    {
      "name": "Sri Lanka",
      "code": "LK",
      "mobile_code": "+94"
    },
    {
      "name": "Sudan",
      "code": "SD",
      "mobile_code": "+249"
    },
    {
      "name": "Suriname",
      "code": "SR",
      "mobile_code": "+597"
    },
    {
      "name": "Sweden",
      "code": "SE",
      "mobile_code": "+46"
    },
    {
      "name": "Switzerland",
      "code": "CH",
      "mobile_code": "+41"
    },
    {
      "name": "Syria",
      "code": "SY",
      "mobile_code": "+963"
    },
    {
      "name": "Tajikistan",
      "code": "TJ",
      "mobile_code": "+992"
    },
    {
      "name": "Tanzania",
      "code": "TZ",
      "mobile_code": "+255"
    },
    {
      "name": "Thailand",
      "code": "TH",
      "mobile_code": "+66"
    },
    {
      "name": "Timor-Leste",
      "code": "TL",
      "mobile_code": "+670"
    },
    {
      "name": "Togo",
      "code": "TG",
      "mobile_code": "+228"
    },
    {
      "name": "Tonga",
      "code": "TO",
      "mobile_code": "+676"
    },
    {
      "name": "Trinidad and Tobago",
      "code": "TT",
      "mobile_code": "+1"
    },
    {
      "name": "Tunisia",
      "code": "TN",
      "mobile_code": "+216"
    },
    {
      "name": "Turkey",
      "code": "TR",
      "mobile_code": "+90"
    },
    {
      "name": "Turkmenistan",
      "code": "TM",
      "mobile_code": "+993"
    },
    {
      "name": "Tuvalu",
      "code": "TV",
      "mobile_code": "+688"
    },
    {
      "name": "Uganda",
      "code": "UG",
      "mobile_code": "+256"
    },
    {
      "name": "Ukraine",
      "code": "UA",
      "mobile_code": "+380"
    },
    {
      "name": "United Arab Emirates",
      "code": "AE",
      "mobile_code": "+971"
    },
    {
      "name": "United Kingdom",
      "code": "GB",
      "mobile_code": "+44"
    },
    {
      "name": "United States of America",
      "code": "US",
      "mobile_code": "+1"
    },
    {
      "name": "Uruguay",
      "code": "UY",
      "mobile_code": "+598"
    },
    {
      "name": "Uzbekistan",
      "code": "UZ",
      "mobile_code": "+998"
    },
    {
      "name": "Vanuatu",
      "code": "VU",
      "mobile_code": "+678"
    },
    {
      "name": "Venezuela",
      "code": "VE",
      "mobile_code": "+58"
    },
    {
      "name": "Vietnam",
      "code": "VN",
      "mobile_code": "+84"
    },
    {
      "name": "Yemen",
      "code": "YE",
      "mobile_code": "+967"
    },
    {
      "name": "Zambia",
      "code": "ZM",
      "mobile_code": "+260"
    },
    {
      "name": "Zimbabwe",
      "code": "ZW",
      "mobile_code": "+263"
    }
  ]
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const onEmojiClick = (event) => {

    setState({
      ...state, bodyText: state.bodyText + event.emoji
    })
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const ids = open ? 'simple-popover' : undefined;

  const finishBtn = () => {
    const formData = new FormData();
    !!state.mediaFile && formData.append(`media`, state.mediaFile);

    let finalData = {
      name: state.teamplateName,
      category: state.category,
      language: state.language,
      header: {
        headerType: state.headerType,
        content: state.headerText,
        url: "",
        sampleData: state.headerSample
      },
      body: {
        text: state.bodyText,
        sampleData: state.bodyVariables.map(item => item.sampleData)
      },
      footer: state.footerText,
      buttons: {
        callToAction: state.buttonType !== "quick-reply" ? state.allButtons : [],
        quickReply: state.buttonType === "quick-reply" ? state.allButtons : []
      }
    }

    formData.append('data', JSON.stringify(finalData))

    let API = !!id ? axios.put(config.api.baseUrl + `/template/update/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
      }
    }) : axios.post(config.api.baseUrl + '/template/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
      }
    })
    API
      .then(() => {
        setState({ ...state })
      })
      .catch((err) => {
        console.log(err, 'Err')
      });
  }

  const authFinish = async () => {
    let data = {
      name: state.teamplateName,
      code_delivery: state.authCategory,
      language: state.language,
      securityRecommendation: state.addSecrityRecommendation,
      expireIn: parseInt(state.expiresIn, 10),
      buttons: [
        {
          type: "one_tap",
          text: state.authCopyCode,
          package_name: state.authCategory==="autofill" ? state.packageName : '',
          signature_hash: state.authCategory==="autofill" ? state.appSignatureHash : ""
        }
      ]
    }

    let API = !!id ? updateAuthTemplate(id, data) : createAuthTemplate(data)
    await API.then(res => console.log(res, 'Response')).catch(err => console.log(err))
  }

  const getTemplateFunc = () => {
    getTemplateById(id).then(res => {
      let response = res.data.data
      console.log(response, 33771111)
      let regex = /{{\d+}}/g;
      let matches = !!response?.body && response.body.text.match(regex);
      console.log(87654311)
      setState({
        ...state,
        teamplateName: response.name,
        language: response.language,
        category: response.category,
        headerType: !!response?.header && response?.header?.headerType === "" ? "" : response?.header?.headerType === "text" ? "text" : "media",
        mediaType: !!response?.header && response.header.headerType,
        websiteSampledata:!!response?.header && response.header?.websiteSampledata,
        buttonType: response?.buttons?.callToAction && response?.buttons?.callToAction?.length > 0 ? 'call-to-action' : 'quick-reply',
        selectedButton: 'none',
        allButtons: response?.buttons?.callToAction && response?.buttons?.callToAction.length > 0 ? response?.buttons?.callToAction : response?.buttons?.quickReply,
        headerText: !!response?.header && response.header.content,
        headerSample: !!response?.header && response.header.sampleData,
        bodyText: response?.body && response.body.text,
        footerText: response?.footer,
        headerMedia: response?.mediaURL,
        selectedButtonValues: response?.buttons?.callToAction && response.buttons?.callToAction.length > 0 ?
          response?.buttons?.callToAction.map(item => item.type) :
          response?.buttons?.quickReply?.length && response?.buttons?.quickReply.map(item => item.type),
        bodyVariables: !!matches && !!response.body.sampleData.length ? response.body.sampleData.map((item, idx) => ({
            sampleData: item,
            value: matches[idx]
          }))
          : [],
        authCategory: response.code_delivery,
        expiresIn: response.expireIn,
        packageName: response?.buttons?.length && response.buttons[0].package_name,
        appSignatureHash: response.buttons.length && response.buttons[0].signature_hash,
        authCopyCode: response.buttons.length && response.buttons[0].text,
        addSecrityRecommendation: response.securityRecommendation === "true",
        addExpireTime: !!response.expireIn,
        authTextToShow: `<b>12345</b> is your verification code. `
      })
    }).catch(err => console.log(err))
  }

  const ckEditorValueChange = (data, matches) => {
    //  setState({
    //   ...state,
    //   bodyText: data,
    //   bodyVariables: !!matches ? matches.map((item, idx) => ({
    //       sampleData: state.bodyVariables[idx]?.sampleData ? state.bodyVariables[idx]?.sampleData : "",
    //       value: matches[idx]
    //     }))
    //     : []
    // })
  }

  console.log(state, 7744411121)


  useEffect(() => {
    !!id && getTemplateFunc()
  }, []);

  return <div className='synctic-module-container' style={ {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10
  } }>
    <div style={ { width: "80%", overflowY: 'auto' } } className={ 'cards-box' }>
      <div style={ { width: "70%", margin: "auto" } }>
        <h2>Create Template</h2>
        <h5>Name</h5>
        <Input.TextArea value={ state.teamplateName }
                        onChange={ (e) => setState({ ...state, teamplateName: e.target.value }) } max={ 512 }
                        showCount placeholder="Enter here"/>
        <br/>
        <br/>
        <h5>Language</h5>
        <Select style={ { width: "100%" } } defaultValue={ state.language } value={ state.language }
                onSelect={ e => setState({ ...state, language: e }) } placeholder="Select language">
          {
            Languages.map(item => <Option value={ item.code }>{ item.language }</Option>)
          }
        </Select>
        <br/>
        <br/>
        <h5>Category</h5>
        <Radio.Group value={ state.category }
                     defaultValue={ state.category }
                     onChange={ e => {
                       setState({ ...state, category: e.target.value })
                     } }>
          <Space direction="horizontal">
            <Radio value={ 'utility' }>
              <div className={ "category-templates" }>
                <BellFilled style={ { fontSize: 32, opacity: .8 } }/>
                <div>
                  <h3 style={ { margin: 0 } }>
                    Utility
                  </h3>
                  <div className={ 'para' }>
                    Send messages about an existing order an account.
                  </div>
                </div>
              </div>
            </Radio>
            <Radio value={ "marketing" } style={ { marginTop: 12 } }>
              <div className={ "category-templates" }>
                <NotificationFilled style={ { fontSize: 32, opacity: .8 } }/>
                <div>
                  <h3 style={ { margin: 0 } }>
                    Marketing
                  </h3>
                  <div className={ 'para' }>
                    Send promotions or informations about your products, services or business
                  </div>
                </div>
              </div>
            </Radio>
            <Radio value={ 'authentication' } style={ { marginTop: 12 } }>
              <div className={ "category-templates" }>
                <LockFilled style={ { fontSize: 32, opacity: .8 } }/>
                <div>
                  <h3 style={ { margin: 0 } }>
                    Authentication
                  </h3>
                  <div className={ 'para' }>
                    Send codes to verify a transaction or login.
                  </div>
                </div>
              </div>
            </Radio>
          </Space>
        </Radio.Group>
        {
          state.category !== "authentication" ?
            <div>
              <h5>Header <span style={ { color: 'grey', fontStyle: 'italic', fontWeight: 200 } }>Optional</span></h5>
              <div style={ { marginTop: 14 } }/>
              <Select
                defaultValue={ state.headerType }
                value={ state.headerType }
                style={ {
                  width: '100%',
                } }
                onChange={
                  (e) => setState({
                    ...state,
                    headerType: e,
                    headerText: ''
                  })
                }
                options={ [
                  {
                    value: 'none',
                    label: 'None',
                  },
                  {
                    value: 'text',
                    label: 'Text',
                  },
                  {
                    value: 'media',
                    label: 'Media',
                  },
                ] }
              />
              <br/>
              <br/>
              {
                state.headerType === 'text' ?
                  <div>
                    <Input
                      value={ state.headerText }
                      placeholder={ 'Enter here' }
                      maxLength={ 60 }
                      showCount
                      onChange={ (e) => setState({ ...state, headerText: e.target.value }) }
                    />
                    { !state.headerText.includes('{{1}}') &&
                      <Button onClick={ () => setState({
                          ...state,
                          headerText: state.headerText.concat('{{1}}')
                        }
                      ) }
                              type={ 'primary' }
                              style={ {
                                float: 'right',
                                marginTop: 10
                              } }>Add variable</Button>
                    }
                    {
                      state.headerText.includes('{{1}}') && <div style={ {
                        padding: 10,
                        borderRadius: 4,
                        background: "rgba(0,0,0,.05)",
                        marginTop: 10
                      } }>
                        <p>Sample Data</p>
                        <div style={ {
                          display: 'flex',
                          justifyContent: "space-between",
                          gap: 10,
                          alignItems: 'center'
                        } }>
                          <Input
                            placeholder={ 'Add sample data' }
                            maxLength={ 60 }
                            showCount
                            value={ state.headerSample }
                            onChange={ (e) => setState({ ...state, headerSample: e.target.value }) }
                          />
                          <div onClick={ () => setState({
                            ...state,
                            headerSample: "",
                            headerText: state.headerText.replace('{{1}}', '')
                          }) }
                          >
                            <DeleteFilled style={ { fontSize: 16, cursor: 'pointer' } }/></div>
                        </div>
                      </div>
                    }
                  </div> :
                  state.headerType === 'media' &&
                  <Radio.Group onChange={ (e) => setState({
                    ...state,
                    mediaType: e.target.value,
                    headerMedia: null
                  }) } value={ state.mediaType }>
                    <Space direction="horizontal">
                      <Radio value={ 'image' }><FileImageFilled className={ 'icons-radio-edit-templates' }/></Radio>
                      <Radio value={ 'video' }><VideoCameraFilled className={ 'icons-radio-edit-templates' }/></Radio>
                      <Radio value={ 'document' }><FileTextFilled className={ 'icons-radio-edit-templates' }/></Radio>
                    </Space>
                  </Radio.Group>
              }

              <br/>
              {
                state.headerType === "media" &&
                !!state.mediaType &&
                <Button style={ { marginTop: 10 } } type={ 'primary' } onClick={ handleButtonClick }>click me</Button>
              }
              <input
                type="file"
                ref={ fileInputRef }
                style={ { display: 'none' } }
                onChange={ handleImageChange }
                accept={ state.mediaType === 'image' ? "image/*" : state.mediaType === 'video' ? "video/*" : ".pdf,.doc,.txt,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" }
              />
              <div style={ { marginTop: 30 } }/>
              <div style={ { display: "flex", justifyContent: 'space-between', alignItems: "center" } }>
                <h5>Body</h5>
                <Tooltip
                  placement="top"
                  title={ "Add emoji" }>
                  <EmojiEmotionsIcon
                    aria-describedby={ ids }
                    variant="contained"
                    onClick={ handleClick }
                    style={ {
                      fontSize: 18,
                      opacity: .8,
                      cursor: 'pointer'
                    } }/>
                </Tooltip>
                <Popover
                  id={ ids }
                  open={ open }
                  anchorEl={ anchorEl }
                  onClose={ handleClose }
                  anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'left',
                  } }
                >
                  <EmojiPicker onEmojiClick={ onEmojiClick }/>
                </Popover>
              </div>
              <div style={ { marginTop: 14 } }/>

              <CKEditor
                editor={ ClassicEditor }
                data={ state.bodyText }
                onChange={ (event, editor) => {
                  console.log(event, 8761111)
                  const data = editor.getData();
                  let regex = /{{\d+}}/g;
                  let matches = data.match(regex);
                  ckEditorValueChange(data, matches)
                } }
                config={ {
                  toolbar: ['bold', 'italic', '|', 'undo', 'redo'
                  ]
                } }
              />

              {
                state.bodyVariables.length > 0 && state.bodyVariables.map((item, idx) => <div style={ {
                  padding: 10,
                  borderRadius: 4,
                  background: "rgba(0,0,0,.05)",
                  marginTop: 10
                } }>
                  <p><span>{ item.value }</span> Sample Data</p>
                  <div style={ {
                    display: 'flex',
                    justifyContent: "space-between",
                    gap: 10,
                    alignItems: 'center'
                  } }>
                    <Input
                      placeholder={ 'Add sample data' }
                      maxLength={ 60 }
                      showCount
                      value={ state.bodyVariables[idx].sampleData }
                      onChange={ e => {
                        let newData = [...state.bodyVariables]
                        newData[idx].sampleData = e.target.value
                      } }
                    />
                    <DeleteFilled onClick={ () => {
                      deleteBodyVariable(item, idx)
                    } }
                                  style={ {
                                    fontSize: 16,
                                    cursor: 'pointer'
                                  } }/>
                  </div>
                </div>)
              }
              <Button

                onClick={ () => {
                  let newVariable = `{{${ state.bodyVariables.length + 1 }}}`;

                  // Check if the new variable already exists in bodyText
                  while (state.bodyText.includes(newVariable)) {
                    // If it exists, increment the variable and check again
                    newVariable = `{{${ parseInt(newVariable.match(/\d+/)[0]) + 1 }}}`;
                  }

                  setState({
                    ...state,
                    bodyText: state.bodyText.concat(newVariable),
                    bodyVariables: [...state.bodyVariables, newVariable]
                  });
                } }
                type={ 'primary' }
                style={ {
                  float: 'right',
                  marginTop: 10
                } }
              >
                Add variable
              </Button>

              <div style={ { marginTop: 30 } }/>
              <h5>Footer<span style={ { color: 'grey', fontStyle: 'italic', fontWeight: 200 } }>Optional</span></h5>
              <div style={ { marginTop: 14 } }/>
              <Input placeholder={ 'Add here' } maxLength={ 60 } showCount
                     value={ state.footerText }
                     onChange={ (e) => setState({ ...state, footerText: e.target.value }) }/>
              <div style={ { marginTop: 30 } }/>
              <h5>Buttons</h5>
              <div style={ { marginTop: 14 } }/>
              <Select
                defaultValue={ state.buttonType }
                style={ {
                  width: '100%',
                } }
                onChange={
                  (e) => {
                    setState(prevState => ({
                      ...prevState,
                      buttonType: e,
                      allButtons: e === 'quick-reply' ? [
                        {
                          type: "custom",
                          btnText: ""
                        }
                      ] : state.allButtons,
                      selectedButton: e === 'quick-reply' ? e : state.selectedButton,
                      selectedButtonValues: e === 'quick-reply' ? [{
                        type: "custom",
                        btnText: ""
                      }].map(item => item.type) : state.selectedButtonValues
                    }))
                  }
                }
                options={ [
                  {
                    value: 'none',
                    label: 'None',
                  },
                  {
                    value: 'call-to-action',
                    label: 'Call To Action',
                  },
                  {
                    value: 'quick-reply',
                    label: 'Quick Reply',
                  },
                ] }
              />
              <br/>
              <br/>

              {
                !!state?.allButtons && state.allButtons.map((item, idx) => item.type === 'url' ?
                  <div style={ {
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: 'center',
                    gap: 20,
                    marginTop: 20
                  } }>
                    <div style={ { width: '100%' } }>
                      <h5 style={ { marginBottom: 10 } }>Visit Website</h5>
                      <div className={ 'btn-templates-container' }>

                        {
                          onChangeBtn(item, idx)
                        }
                        {
                          onChangeBtnText(item, idx)
                        }
                        <Select defaultValue={ item.urlType } value={ item.urlType } placeholder="Select"
                                onSelect={ e => {
                                  let newData = [...state.allButtons]
                                  newData[idx].urlType = e
                                  setState({ ...state, allButtons: newData })
                                } }>
                          <Option value={ 'static' }>Static</Option>
                          <Option value={ 'dynamic' }>Dynamic</Option>
                        </Select>
                      </div>
                      <Input value={ item.url } onChange={ e => {
                        let newData = [...state.allButtons]
                        newData[idx].url = e.target.value
                        setState({ ...state, allButtons: newData })
                      } } placeholder={ 'Website' } maxLength={ 2000 } showCount/>

                      { item.urlType === 'dynamic' && <div style={ {
                        padding: 10,
                        borderRadius: 4,
                        background: "rgba(0,0,0,.05)",
                        marginTop: 10
                      } }>
                        <h5>Add Sample URL</h5>
                        <p>To help us review your message template, please add on exdample of the website URL. Don't use
                          real customer information.</p>
                        <div style={ {
                          display: 'flex',
                          justifyContent: "space-between",
                          gap: 10,
                          alignItems: 'center'
                        } }>
                          <Input
                            placeholder={ 'Add full url' }
                            maxLength={ 2000 }
                            showCount
                            value={ state.websiteSampledata }
                            onChange={ e => {
                              setState({ ...state, websiteSampledata: e.target.value })
                            } }
                          />
                        </div>
                      </div>
                      }
                    </div>
                    <div onClick={ () => deleteFunc(idx) }><DeleteFilled style={ { fontSize: 16 } }/></div>
                  </div>
                  : item.type === 'phone_number' ?
                    <div style={ {
                      display: 'flex',
                      justifyContent: "space-between",
                      alignItems: 'center',
                      gap: 20,
                      marginTop: 20
                    } }>
                      <div style={ { width: '100%' } }>
                        <h5 style={ { marginBottom: 10 } }>Call phone number</h5>
                        <div className={ 'btn-templates-container' }>

                          {
                            onChangeBtn(item, idx)
                          }
                          {
                            onChangeBtnText(item, idx)
                          }
                          <Select placeholder="Select" onSelect={ e => {
                            let newData = [...state.allButtons]
                            newData[idx].phoneNumber = e
                            setState({ ...state, allButtons: newData })
                          } }>
                            {
                              PhoneCountries.map((item, idx) => <Option
                                key={ idx }
                                value={ item.mobile_code }
                              >{ item.mobile_code + " " + item.name }</Option>)
                            }
                          </Select>
                        </div>

                        <Input value={ item.phoneNumber } onChange={ e => {
                          let newData = [...state.allButtons]
                          newData[idx].phoneNumber = e.target.value
                          setState({ ...state, allButtons: newData })
                        } } placeholder={ 'Phone' } maxLength={ 12 } showCount/>
                      </div>
                      <div onClick={ () => deleteFunc(idx) }><DeleteFilled style={ { fontSize: 16 } }/></div>
                    </div>
                    : item.type === 'offer_code' && state.category !== 'utility' ?
                      <div style={ {
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        gap: 20,
                        marginTop: 20
                      } }>
                        <div style={ { width: '100%' } }>
                          <h5 style={ { marginBottom: 10 } }>Copy Offer Code</h5>
                          <div className={ 'btn-templates-container' }>

                            {
                              onChangeBtn(item, idx)
                            }
                            {
                              onChangeBtnText(item, idx)
                            }
                            <div onClick={ () => deleteFunc(idx) }><DeleteFilled
                              style={ { fontSize: 16, float: 'right' } }/>
                            </div>
                          </div>

                          <div style={ { padding: 30, background: "rgba(0,0,0,.1)", borderRadius: 10 } }>
                            <div style={ { fontWeight: 550, color: "grey" } }>Add sample offer code</div>
                            <div style={ { fontWeight: 550, color: "grey", marginTop: 4 } }>To help us review your
                              message
                              template, please add an example for your offer code
                            </div>
                            <br/>
                            <Input value={ item.offerCode } onChange={ e => {
                              let newData = [...state.allButtons]
                              newData[idx].offerCode = e.target.value
                              setState({ ...state, allButtons: newData })
                            } } placeholder={ 'Sample' } maxLength={ 15 } showCount/>
                            <br/>
                            <br/>
                            <Checkbox>You direct meta to use link tracking to report website clicks. When this box is
                              unticked,
                              you will not see insights for website clicks.</Checkbox>
                          </div>
                        </div>
                      </div> : item.type === 'custom' ?
                        <div>
                          <div style={ {
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'center',
                            gap: 20,
                            marginTop: 20
                          } }>
                            <div style={ { width: '100%' } }>
                              <h5 style={ { marginBottom: 10 } }>Quick Reply</h5>
                              <div className={ 'btn-templates-container' }>
                                <Select
                                  defaultValue={ 'custom' }
                                  disabled={ true }
                                  value={ 'custom' }
                                  style={ {
                                    width: '100%',
                                  } }
                                  onChange={
                                    (e) => {
                                      let newData = [...state.allButtons]
                                      newData[idx].type = e
                                      setState({
                                        ...state,
                                        allButtons: newData,
                                        selectedButtonValues: newData.map(item => item.type)
                                      })
                                    }
                                  }
                                  options={ [
                                    {
                                      value: 'Custom',
                                      label: 'custom',
                                      disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('custom')
                                    },
                                    {
                                      value: 'option',
                                      label: 'Option',
                                      disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('option')
                                    }
                                  ] }
                                />
                                {
                                  onChangeBtnText(item, idx)
                                }
                                <div onClick={ () => deleteFunc(idx) }><DeleteFilled
                                  style={ { fontSize: 16, float: 'right' } }/></div>
                              </div>
                            </div>
                          </div>
                        </div> : ''
                )
              }

              <br/>
              {
                state.buttonType === 'quick-reply' &&
                <Button onClick={ () => setState({
                  ...state,
                  allButtons: [...state.allButtons, {
                    action: "custom",
                    text: ""
                  }]
                }) } type={ 'primary' } style={ { marginTop: 6, float: "right" } }> Add Quick Reply Button </Button>
              }
              {
                state.buttonType === 'call-to-action' &&
                <Select
                  // defaultValue={ state.selectedButton }
                  value={ state.selectedButton }
                  placeholder={ 'Select' }
                  style={ {
                    width: '100%',
                  } }
                  onChange={ addBtnFunc }
                  options={ state.buttonType === 'call-to-action' && [
                    {
                      value: 'visit-website',
                      label: 'Visit website',
                      disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('url')
                    },
                    {
                      value: 'call-phone-number',
                      label: 'Call phone number',
                      disabled: state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes("phone_number")
                    },
                    {
                      value: 'copy-offer-code',
                      label: 'Copy offer code',
                      disabled: state.category === 'utility' || (state.selectedButtonValues.length > 0 && state.selectedButtonValues.includes('offer_code'))
                    },
                  ] }
                /> }
              <br/>
              <br/>
              <Button onClick={ finishBtn } type={ 'primary' }
                      style={ { marginTop: 6, float: "right" } }>{!id ? 'Submit' : 'Update'}</Button>


            </div>
            :
            <div className={ "authentication" }>
              <h5>Code Delivery</h5>
              <p>Choose how customers send the code from WhatsaApp to your app</p>
              <Radio.Group value={ state.authCategory } defaultValue={ state.authCategory } onChange={ e => setState({
                ...state,
                authCategory: e.target.value
              }) }
              >
                <Space direction="vertical">
                  <Radio value={ 'autofill' }>
                    <div className={ "category-templates" }>
                      <div>
                        <h3 style={ { margin: 0 } }>
                          Auto-Fill
                        </h3>
                        <div className={ 'para' }>
                          Recommended as the easiest option for your customers. The code is sent to your app when a
                          customer taps the button. A copy code message will be sent when auto-fill isn't possible.
                        </div>
                      </div>
                    </div>
                  </Radio>


                  {
                    state.authCategory === 'autofill' &&
                    <div style={ {
                      padding: 10,
                      borderRadius: 4,
                      background: "rgba(0,0,0,.05)",
                      marginTop: 10
                    } }>
                      <h5>Package Name</h5>
                      <Input
                        placeholder={ 'Enter text (com.example.myapplication)' }
                        value={ state.packageName }
                        onChange={ (e) => setState({ ...state, packageName: e.target.value }) }
                      />
                      <br/>
                      <div style={ { marginTop: 20 } }/>
                      <h5>App Signature Hash</h5>
                      <Input
                        placeholder={ 'Enter text' }
                        value={ state.appSignatureHash }
                        onChange={ (e) => setState({ ...state, appSignatureHash: e.target.value }) }
                      />
                    </div>
                  }


                  <Radio value={ "copy_code" } style={ { marginTop: 12 } }>
                    <div className={ "category-templates" }>
                      <div>
                        <h3 style={ { margin: 0 } }>
                          Copy Code
                        </h3>
                        <div className={ 'para' }>
                          Basic authentication with quick setup. Your customers copy and paste the code into your app.
                        </div>
                      </div>
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
              <br/>
              <br/>

              <div>
                <h5>Message Send</h5>
                <p>Message for authentication message templates can't be edited. You can add additional content from
                  the options below.</p>
                <Checkbox
                  value={ state.addSecrityRecommendation }
                  defaultChecked={ state.addSecrityRecommendation }
                  onChange={ () => setState({ ...state, addSecrityRecommendation: !state.addSecrityRecommendation }) }>Add
                  security recommendation.</Checkbox>
                <br/>
                <Checkbox
                  value={ state.addExpireTime }
                  defaultChecked={ state.addExpireTime }
                  onChange={ () => setState({ ...state, addExpireTime: !state.addExpireTime }) }>Add expiry time
                  for the code.</Checkbox>
                <br/><br/>
                <div style={ {
                  padding: 10,
                  borderRadius: 4,
                  background: "rgba(0,0,0,.05)",
                  marginTop: 10
                } }>
                  <h5>Expires in</h5>
                  <div style={ {
                    display: 'flex',
                    justifyContent: "space-between",
                    gap: 10,
                    alignItems: 'center'
                  } }>
                    <Input
                      placeholder={ 'Minutes' }
                      value={ state.expiresIn }
                      onChange={ (e) => setState({ ...state, expiresIn: e.target.value }) }
                    />
                    <div> Minutes</div>
                  </div>
                </div>
              </div>
              <br/><br/>
              <div>
                <h5>Button Text</h5>
                <p>
                  You can customize the button text for both auto-fill and copy code. Even when zero-tap is turned on,
                  buttons are still needed for the backup code delivery method.
                </p>
                <h5>Copy Code</h5>
                <Input
                  placeholder={ 'Enter here' }
                  maxLength={ 25 }
                  showCount
                  value={ state.authCopyCode }
                  onChange={ (e) => setState({ ...state, authCopyCode: e.target.value }) }
                />
              </div>
              <Button onClick={ authFinish } type={ 'primary' }
                      style={ { marginTop: 6, float: "right" } }>{!id ? 'Submit' : 'Update'}</Button>
            </div>
        }
      </div>
    </div>
    <div className={ 'cards-box' }>
      <h3>Preview</h3>
      <DeviceFrameset
        device="iPhone X"
        color="black"
        zoom={ '80%' }
      >
        <div style={ {
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        } }>
          <div className={ 'deviceMessage' } style={ {
            backgroundImage: `url(${ InstagramBackgroundImage })`,
            padding: 10,
            overflowWrap: 'anywhere'
          } }>
            <br/><br/><br/>
            <div style={ { backgroundColor: "white", padding: 14, borderRadius: 16, width: 300 } }>
              {
                state.category !== 'authentication' ?
                  <div>
                    {
                      state.headerType === 'text' && <h3 style={ { width: 300 } }>{ state.headerText }</h3>
                    }
                    {
                      state.headerType === 'media' && state.mediaType === 'image' && <img
                        src={ state.headerMedia }
                        alt={ 'Image' }
                        style={ { width: 300, height: "300px", objectFit: "cover" } }
                      />
                    }

                    {
                      state.headerType === 'media' && state.mediaType === 'video' && <video style={ {
                        maxWidth: "100%", maxHeight: 300, objectFit: 'contain'
                      } } controls>
                        <source src={ state.headerMedia }
                                type="video/mp4"/>
                        Your browser does not support the video element.
                      </video>
                    }

                    {
                      state.headerType === 'media' && state.mediaType === 'document' && !!state.headerMedia &&
                      <FileTextFilled style={ { fontSize: 32 } }/>
                    }

                    <br/>
                    {
                      state.bodyText.length > 0 &&
                      <h4 style={ { width: 300 } } dangerouslySetInnerHTML={ { __html: state.bodyText } }></h4>
                    }

                    <br/>
                    {
                      state.footerText.length > 0 && <h4 style={ { width: 300 } }>{ state.footerText }</h4>
                    }
                    <br/>
                    {
                      state?.allButtons?.length > 0 && state.allButtons.map(item => <Button type={ 'primary' } style={ {
                          marginTop: 6,
                          width: 300
                        } }>{ item.text }</Button>
                      )
                    }
                  </div> :
                  <div>
                    {
                      <p
                        dangerouslySetInnerHTML={ { __html: state.addSecrityRecommendation ? state.authTextToShow + 'For your security, do not share this code.' : state.authTextToShow } }></p>
                    }
                    {
                      !!state.addExpireTime && `This code expires in ${ state.expiresIn } minutes`
                    }
                    <Button type={ 'primary' } style={ {
                      marginTop: 6,
                      width: 300
                    } }>{ state.authCopyCode }</Button>
                  </div>
              }
            </div>
          </div>
        </div>
      </DeviceFrameset>
    </div>
  </div>
}

export default EditTemplates