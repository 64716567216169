import React, { useState, useEffect } from 'react'; 
import {useTranslation} from "react-i18next";
import "./LeftSection.scss";
import Tiebotone from "../../../assets/media/misc/TieBot PART 1.gif"
import Tiebottwo from "../../../assets/media/misc/TieBot PART 2.gif"


const LeftSection = () => {

    const [showFirstImage, setShowFirstImage] = useState(true); 

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowFirstImage(false); 
        }, 4000); 

        return () => clearTimeout(timer);
    }, []);

    const { t } = useTranslation();
    return <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" className="header-portal-first"  >
        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100" id="header-portal"style={{marginTop:'2%',height:"100%"}}>
            <h1 style={{color:"#fff",marginTop:"-1 !important", fontSize:"45px",fontFamily:"arial, Helvetica, sans-serif",fontWeight:"800",textTransform:"uppercase"}}className="d-none d-lg-block  text-center mt-7">Welcome to</h1>
            <div>
            {showFirstImage ? (
                <img
                    style={{ width: "47%" }}
                    className="d-none d-lg-block mx-auto w-md-43 w-xl-306px mt-8"
                    src={Tiebotone}
                    alt=""
                />
            ) : (
                <img
                    style={{ width: "47%" }}
                    className="d-none d-lg-block mx-auto w-md-43 w-xl-306px mt-8"
                    src={Tiebottwo}
                    alt=""
                />
            )}
        </div> 
            <div style={{color:"white"}} className="d-none d-lg-block fs-base text-center">
                <br />
         </div>
         <div className='main-Discription'>
         <p className="Discription" >Unlock your business potential with our multichannel customer engagement platform.</p>
         </div>
        </div>
    </div>
}

export default LeftSection