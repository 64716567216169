import { BellFilled, NotificationFilled, LockFilled }       from '@ant-design/icons';
import { Space, Radio, Alert, Form, Input, Select, Button } from 'antd';
import React, { useState }                                  from 'react';
import "./CreateTemplates.scss";

const { Option } = Select;
const CreateTemplates = () => {
  const [state, setState] = useState({
    category: ''
  })

  const onFinish = (values) => {
  }
  const Languages = [
    {
      "code": "af",
      "language": "Afrikaans"
    },
    {
      "code": "sq",
      "language": "Albanian"
    },
    {
      "code": "ar",
      "language": "Arabic"
    },
    {
      "code": "az",
      "language": "Azerbaijani"
    },
    {
      "code": "bn",
      "language": "Bengali"
    },
    {
      "code": "bg",
      "language": "Bulgarian"
    },
    {
      "code": "ca",
      "language": "Catalan"
    },
    {
      "code": "zh_CN",
      "language": "Chinese (Simplified)"
    },
    {
      "code": "zh_HK",
      "language": "Chinese (Hong Kong)"
    },
    {
      "code": "zh_TW",
      "language": "Chinese (Traditional)"
    },
    {
      "code": "hr",
      "language": "Croatian"
    },
    {
      "code": "cs",
      "language": "Czech"
    },
    {
      "code": "da",
      "language": "Danish"
    },
    {
      "code": "nl",
      "language": "Dutch"
    },
    {
      "code": "en",
      "language": "English"
    },
    {
      "code": "en_GB",
      "language": "English (UK)"
    },
    {
      "code": "en_US",
      "language": "English (US)"
    },
    {
      "code": "et",
      "language": "Estonian"
    },
    {
      "code": "fil",
      "language": "Filipino"
    },
    {
      "code": "fi",
      "language": "Finnish"
    },
    {
      "code": "fr",
      "language": "French"
    },
    {
      "code": "fr_CA",
      "language": "French (Canada)"
    },
    {
      "code": "ka",
      "language": "Georgian"
    },
    {
      "code": "de",
      "language": "German"
    },
    {
      "code": "el",
      "language": "Greek"
    },
    {
      "code": "gu",
      "language": "Gujarati"
    },
    {
      "code": "ha",
      "language": "Hausa"
    },
    {
      "code": "he",
      "language": "Hebrew"
    },
    {
      "code": "hi",
      "language": "Hindi"
    },
    {
      "code": "hu",
      "language": "Hungarian"
    },
    {
      "code": "id",
      "language": "Indonesian"
    },
    {
      "code": "ga",
      "language": "Irish"
    },
    {
      "code": "it",
      "language": "Italian"
    },
    {
      "code": "ja",
      "language": "Japanese"
    },
    {
      "code": "kn",
      "language": "Kannada"
    },
    {
      "code": "kk",
      "language": "Kazakh"
    },
    {
      "code": "ko",
      "language": "Korean"
    },
    {
      "code": "ky",
      "language": "Kyrgyz"
    },
    {
      "code": "lo",
      "language": "Lao"
    },
    {
      "code": "lv",
      "language": "Latvian"
    },
    {
      "code": "lt",
      "language": "Lithuanian"
    },
    {
      "code": "mk",
      "language": "Macedonian"
    },
    {
      "code": "ms",
      "language": "Malay"
    },
    {
      "code": "ml",
      "language": "Malayalam"
    },
    {
      "code": "mr",
      "language": "Marathi"
    },
    {
      "code": "nb",
      "language": "Norwegian"
    },
    {
      "code": "fa",
      "language": "Persian"
    },
    {
      "code": "pl",
      "language": "Polish"
    },
    {
      "code": "pt_BR",
      "language": "Portuguese (Brazil)"
    },
    {
      "code": "pt_PT",
      "language": "Portuguese (Portugal)"
    },
    {
      "code": "pa",
      "language": "Punjabi"
    },
    {
      "code": "ro",
      "language": "Romanian"
    },
    {
      "code": "ru",
      "language": "Russian"
    },
    {
      "code": "sr",
      "language": "Serbian"
    },
    {
      "code": "sk",
      "language": "Slovak"
    },
    {
      "code": "sl",
      "language": "Slovenian"
    },
    {
      "code": "es",
      "language": "Spanish"
    },
    {
      "code": "es_AR",
      "language": "Spanish (Argentina)"
    },
    {
      "code": "es_ES",
      "language": "Spanish (Spain)"
    },
    {
      "code": "es_MX",
      "language": "Spanish (Mexico)"
    },
    {
      "code": "sw",
      "language": "Swahili"
    },
    {
      "code": "sv",
      "language": "Swedish"
    },
    {
      "code": "ta",
      "language": "Tamil"
    },
    {
      "code": "te",
      "language": "Telugu"
    },
    {
      "code": "th",
      "language": "Thai"
    },
    {
      "code": "tr",
      "language": "Turkish"
    },
    {
      "code": "uk",
      "language": "Ukrainian"
    },
    {
      "code": "ur",
      "language": "Urdu"
    },
    {
      "code": "uz",
      "language": "Uzbek"
    },
    {
      "code": "vi",
      "language": "Vietnamese"
    },
    {
      "code": "zu",
      "language": "Zulu"
    }
  ]

  return <div className={ 'synctic-module-container create-templates' }>
    <div className={ ' cards-box' }>
      <h2>Category</h2>
      <div className={ 'para' }>Choose a category that describes your message template. View category examples.</div>
      <div className={ 'para' }>How to create a WhatsApp Template Message?</div>
      <br/>
      <Alert
        message="The category of your message template will set the price. Learn more about how conversation pricing works?"
        type="success"/>

<br/>
      <Form
        name="Form"
        onFinish={ onFinish }
        scrollToFirstError
        layout="vertical"
        style={ { width: '100%' } }
        labelAlign="left"
        labelWrap
        wrapperCol={ {
          flex: 1,
        } }
      >
        <Form.Item
          name="category"
          rules={ [{ required: true, message: 'Please select category' }] }
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={ 'utility' }>
                <div className={ "category-templates" }>
                  <BellFilled style={ { fontSize: 32, opacity: .8 } }/>
                  <div>
                    <h3 style={ { margin: 0 } }>
                      Utility
                    </h3>
                    <div className={ 'para' }>
                      Send messages about an existing order an account.
                    </div>
                  </div>
                </div>
              </Radio>
              <Radio value={ 'marketing' } style={ { marginTop: 12 } }>
                <div className={ "category-templates" }>
                  <NotificationFilled style={ { fontSize: 32, opacity: .8 } }/>
                  <div>
                    <h3 style={ { margin: 0 } }>
                      Marketing
                    </h3>
                    <div className={ 'para' }>
                      Send promotions or informations about your products, services or business
                    </div>
                  </div>
                </div>
              </Radio>
              <Radio value={ 'authentication' } style={ { marginTop: 12 } }>
                <div className={ "category-templates" }>
                  <LockFilled style={ { fontSize: 32, opacity: .8 } }/>
                  <div>
                    <h3 style={ { margin: 0 } }>
                      Authentication
                    </h3>
                    <div className={ 'para' }>
                      Send codes to verify a transaction or login.
                    </div>
                  </div>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={ [
            {
              type: 'string'
            },
            {
              required: true,
              max: 512
            },
          ] }
        >
          <Input.TextArea max={ 512 } showCount placeholder="Enter here"/>
        </Form.Item>
        <Form.Item
          name="language"
          label="Language"
        >
          <Select placeholder="Select language">
            {
              Languages.map(item => <Option value={ item.code }>{ item.language }</Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Continue</Button>
        </Form.Item>
      </Form>

    </div>
  </div>
}
export default CreateTemplates