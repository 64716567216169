import axios from "../../Authentication/headers";

export function getWhatsappChat(id, pageNumber){
    let data = {
        conversationId: id,
        pageNumber:pageNumber
    }
    return axios.post(`/message/messageScroll`, data)
}

// export function getWhatsappChat(id, pageNum){
//     return axios.get(`/message/${id}`)
// }

export function getChatData(id) {
    return axios.get(`/message/inbox/${id}`)
}

export function getInstagramChat(id){
    return axios.get(`/instMessage/${id}`)
}

export function getMessengerChat(id){
    return axios.get(`/messenger/${id}`)
}

export function exportReport(data){
    return axios.post(`/message/exportChat`, data)
}

export function assignStatus(data){
    return axios.post('/message/assignStatus', data)
}

export function assignMessengerStatus(data){
    return axios.post('/messenger/assignStatus', data)
}

export function assignInstagramStatus(data){
    return axios.post('/instMessage/assignStatus', data)
}



export function assignAgent(data){
    return axios.post('/message/assignAgent', data)
}

export function assignAgentMessenger(data){
    return axios.post('/messenger/assignAgent', data)
}


export function assignAgentInstagram(data){
    return axios.post('/instMessage/assignAgent', data)
}

export function searchAgents(data){
    return axios.get('/account/searchAgent')
}

export function conversationTimer(id){
    return axios.get(`/message/timer/${id}`)
}

export function postNote(data){
    return axios.post(`/message/addNote`, data)
}

export function getNote(id){
    return axios.get(`/message/allNote/${id}`)
}

export function deleteNote(id){
    return axios.get(`/message/deleteNote/${id}`)
}

export function updateNote(id, data){
    return axios.put(`/message/updateNote/${id}`, data)
}

export function addAttr(data){
    return axios.post(`/message/addAttr`, data)
}

export function getAttr(id){
    return axios.get(`/message/allAttr/${id}`)
}

export function deleteAttr(id){
    return axios.get(`/message/deleteAttr/${id}`)
}

export function updateAttr(id, data){
    return axios.put(`/message/updateAttr/${id}`, data)
}

export function getLogs(id){
    return axios.get(`/message/logs/${id}`)
}

export function assignToMe(data){
    return axios.post(`/message/assignToMe`, data)
}
export function getInboxContactInformation(id){
    return axios.get(`/message/inboxContactInformation/${id}`)
}

export function saveInboxContactInformation(data){
    return axios.post(`/message/saveInboxContactDetail`, data)
}

export function aiGenerateMsg(data){
    return axios.post(`/ai/generate`, data)
}

export function getAllForwarders(id){
    return axios.get(`/message/forwardList/${id}`)
}

export function getAllAgents(id){
    return axios.get(`/message/agentList/${id}`)
}

export function forwardChatToUser(data){
    return axios.post(`/message/forwardChat`, data)
}

export function getForwardChats(){
    return axios.get(`/message/getMyForwardChat`)
}

export function readAllMessages(id){
    return axios.get(`/message/readByAll/${id}`)
}

export function markAsRead(data){
    return axios.post(`/message/markAsRead`, data)
}

export function openedConversations(status) {
    return axios.get(`message/status/${status}`)
}


export function getAllChats() {
    return axios.get(`/message/inbox`)
}



