import axios from "../../../Authentication/headers";
import { markAsRead } from '../../Apis';
import {myProfile} from "./me";
import openNotificationWithIcon from "../../../ResuseableComponnets/Toaster";

const getAllWhatsappConversations = () => {
    return (dispatch) => {
        try {
            axios.get('/message/inbox').then(response=>{
                dispatch({
                    type:"GET_ALL_WHATSAPP_CONVERSATIONS",
                    payload:response.data.data
                })

            }).catch(()=>{
                openNotificationWithIcon('error',
                    'Error Message',
                    'Error while getting conversations.'
                )
            })
        } catch {
            openNotificationWithIcon('error',
                'Error Message',
                'Error while getting conversations.'
            )
        }
    }
}

const getAllInstagramConversations = () => {
    return (dispatch) => {
        try {
            axios.get('/instMessage/inbox').then(response=>{
                dispatch({
                    type:"GET_ALL_INSTAGRAM_CONVERSATIONS",
                    payload:response.data.data
                })
            }).catch(err=>{
                console.log(err, "Err")
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const getAllMessengerConversations = () => {
    return (dispatch) => {
        try {
            axios.get('/messenger/inbox').then(response=>{
                dispatch({
                    type:"GET_ALL_MESSENGER_CONVERSATIONS",
                    payload:response.data.data
                })
            }).catch(err=>{
                console.log(err, "Err")
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const getWhatsappConversationAction = (data) => {
    return (dispatch) => {
        try {
                dispatch({
                    type:"GET_WHATSAPP_CONVERSATION",
                    payload:data
                })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const deleteWhatsappConversationAction = (id) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"DELETE_WHATSAPP_CONVERSATION",
                payload:id
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const getMessengerConversationAction = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"GET_MESSENGER_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const getInstagramConversationAction = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"GET_INSTAGRAM_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const updateWhatsappConversation = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"UPDATE_WHATSAPP_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const updateInstagramConversation = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"UPDATE_INSTAGRAM_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const updateMessengerConversation = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"UPDATE_MESSENGER_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const updateWhatsappAllConversation = (data) => {
    console.log(data, 9911444)
    return (dispatch) => {
        try {
            dispatch({
                type:"UPDATE_All_WHATSAPP_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while getting the all conversations")
        }
    }
}

const updatedInbox = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"UPDATE_INBOX_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while updating conversation")
        }
    }
}
const markAsSent = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"MARK_MESSAGE_AS_SENT",
                payload:data
            })
        } catch {
            console.log("Error while changing the status of message")
        }
    }
}
const markAsReadAction = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"MARK_MESSAGE_AS_READ",
                payload:data
            })
        } catch {
            console.log("Error while changing status of message")
        }
    }
}

const markAsDeliveredAction = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"MARK_MESSAGE_AS_DELIVERED",
                payload:data
            })
        } catch {
            console.log("Error while changing status of message")
        }
    }
}

const inboxActiveTab = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"INBOX_ACTIVE_TAB",
                payload:data
            })
        } catch {
            console.log("Error while getting tab information")
        }
    }
}

const addForwardChat = (data) => {
    console.log(data, 'forwardChat' )
    return (dispatch) => {
        try {
            dispatch({
                type:"ADD_FORWARD_CHAT",
                payload:data
            })
        } catch {
            console.log("Error while adding forward chat.")
        }
    }
}

const removeChat = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type:"REMOVE_WHATSAPP_CONVERSATION",
                payload:data
            })
        } catch {
            console.log("Error while updating conversation")
        }
    }
}


export {
    markAsReadAction,
    markAsSent,
    getAllWhatsappConversations,
    getAllMessengerConversations,
    getWhatsappConversationAction,
    getMessengerConversationAction,
    getInstagramConversationAction,
    updateWhatsappConversation,
    updateMessengerConversation,
    updateInstagramConversation,
    getAllInstagramConversations,
    myProfile,
    updateWhatsappAllConversation,
    deleteWhatsappConversationAction,
    updatedInbox,
    inboxActiveTab,
    addForwardChat,
    removeChat,
    markAsDeliveredAction
}


