import { MoreOutlined, FilterFilled, ExportOutlined, ImportOutlined }  from "@ant-design/icons";
import Button                                                          from "@mui/material/Button";
import Dialog                                                          from "@mui/material/Dialog";
import DialogTitle                                                     from "@mui/material/DialogTitle";
import TextField                                                       from "@mui/material/TextField";
import { Dropdown, Menu, Form, Modal, DatePicker, Select, Input, Tag } from "antd";
import axios                                                           from 'axios';
import moment                                                          from "moment/moment";
import { MuiTelInput }                                                 from "mui-tel-input";
import React, { useEffect, useState, useRef }                          from "react";
import config                                                          from '../../../../config';
import Loader                                                          from '../../../ResuseableComponnets/Loader';
import openNotificationWithIcon                                        from '../../../ResuseableComponnets/Toaster';
import {
  AddContact,
  blockContactApi,
  deleteContactApi,
  GetAllContacts,
  GetSpecificContact,
  UnBlockContactApi,
  updateContactApi,
  exportContacts,
  filterContacts
}                                                                      from "../../Api/Api";
import { addGroup }                                                    from '../../Api/groupApis';
import ContactForm                                                     from "../Form/Form";
import Table_contacts                                                  from "../Table_contacts";
import { useTranslation }                                              from "react-i18next";

export default function Contacts() {
  const [state, setState] = useState({
    isOpen: false,
    name: "",
    email: "",
    phone: "",
    data: [],
    value: false,
    contactId: "",
    isEmailError: false,
    emailErrorMsg: "",
    isContactInfo: false,
    contactData: "",
    isValueChange: false,
    filterModal: false,
    columnsData: [],
    openDrawer: false,
    contactInfo: null,
    mode: '',
    loader: false,
    startDate: '',
    endDate: '',
    isOpenExportModal: false,
    dateRangeType: '',
    laSDate: '',
    laEDate: '',
    caSDate: '',
    caEDate: '',
    expVal: null,
    expLimit: 'isLess',
    ageVal: null,
    ageLimit: 'isLess',
    groups: [],
    users: [],
    isGroupModal: false
  });
  const [form] = Form.useForm();
  const fileInputRef = useRef(null);
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const getContacts = () => {
    setState({ ...state, loader: true, openDrawer: false, users: [] })
    GetAllContacts().then(res => {
        let data = res.data.data
        makeColumnsData(data)
      }
    ).catch(error => {
      console.log(error)
      setState({ ...state, loader: false })
    })
  }

  const BlockContact = (id) => {
    blockContactApi(id)
      .then(() => {
          getContacts()
        }
      )
      .catch(err => console.log(err, "err"))
  }

  const UnBlockContactFunc = (id) => {
    UnBlockContactApi(id)
      .then(() => {
          getContacts()
        }
      )
      .catch(err => console.log(err, "err"))
  }

  const GetSpecificContactFunc = (id) => {
    GetSpecificContact(id).then(res => {
      const groupIds = res?.data?.data?.groupList.map(item => item.id) || [];
      setState(prevState => ({
        ...prevState,
        contactInfo: res.data.data,
        groups: groupIds,
        openDrawer: true,
        mode: 'update',
        contactId: id
      }))
    }).catch(error => console.log(error, "error"))
  }
  const deleteContactApiFunc = (id) => {
    deleteContactApi(id)
      .then(() => {
          getContacts()
        }
      )
      .catch(err => console.log(err, "err"))
  }

  const makeColumnsData = (data) => {
    let objectData = data[0]
    delete objectData['_id'];
    delete objectData['updatedBy'];
    delete objectData['id'];
    let columnsData = !!objectData && Object.keys(objectData)
    columnsData.push("actions");
    let updatedArray = data.map((obj) => ({
      ...obj,
      key: obj.id,
      addedBy: `${ obj.addedBy?.firstName + ' ' + obj.addedBy?.lastName }`,
      created_at: moment(obj.created).format('MMMM Do YYYY, h:mm a'),
      tags: obj.tags.length > 0 && obj.tags.map((item) => <Tag color="magenta" style={ {
        marginTop: 2,
        marginLeft: 2
      } }>{ item }</Tag>),
      actions: <Dropdown overlay={ (
        <Menu>
          <Menu.Item onClick={ () => {
            GetSpecificContactFunc(obj.id)

          } } key="1">
            Edit
          </Menu.Item>
          <Menu.Item onClick={ () => deleteContactApiFunc(obj.id) } key="2">
            Delete
          </Menu.Item>
          <Menu.Item onClick={ () => BlockContact(obj.id) } key="3">
            Block
          </Menu.Item>
          <Menu.Item onClick={ () => UnBlockContactFunc(obj.id) } key="4">
            Unblock
          </Menu.Item>
        </Menu>
      ) } trigger={ ['click'] }>
        <a onClick={ (e) => e.preventDefault() }>
          <MoreOutlined style={ { fontSize: '14px' } }/>
        </a>
      </Dropdown>
    }))

    setState({
      ...state,
      columnsData,
      data: updatedArray,
      openDrawer: false,
      loader: false,
      isOpenExportModal: false,
      mode: '',
      users: [],
      isGroupModal: false
    })
  }

  const selectedTeam = (data) => {
    let newData = {
      ...data,
      'name': data.name
    }
    setState(prevState => ({ ...prevState, contactData: newData, isContactInfo: true }))
  }
  const back = () => {
    setState({ ...state, isContactInfo: false, })
  }
  const onSubmit = (data) => {
    AddContact(data).then(() => {
      getContacts()
    }).catch((err) => {
      console.log(err)
    })
  }
  const onUpdate = (data) => {
    updateContactApi(state.contactId, data).then(() => {
      getContacts()
    }).catch(error => console.log(error))
  }

  const closeDialogue = () => {
    setState({ ...state, openDrawer: false, mode: '' })
  }

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setState({
      ...state,
      email: newEmail,
      isEmailError: !validateEmail(newEmail),
      emailErrorMsg: ""
    });
  };

  const handleDateChange = (dates, dateStrings) => {
    setState({
      ...state,
      dateRangeType: 'custom',
      startDate: dateStrings[0],
      endDate: dateStrings[1]
    })
  };

  const createdAtFunc = (dates, dateStrings) => {
    setState({
      ...state,
      caSDate: dateStrings[0],
      caEDate: dateStrings[1]
    })
  };

  const lastActiveFunc = (dates, dateStrings) => {
    setState({
      ...state,
      laSDate: dateStrings[0],
      laEDate: dateStrings[1]
    })
  };


  const handleButtonClick = () => {
    // Trigger file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    setState({ ...state, loader: true })
    const formData = new FormData();
    Array.from(e.target.files).forEach((file) => {
      formData.append(`file`, file);
    });

    await axios.post(`${ config.api.baseUrl }/contact/import `, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
      }
    })
      .then(() => {
        openNotificationWithIcon('success',
          'Success Message',
          'Contacts imported successfully'
        )
        getContacts()
      })
      .catch((err) => {
        openNotificationWithIcon('error',
          'Error',
          err?.response?.data?.message
        )
        setState({ ...state, loader: false })
      });
    e.target.value = null
  };

  const changeExperience = (e) => {
    setState({
      ...state,
      expLimit: e
    })
  }

  const changeAge = (e) => {
    setState({
      ...state,
      ageLimit: e
    })
  }
  const exportFunction = () => {
    let { laEDate, laSDate, caEDate, caSDate, expVal, expLimit, ageLimit, ageVal } = state

    let updatedData = {};

    if (laEDate && laSDate) {
      updatedData = {
        ...updatedData,
        lastActive: { startDate: laSDate, endDate: laEDate }
      };
    }
    if (caSDate && caEDate) {
      updatedData = {
        ...updatedData,
        created_at: { startDate: caSDate, endDate: caEDate }
      };
    }
    if (expVal && expLimit) {
      updatedData = {
        ...updatedData,
        experience: { value: expVal, condition: expLimit }
      };
    }
    if (ageLimit && ageVal) {
      updatedData = {
        ...updatedData,
        age: { value: ageVal, condition: ageLimit }
      };
    }

    state.mode === 'filterData' ? filterContacts(updatedData).then((res) => {
        let data = res?.data?.data
        makeColumnsData(data)
        openNotificationWithIcon('success', 'Success message', 'Contacts getting successfully')
      }).catch(err => {
        openNotificationWithIcon('error', 'Error message', err?.response?.data?.message)
      }) :
      exportContacts(updatedData)
        .then((res) => {
          window.location.href = res?.data?.data?.file;
          openNotificationWithIcon('success', 'Success message', 'Contacts export successfully')
          setState({ ...state, isOpenExportModal: false, mode: '' })
        })
        .catch(err => openNotificationWithIcon('error', 'Error message', err?.response?.data?.message))
  }

  const SelectedContactsFunc = (users) => {
    setState({ ...state, users })
  }

  const onFinish = async (values) => {
    let data = {
      ...values.data
    }
    try {
      let response = await addGroup(data)
      if (response && response.data) {
        setState({ ...state, isGroupModal: false, users: [] })
        openNotificationWithIcon('success', 'Success Message', 'Group created successfully')
        getContacts()
      }
      else {
        console.log('Error', response)
        openNotificationWithIcon('error', 'Error Message', 'Error while creating group')
      }
    }
    catch (e) {
      openNotificationWithIcon('error', 'Error Message', 'Error while creating group')
    }
  };


  useEffect(() => {
    getContacts()
  }, [state.isContactInfo]);
  const { t } = useTranslation();
  return (
    <div style={ { width: "100%" } }>
      <div className='main-header'>
        <div className='all-contacts' style={ {
          display: "flex",
          justifyContent: "space-between"
        } }>
          { state.users.length > 0 ? <div style={ { display: 'flex', gap: 6, alignItems: 'center' } }>
            <Button variant="contained"
                    onClick={ () => {
                      setState({ ...state, isGroupModal: true })
                    } }>{t("Create Group")}</Button>
            <Tag color="#f50">{ state.users.length } {t("Member")}</Tag>
          </div> : <div/> }
          <div style={ { display: "flex", gap: 10 } }>
            <input
              type="file"
              ref={ fileInputRef }
              style={ { display: 'none' } }
              multiple
              onChange={ handleFileChange }
            />

            <FilterFilled
              title={ 'Filter' }
              style={ { fontSize: 20, opacity: .7 } }
              onClick={ () => setState({
                ...state, filterModal: true, mode: 'filterData'
              }) }
            />
            <ExportOutlined title={ 'Export' } style={ { fontSize: 20, opacity: .7 } }
                            onClick={ () => {
                              setState({ ...state, isOpenExportModal: true, mode: 'export' })
                            } }
            />

            <ImportOutlined style={ { fontSize: 20, opacity: .7 } } title={ 'Import' }
                            onClick={ handleButtonClick }
            />

            <Button variant="contained"
                    onClick={ () => {
                      setState({ ...state, isOpenExportModal: true, mode: 'filterData' })
                    } }>{t("Filter contacts")}</Button>
            <Button variant="contained"
                    onClick={ () => setState({
                      ...state,
                      openDrawer: true,
                      mode: 'new'
                    }) }>{t("Add new contact")}</Button>
          </div>
        </div>
      </div>
      {
        state.loader ? <Loader height={ '100%' }/> :
          state.columnsData.length > 0 && <Table_contacts
            filterModal={ state.filterModal }
            closeFilterModal={ () => setState({
              ...state,
              filterModal: false
            }) }
            selectedTeam={ selectedTeam }
            tableData={ state.data }
            columns={ state.columnsData }
            SelectedContactsFunc={ SelectedContactsFunc }
            isContacts={true}
          />
      }

      <Dialog
        onClose={ () => setState({
          ...state,
          isOpen: false,
          name: "",
          contactId: "",
          email: "",
          phone: "",
          isEmailError: false,
          emailErrorMsg: ""
        }) }
        open={ state.isOpen }
      >
        <DialogTitle>
          { !!state.contactId ? "Update Information" : t("Enter-Contact-Information") }</DialogTitle>
        <div style={ { display: "flex", flexDirection: "column", gap: 10, padding: 30, paddingTop: 10 } }>
          <TextField
            label={ t("title-Name") }
            placeholder={ t("Enter-Name") }
            fullWidth
            variant="outlined"
            size="small"
            style={ { width: 400 } }
            value={ state.name }
            onChange={ (e) => setState({ ...state, name: e.target.value }) }
          />
          <TextField
            error={ state.email === "" ? false : state.isEmailError }
            label={ t("Email-address") }
            placeholder={ t("Enter-Email") }
            id="outlined-error-helper-text"
            fullWidth
            variant="outlined"
            size="small"
            className="form-control bg-transparent"
            style={ { width: 400 } }
            value={ state.email }
            helperText={ state.emailErrorMsg }
            onChange={ (e) => handleEmailChange(e) }
          />
          <MuiTelInput
            required
            className={ 'textField' }
            size="small"
            id="outlined-required"
            defaultCountry="PK"
            value={ state.phone }
            onChange={ (e) => {
              setState({ ...state, phone: e })
            } }
          />

          <Button
            disabled={
              !state.name ||
              !state.email ||
              state.phone.length < 14 ||
              !validateEmail(state.email)
            }
            onClick={ !!state.contactId ? onUpdate : onSubmit } variant="contained">
            { !!state.contactId ? "Update" : t("Submit-btn") }

          </Button>
        </div>
      </Dialog>
      {
        state.openDrawer && <ContactForm
          stateData={ {
            ...state.contactInfo?.contact,
            groups: state?.groups
          } }
          getContacts={ getContacts }
          mode={ state.mode }
          form={ form }
          contactId={ state.contactId }
          openDrawer={ state.openDrawer }
          closeDialogue={ closeDialogue }
        />
      }

      {/*Export Contacts*/ }

      <Modal
        title={ state.mode === 'filterData' ? 'Filter' : 'Export' }
        open={ state.isOpenExportModal }
        okButtonProps={ {
          disabled:
            !state.ageVal &&
            !state.expVal &&
            !state.caSDate &&
            !state.caEDate &&
            !state.laEDate &&
            !state.laSDate
        } }
        onOk={ () => {
          exportFunction()
        } }
        okText={ state.mode === 'filterData' ? 'Filter' : 'Export' }
        destroyOnClose={ () => {
          setState({
            ...state,
            isOpenExportModal: false,
            ageLimit: 'isLess',
            ageVal: null,
            expLimit: 'isLess',
            expVal: null,
            caSDate: '',
            caEDate: '',
            laEDate: '',
            laSDate: ''
          })
        } }
        onCancel={ () => {
          setState({
            ...state,
            isOpenExportModal: false,
            ageLimit: 'isLess',
            ageVal: null,
            expLimit: 'isLess',
            expVal: null,
            caSDate: '',
            caEDate: '',
            laEDate: '',
            laSDate: ''
          })
        } }
      >
        <div>
          {t("Last Active")}
          <br/>
          <RangePicker
            value={ !!state.laSDate && !!state.laEDate ? [moment(state.laSDate), moment(state.laEDate)] : null }
            onChange={ lastActiveFunc }
            format="YYYY-MM-DD"
            style={ { marginTop: 4, width: '100%' } }
          />
        </div>
        <div style={ { marginTop: 6 } }/>
        <div>
          {t("Created At")}
          <br/>
          <RangePicker
            value={ !!state.caSDate && !!state.caEDate ? [moment(state.caSDate), moment(state.caEDate)] : null }
            onChange={ createdAtFunc }
            format="YYYY-MM-DD"
            style={ { marginTop: 4, width: '100%' } }
          />
        </div>
        <div style={ { marginTop: 6 } }/>
        {t("Experience")}
        <div style={ { display: 'flex', justifyContent: "space-between", gap: 10, marginTop: 4 } }>
          <Input value={ state.expVal } onChange={ (e) => setState({ ...state, expVal: e.target.value }) }
                 placeholder={ 'Experience Val' }/>
          <Select value={ state.expLimit } onChange={ changeExperience } placeholder="Select filter"
                  style={ { width: '30%' } }>
            <Option value="isLess">{t("Is Less")}</Option>
            <Option value="isEqual">{t("Is Equal")}</Option>
            <Option value="isGreater">{t("Is Greater")}</Option>
          </Select>
        </div>
        <div style={ { marginTop: 6 } }/>
        Age
        <div style={ { display: 'flex', justifyContent: "space-between", gap: 10, marginTop: 4 } }>
          <Input value={ state.ageVal } onChange={ (e) => setState({ ...state, ageVal: e.target.value }) }
                 placeholder={ 'Experience Val' }/>
          <Select value={ state.ageLimit } onChange={ changeAge } placeholder="Select filter"
                  style={ { width: '30%' } }>
            <Option value="isLess">{t("Is Less")}</Option>
            <Option value="isEqual">{t("Is Equal")}</Option>
            <Option value="isGreater">{t("Is Greater")}</Option>
          </Select>
        </div>
      </Modal>


      {/*Modal to create group*/ }
      <Modal
        title={ 'Create Group' }
        open={ state.isGroupModal }
        footer={ false }
        okText={ "Create" }
        destroyOnClose={ () => {
          setState({
            ...state,
            isGroupModal: false
          })
        } }
        onCancel={ () => setState({ ...state, isGroupModal: false }) }
      >
        <Form
          name="nest-messages"
          layout="vertical"
          onFinish={ onFinish }
          style={ {
            maxWidth: 600,
          } }
          initialValues={{
            data: {
              users:state.users
            }
          }}
        >
          <Form.Item
            name={ ['data', 'name'] }
            label="Name"
            rules={ [
              {
                required: true,
                message: "Name is important"
              },
            ] }
          >
            <Input placeholder={ "Name" }/>
          </Form.Item>
          <Form.Item name={ ['data', 'description'] } label="Description">
            <Input.TextArea placeholder={ 'Description' }/>
          </Form.Item>
          <Form.Item
            name={ ['data', 'users'] }
            label="Users"
            rules={ [
              {
                required: true,
                message: 'Select atleast one user',
                type: 'array',
              }
            ] }
          >
            <Select mode="multiple" placeholder="Please select Users">
              {
                state?.data.length > 0 && state.data.map(obj => <Option
                  value={ obj.id }>{ obj.name }</Option>)
              }
            </Select>
          </Form.Item>

          {/*<Tag color="#f50">{ state.users.length } Member</Tag>*/}
          <Form.Item
          >
            <Button style={ { float: 'right' } } type="primary" htmlType="submit" variant="contained">
              {t("Create")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>


    </div>
  )
}
