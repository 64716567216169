import { Button, Form, Input, Select, Radio } from 'antd';
import React, { useEffect, useState }         from 'react';
// import openNotificationWithIcon                from "../../../ResuseableComponnets/Toaster";
// import { getAllActivatedTags }                 from "../../../Settings/Tags/API/Api";
// import { AddContact, updateContactApi }        from "../../Api/Api";
// import { GetAllGroups, addMember }             from '../../Api/groupApis';
import { useTranslation }                     from 'react-i18next';
import { GetAllGroups }                      from '../Contacts/Api/groupApis';
import { apiall }                             from '../Settings/Api_mangement/Api/Api';
import { getAllActivatedTags, getAllTags }   from '../Settings/Tags/API/Api';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const SegmentForm = (props) => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    allTags: [],
    allGroups: [],
    allApis:[]
  })
  const [form] = Form.useForm();
  const {id} = useParams()
  const onFinish = (values) => {
    console.log(values, 4678211111)
    // props.mode === 'new' ?
    //   onSubmit(values) : onUpdate(values)
    // form.resetFields()
  // const onSubmit = (data) => {
  //   AddContact(data).then(() => {
  //     props.getContacts()
  //   }).catch((err) => {
  //     openNotificationWithIcon('error',
  //       'Error Message',
  //       err?.response?.data?.message
  //     )
  //   })
  // }
  // const onUpdate = (data) => {
  //   updateContactApi(props.contactId, data).then(() => {
  //     props.getContacts()
  //   }).catch(error => console.log(error))
  }
  const getAllTagsAndGroups = async () => {
    let allActiveTags = await getAllActivatedTags()
    let allApis = await  apiall()
    setState({
      ...state,
      allTags: !!allActiveTags?.data?.data ? allActiveTags.data.data : [],
      allApis: !!allApis?.data?.data ? allApis.data.data : []
    })
  }

  const options = [
    { value: 1, label: "Ask Address Information" },
    { value: 2, label: "Ask House Information" },
    { value: 3, label: "Ask Name" },
    { value: 4, label: "Ask Pickup or Delivery" },
    { value: 5, label: "Order Confirmed" },
    { value: 6, label: "Welcome Message With Catalog" },
  ];

  const  customerFeedback = [
    { value: 1, label: "Average" },
    { value: 2, label: "Great" },
    { value: 3, label: "Poor" }
  ];

  useEffect(() => {
    getAllTagsAndGroups()
  }, []);
  return (
    <div className={"synctic-module-container"}>
      <Form
        { ...formItemLayout }
        form={ form }
        name="register"
        onFinish={ onFinish }
        scrollToFirstError
        initialValues={ props.mode !== 'new' && {
          ...props.stateData
        } }
        layout="vertical"
        style={ { width: '100%' } }
        labelAlign="left"
        labelWrap
        wrapperCol={ {
          flex: 1,
        } }
      >
        <div className="cards-box">
          <h3>Create Segment</h3>
          <Form.Item
            name="name"
            label={ t("Name") }
            rules={ [
              {
                type: 'string'
              },
              {
                required: true,
                message: 'Enter here',
              },
            ] }
          >
            <Input placeholder="Name"/>
          </Form.Item>

          <Form.Item
            name="category"
            label={ "Category" }
            rules={ [
              {
                required: true,
                message: 'Enter here'
              },
            ] }
          >
            <Radio.Group>
              <Radio value={ "entire_audience" }>Entire Audience</Radio>
              <Radio value={ "active_last_24_hours" }>Active Last 24 Hours (Free)</Radio>
              <Radio value={ "list_upload" }>List Upload</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="cards-box" style={ {
          marginTop: 10
        } }>
          <h4>Segment Rules</h4>

          <h5 style={ { marginTop: 20 } }>Chatbot Menu</h5>
          <div style={ {
            marginTop: 10
          } }/>
          <Form.List
            name="chatBotList"
            rules={ [
              {
                validator: async (_, names) => {
                  if (!names || names.length === 0) {
                    return Promise.reject(new Error("At least one item is required"));
                  }
                  if (names.length > 5) {
                    return Promise.reject(new Error("You can add up to 5 items only"));
                  }
                },
              },
            ] }
          >
            { (fields, { add, remove }) => (
              <>
                { fields.map(({ key, name, fieldKey, ...restField }) => {
                  const selectedValues = form.getFieldValue("chatBotList")?.map((item) => item?.value) || [];
                  const availableOptions = state.allApis.filter(
                    (option) => !selectedValues.includes(option.value)
                  );

                  return (
                    <div
                      key={ key }
                      style={ {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 8,
                      } }
                    >
                      <Form.Item
                        { ...restField }
                        name={ [name, "botId"] }
                        fieldKey={ [fieldKey, "botId"] }
                        style={ { width: "33%" } }
                        rules={ [{ required: true, message: "Select a value" }] }
                      >
                        <Select placeholder="Select bot" style={ { width: "100%" } }>
                          { availableOptions.map((option) => (
                            <Select.Option key={ option.value } value={ option.value }>
                              { option.label }
                            </Select.Option>
                          )) }
                        </Select>
                      </Form.Item>
                      <p style={ { width: "33%", textAlign: "center" } }>Equals To</p>
                      <Form.Item
                        { ...restField }
                        name={ [name, "isTrue"] }
                        fieldKey={ [fieldKey, "isTrue"] }
                        style={ { width: "33%" } }
                        rules={ [{ required: true, message: "Select true/false" }] }
                      >
                        <div style={ {
                          display: "flex", gap: 10,
                          width: "100%"
                        } }>
                          <Select placeholder="Select true/false" style={ { width: "100%" } }>
                            <Select.Option value={ true }>True</Select.Option>
                            <Select.Option value={ false }>False</Select.Option>
                          </Select>
                          <MinusCircleOutlined onClick={ () => remove(name) }/>

                        </div>
                      </Form.Item>
                </div>
                  );
                }) }
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={ () => add() }
                    block
                    icon={ <PlusOutlined/> }
                    disabled={ fields.length >= 5 }
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </>
            ) }
          </Form.List>


          <h5 style={ { marginTop: 20 } }>Customer Feedback</h5>
          <div style={ {
            marginTop: 10
          } }/>
          <Form.List
            name="customerFeedback"
            rules={ [
              {
                validator: async (_, names) => {
                  if (!names || names.length === 0) {
                    return Promise.reject(new Error("At least one item is required"));
                  }
                  if (names.length > 5) {
                    return Promise.reject(new Error("You can add up to 5 items only"));
                  }
                },
              },
            ] }
          >
            { (fields, { add, remove }) => (
              <>
                { fields.map(({ key, name, fieldKey, ...restField }) => {
                  const selectedValues = form.getFieldValue("customerFeedback")?.map((item) => item?.value) || [];
                  const availableOptions = options.filter(
                    (option) => !selectedValues.includes(option.value)
                  );

                  return (
                    <div
                      key={ key }
                      style={ {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 8,
                      } }
                    >
                      <Form.Item
                        { ...restField }
                        name={ [name, "value"] }
                        fieldKey={ [fieldKey, "value"] }
                        style={ { width: "33%" } }
                        rules={ [{ required: true, message: "Select a value" }] }
                      >
                        <Select placeholder="Select a value" style={ { width: "100%" } }>
                          { availableOptions.map((option) => (
                            <Select.Option key={ option.value } value={ option.value }>
                              { option.label }
                            </Select.Option>
                          )) }
                        </Select>
                      </Form.Item>
                      <p style={ { width: "33%", textAlign: "center" } }>Equals To</p>
                      <Form.Item
                        { ...restField }
                        name={ [name, "isTrue"] }
                        fieldKey={ [fieldKey, "isTrue"] }
                        style={ { width: "33%" } }
                        rules={ [{ required: true, message: "Select true/false" }] }
                      >
                        <div style={ {
                          display: "flex", gap: 10,
                          width: "100%"
                        } }>
                          <Select placeholder="Select true/false" style={ { width: "100%" } }>
                            <Select.Option value={ true }>True</Select.Option>
                            <Select.Option value={ false }>False</Select.Option>
                          </Select>
                          <MinusCircleOutlined onClick={ () => remove(name) }/>
                        </div>
                      </Form.Item>
                    </div>
                );
                }) }
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={ () => add() }
                    block
                    icon={ <PlusOutlined/> }
                    disabled={ fields.length >= 5 }
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </>
            ) }
          </Form.List>

          <h5 style={ { marginTop: 20 } }>Conversation Tags</h5>
          <div style={ {
            marginTop: 10
          } }/>
          <Form.List
            name="conversationTags"
            rules={ [
              {
                validator: async (_, names) => {
                  if (!names || names.length === 0) {
                    return Promise.reject(new Error("At least one item is required"));
                  }
                  if (names.length > 5) {
                    return Promise.reject(new Error("You can add up to 5 items only"));
                  }
                },
              },
            ] }
          >
            { (fields, { add, remove }) => (
              <>
                { fields.map(({ key, name, fieldKey, ...restField }) => {
                  const selectedValues = form.getFieldValue("conversationTags")?.map((item) => item?.value) || [];
                  const availableOptions = state.allTags.filter(
                    (option) => !selectedValues.includes(option.name)
                  );

                  return (
                    <div
                      key={ key }
                      style={ {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 8,
                      } }
                    >
                      <Form.Item
                        { ...restField }
                        name={ [name, "value"] }
                        fieldKey={ [fieldKey, "value"] }
                        style={ { width: "33%" } }
                        rules={ [{ required: true, message: "Select a value" }] }
                      >
                        <Select placeholder="Select a value" style={ { width: "100%" } }>
                          { availableOptions.map((option) => (
                            <Select.Option key={ option.name } value={ option.name }>
                              { option.name }
                            </Select.Option>
                          )) }
                        </Select>
                      </Form.Item>
                      <p style={ { width: "33%", textAlign: "center" } }>Equals To</p>
                      <Form.Item
                        { ...restField }
                        name={ [name, "isTrue"] }
                        fieldKey={ [fieldKey, "isTrue"] }
                        style={ { width: "33%" } }
                        rules={ [{ required: true, message: "Select true/false" }] }
                      >
                        <div style={ {
                          display: "flex", gap: 10,
                          width: "100%"
                        } }>
                          <Select placeholder="Select true/false" style={ { width: "100%" } }>
                            <Select.Option value={ true }>True</Select.Option>
                            <Select.Option value={ false }>False</Select.Option>
                          </Select>
                          <MinusCircleOutlined onClick={ () => remove(name) }/>
                        </div>
                      </Form.Item>
                      <MinusCircleOutlined onClick={ () => remove(name) }/>
                    </div>
                  );
                }) }
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={ () => add() }
                    block
                    icon={ <PlusOutlined/> }
                    disabled={ fields.length >= 5 }
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </>
            ) }
          </Form.List>

        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {/*<Form.Item { ...tailFormItemLayout }>*/ }
        {/*    <Button type="primary" htmlType="submit">*/ }
        {/*      { props.mode === 'new' ? t('Submit') : t('Update') }*/ }
        {/*    </Button>*/ }
        {/*  </Form.Item>*/ }
      </Form>
    </div>
  )
};
export default SegmentForm;