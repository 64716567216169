import axios from "../../../Authentication/headers";

export function RoleCreate(data) {
    return axios.post(`/role/create`,data);
  }
  export function AllRole() {
    return axios.get(`/role/all`);
  }
  export function Roleone(id) {
    return axios.get(`/role/one/${id}`);
  }
  export function roleupdate(id, data) {
    return axios.put(`/role/update/${id}`, data);
  }
  export function roledelete(id) {
    return axios.get(`/role/delete/${id}`);
  }
  export function rolesuspend(id) {
    return axios.get(`/role/suspend/${id}`);
  }
  export function RoleActive(id) {
    return axios.get(`/role/activate/${id}`);
  }
  export function AllDropdown() {
    return axios.get(`/role/dropdown`);
  }