import React, {useState, useEffect} from 'react';
import {Button, Input, Drawer, Form, Tabs, message} from 'antd';
import Message from './message';
import {CloseOutlined} from '@ant-design/icons';
import {createOptionNode} from "../hooks/message";
import getMediaType from "../hooks/fileType";
import {uploadFile} from "./api/list";
import {MdDelete, MdCheck} from "react-icons/md";
import {useNodes} from '../../allnode';
import CustomSelectBox from './fields/option';
import { formList, apiList} from './api/list';
import BuildTemplate from './buildTemplate';
import Loopback from './loopBack';
import CustomConfirmPopup from './fields/confirmPopup';
import "./style.scss"

const TriggerResponse = ({saveNodes}) => {
    const [waitForm, setWaitForm] = useState(false)
    const [forms, setForms] = useState(null)
    const [apis, setApis] = useState(null); 

    

    const [keywordForm] = Form.useForm();
    const [form] = Form.useForm();
    
    const {nodes, showEdit, setShowEdit, triggerName, setNodes, setTriggerName, keyword, setKeyword} = useNodes();

    const [messageOption, setMessageOption] = useState({
        message: true,
        api: false,
        form: false,
        template: false,
    })

    // Function to push an element to the keyword array
    const addKeyword = (values) => {
        let newKeyword = values.keyword;
        if (!newKeyword) {
            return true;
        }
        setKeyword(prevKeywords => [...prevKeywords, newKeyword]);
        keywordForm.resetFields()
    };

    const deleteKeyword = (index) => {
        setKeyword(prevKeywords => prevKeywords.filter((_, i) => i !== index));
    };

    // useEffect(() => {
    //     // if (showEdit?.data?.messageType) {
    //     //     let messageType = showEdit?.data?.messageType;

    //     //     if (messageType === 'template' || messageType === 'media' || messageType === 'text') {
    //     //         messageType = 'message';
    //     //     }

    //     //     setMessageOption((prevState) => {
    //     //         const updatedOptions = {...prevState};
    //     //         Object.keys(updatedOptions).forEach((key) => {
    //     //             updatedOptions[key] = key === messageType;
    //     //             updatedOptions['activeTab'] = messageType
    //     //         });
    //     //         return updatedOptions;
    //     //     });
    //     // }
       
    // },[showEdit])

    console.log(showEdit)

    useEffect(() => {
        if (showEdit?.data?.messageType) {
            let messageType = showEdit?.data?.messageType || undefined;
            if (messageType === 'template' || messageType === 'media' || messageType === 'text') {
                messageType = 'message';
            }
            setMessageOption((prevState) => {
                const updatedOptions = {...prevState};
                Object.keys(updatedOptions).forEach((key) => {
                    updatedOptions[key] = key === messageType;
                });
                return updatedOptions;
            });
        }

        if (showEdit?.data?.triggerName) {
            setTriggerName(showEdit.data.triggerName)
        }
        if (showEdit?.data?.keywords?.length > 0) {
            setKeyword(showEdit.data.keywords)
        }

        formList().then((res) => {
            setForms(res.data || []); 
        });

        apiList().then((res) => {
            setApis(res.data || []); 
        });

        const initialValue = {
            triggerName: showEdit?.data?.triggerName ? showEdit.data.triggerName : '',
            botToHuman: showEdit?.data?.botToHuman ? showEdit.data.botToHuman : '',
            fallBackResponse: showEdit?.data?.fallBackResponse ? showEdit.data.fallBackResponse : '',
            message: showEdit?.data?.message ? showEdit.data.message : '',
            messageType: showEdit?.data?.messageType ? showEdit.data.messageType : '',
            mediaType: showEdit?.data?.mediaType ? showEdit.data.mediaType : '',
            options: showEdit?.data?.options?.length > 0 ? showEdit.data.options : [''],
            template: showEdit?.data?.template ? showEdit.data.template : '',
            loopback: showEdit?.data?.loopback ? showEdit.data.loopback : '',
            form: showEdit?.data?.form ? showEdit.data.form : '',
            api: showEdit?.data?.api ? showEdit.data.api : '', 
        };

        console.log(initialValue)

        form.setFieldsValue(initialValue);
    }, [showEdit])

    const closeDrawer = () => {
        setKeyword([])
        setTriggerName('')
        setShowEdit({})
    }

    const switchSection = (section) => {
        setMessageOption(prevState => {
            const updatedOptions = {...prevState};
            updatedOptions[section] = true;
            Object.keys(updatedOptions).forEach(key => {
                if (key !== section) {
                    updatedOptions[key] = false;
                }
            });
            return updatedOptions;
        });
    };
    // Submit Form
    const onFinish = async (values) => {
        if (!triggerName) {
            message.error("Trigger name is required")
            return true;
        }

        if (values?.media?.length > 0 && !values.mediaType) {
            message.error("You cant upload a media until you select media type")
            return true;
        }

        setWaitForm(true)
        // deleting all previous values
        try {
            let messageData = {};
            if(values?.template){
                messageData = {template : values.template, loopback : values.loopback, messageType: 'template', triggerName: triggerName, keywords: keyword}
            }
            else{
                messageData = await getMessageData(values);
                delete messageData?.media
                if (messageData === null) {
                    setWaitForm(false)
                    return true
                }
                else{
                    messageData = {...messageData, triggerName: triggerName, keywords: keyword}
                }
            }

            console.log(messageData)

            // updating message data
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === showEdit?.id) {
                        node.data = messageData;
                    }
                    return node;
                })
            );

            setTimeout(async () => {
                setTriggerName('')
                setKeyword([])
                setWaitForm(false)
                form.resetFields();
                setShowEdit({});

                const response = await saveNodes();
                if (response === null) {
                    message.error("Node has been created but has some problem to save in database")
                }
            }, 500);

        } catch (error) {
            console.error("An error occurred:", error);
        }

    };

    const getMessageData = async (values) => {


        let messageData = {messageType: 'message'};

        if (showEdit.data.url && showEdit.data.fileKey && showEdit.data.mediaType) {
            messageData['url'] = showEdit.data.url
            messageData['fileKey'] = showEdit.data.fileKey
            messageData['mediaType'] = showEdit.data.mediaType
        }

        // if option  exist in form
        if (values?.options?.length > 0) {
            let optionList = [];
            if (showEdit?.data?.options?.length > 0) {

                optionList = values?.options;
                const allChildNodes = nodes.filter(node => node.parentNode === showEdit.id);
                const existingChildNodes = new Set(allChildNodes.map(node => node.id));

                // Create a set of option IDs for efficient lookup
                const optionIdsSet = new Set(values.options.map(option => option.id && option.id));

                setNodes(nds => {
                    return nds.map(node => {
                        if (optionIdsSet.has(node.id)) {
                            const option = values.options.find(option => option.id === node.id);
                            node.data.content = option.value;
                            existingChildNodes.delete(node.id);
                        }
                        return node;
                    });
                });

                // removing that does not exist
                setNodes((nodes) =>
                    nodes.filter((node) => !existingChildNodes.has(node.id))
                );

                // adding new one
                let updatedOptionList = [];
                const newOptionNodes = [];
                values.options.forEach((option, key) => {
                    if (!option.id && option.value) {
                        let index = key + 1;
                        const optionNodeId = `option${index}_${new Date().getTime()}`;
                        updatedOptionList.push({id: optionNodeId, value: option.value});
                        newOptionNodes.push(createOptionNode(index, option.value, optionNodeId, showEdit.id));
                    } else {
                        updatedOptionList.push({id: option.id, value: option.value})
                    }
                });

                // Concatenate newOptionNodes with the current nodes and update the state once
                setNodes(nodes => nodes.concat(newOptionNodes));

                optionList = updatedOptionList

            } else {
                values.options.forEach((option, key) => {
                    if (option.value) {
                        let index = key + 1;
                        const optionNodeId = `option${index}_${new Date().getTime()}`;
                        optionList.push({id: optionNodeId, value: option.value})
                        const optionNode = createOptionNode(index, option.value, optionNodeId, showEdit.id);
                        setNodes((nodes) => nodes.concat(optionNode));
                    }
                });
            }
            values['options'] = optionList;
        } else {
            if (showEdit?.data?.options?.length > 0) {
                setNodes((nodes) =>
                    nodes.filter((node) => node.parentNode !== showEdit.id)
                );
            }
        }

        // if media exist
        if (values?.media?.length > 0) {
            let mediaInfo = await handleMediaMessage(values);
            if (mediaInfo === null) {
                return null
            }

            messageData = {...messageData, ...mediaInfo}
        }
        messageData = {...values, ...messageData};

        return messageData;
    };

    const handleMediaMessage = async (values) => {
        // if(showEdit.data.fileKey && showEdit.data.url){
        //   const deleteResponse =  await deleteFile(showEdit.data.fileKey)
        //   console.log(deleteResponse)
        // }

        // checking file type with selected one
        const mediaFile = values.media[0].originFileObj
        let fileMimeType = getMediaType(mediaFile.type)
        if (fileMimeType !== values.mediaType) {
            message.error("File does not match with selected file type")
            return null;
        }
        // checking file type with selected one
        const response = await uploadFile(mediaFile)

        if (response === null) {
            message.error("Error while uploading file try again")
            return null;
        }

        let mediaObj = {};
        mediaObj["url"] = response.url
        mediaObj["fileKey"] = response.fileKey
        return mediaObj

    };

    return (
        <Drawer
            width="750px"
            open={showEdit.type ? true : false}
            className='style-drawer'
            closeIcon={<div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
                <CloseOutlined style={{textAlign: 'right', marginRight: '-20px'}}/>
            </div>}
            onClose={closeDrawer}
            closable={false}
        >
            <div className="drawer-container">
            <div className="main-btn mb-4">
                        <div className="button-container">
                            <Button
                            onClick={() => switchSection("message")}
                            className={'check-btn ' + (messageOption.message && "active")}
                            >
                            {messageOption.message &&  <MdCheck className="MdDelete " style={{fontSize: "22px", fontWeight : "600", marginRight : "10px"}} /> }
                            Message
                            </Button>
                            <Button
                            onClick={() => switchSection("api")}
                            className={'check-btn ' + (messageOption.api && "active")}
                            >
                            {messageOption.api &&  <MdCheck className="MdDelete" style={{fontSize: "22px", fontWeight : "600", marginRight : "10px"}} /> }
                            API
                            </Button>
                            <Button
                            onClick={() => switchSection("form")}
                            className={'check-btn ' + (messageOption.form && "active")}
                            >
                            {messageOption.form &&  <MdCheck className="MdDelete" style={{fontSize: "22px", fontWeight : "600", marginRight : "10px"}} /> }
                            Form
                            </Button>
                            <Button
                            onClick={() => switchSection("template")}
                            className={'check-btn ' + (messageOption.template && "active")}
                            >
                            {messageOption.template &&  <MdCheck className="MdDelete" style={{fontSize: "22px", fontWeight : "600", marginRight : "10px"}} /> }
                            Template
                            </Button>
                        </div>
                    </div>
                <div className="mt-4">
                    <Input id='triggerName' className='style-input' value={triggerName} placeholder="Trigger name"
                        onChange={(e) => {
                        setTriggerName(e.target.value)
                    }}/>
                </div>
                <Form
                    name="addKeyWord"
                    form={keywordForm}
                    initialValues={{}}
                    onFinish={addKeyword}
                    autoComplete="off"
                    className="mt-3"
                >
                    <label htmlFor="keywordInput" className='style-label'>Initial Conversation With</label>
                    <div className="keyword-form" style={{marginTop: 6}}>
                        <Form.Item
                            className='keyword-input'
                            name="keyword"
                            style={{width: '100%'}}
                        >
                            <Input id='keywordInput' className='style-input'/>
                        </Form.Item>
                        <Button htmlType="submit" type='primary'>Add Keyword</Button>
                    </div>
                </Form>
                {
                    keyword?.length > 0 &&  <div className="main-Initial-Con">
                        <div className="button-reg">
                            { keyword.map((val, key) => (
                                <span className='keyword-btn' key={key}>
                        {val} <MdDelete className="MdDelete" style={{fontSize: "20px"}}
                                        onClick={() => deleteKeyword(key)}/>
                    </span>
                            ))}
                        </div>
                    </div>
                    
                }
                <Form
                form={form}
                onFinish={onFinish}
                className="text-form"
                >
                {/* message section */}
                {(messageOption.message || messageOption.api) && 
                <>
                 {messageOption.api &&
                    <div className="message-inner-section">    
                        <h3 className="sub-heading" style={{marginBottom:10, textAlign:'start'}}>Select API</h3>
                        {apis === null && <span className="message-text">Getting API List</span>}
                        {apis?.length === 0 ? <span className="message-text">API not found</span> : apis?.length > 0 && <CustomSelectBox options={apis} name='api' label="Select any api" />}
                    </div>
                }
                <Message showEditValue={showEdit?.type} saveNodes={saveNodes}/>
                </>
                }
                {messageOption.form &&
                <>
                    <div className="message-inner-section">   
                        <h4 className="sub-heading" style={{marginBottom:10, textAlign:'start'}}>Select Form</h4>
                        {forms === null && <span className="message-text"> Getting Form List </span>}
                        {forms?.length === 0 ?  <span className="message-text">Form not found</span> : forms?.length > 0 && <CustomSelectBox options={forms} name='form' label="Select any form" />}
                    </div>
                    <Loopback />
                </>
                }
                {messageOption.template &&
                <>
                    <BuildTemplate />
                    <Loopback />
                </>
                }
                <div className="button-container-Update d-flex justify-content-center">
                    {
                        waitForm ?
                            'Wait ! all nodes are saving'
                            :
                        <CustomConfirmPopup  form={form}>
                            <Button type="primary" htmlType="button">Update Trigger</Button>
                        </CustomConfirmPopup>
                    }
                </div>
                </Form>
            </div>
        </Drawer>
    )
}

export default TriggerResponse








