import React, {useState} from "react";
import "./Reports.scss";
import ReportsStats from "./ReportsStats";
import { useTranslation } from 'react-i18next';
import {FilterFilled} from '@ant-design/icons';
import TiebotDateFilters from "../../Modules/Dashboard/components/reuseableComponents/TiebotDateFilters";
import {whatsappReportData} from "../../Modules/Dashboard/states/actions"
import {whatsappReportFilter} from "../Reports/Api/Api"
import { useDispatch } from "react-redux";
import {Tag} from "antd";
const Reports = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [state, setState] = useState({
        isFilterOpen: false,
        isModalOpen:false,
        filterDuration:''
    })
    const closeFilterModal = (start, end, duration) => {
        let data = duration==='custom' ?
          {
              startDate:start,
              endDate: end,
          } :
          {
              filterDuration: duration
          }

          if(duration==='clear'){
              dispatch(whatsappReportData())
              setState({
                  ...state,
                  isFilterOpen: !state.isFilterOpen,
                  filterDuration:duration
              })
          } else {
            whatsappReportFilter(data)
                .then((res) => {
                    console.log(res,7765444)
                    setState({
                        ...state,
                        isFilterOpen: !state.isFilterOpen,
                        filterDuration:duration,
                        startDate: start,
                        endDate: end
                    })
                    dispatch(whatsappReportData(res?.data?.data))
                })
                .catch((error) => {
                    console.log(error);
                });
          }
    }
    return <div className="reports-main synctic-module-container">
        <div>
            <div className="cards-box" style={{paddingTop:10,paddingBottom:6}}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
            <div className="reports-heading">{t("Conversation-Reports")}</div>
            <TiebotDateFilters
          isFilterOpen={state.isFilterOpen}
          closeModal={()=>setState({...state, isFilterOpen: !state.isFilterOpen})}
          closeFilterModal={closeFilterModal}
          defaultValue={state.filterDuration}
        />
        <div style={{display:"flex"}}>
{
                  state.filterDuration !== 'clear' &&
                    <Tag color="magenta" style={{display:state.filterDuration.length > 0 ? "block" : "none"}}>{state.filterDuration === 'custom' ? `${state.filterDuration}  ${state.startDate} to ${state.endDate}`  : state.filterDuration}</Tag>
                }        <FilterFilled
                    style={{fontSize: 20}}
                    onClick={() => setState({
                        ...state,
                        isFilterOpen: !state.isFilterOpen,
                        mode: 'timeFilter'
                    })}
                    />
                    </div>
            </div>
            <div className="reports-tabs-parent">
            </div>
            </div>
            <ReportsStats/>
        </div>
    </div>
}

export default Reports;