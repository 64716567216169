import React, { useState, useEffect } from "react";
import { Button, Checkbox, Modal, Table, Tabs } from "antd";
import AgentsFilterTable from "../AgentPerformance/AgentsFilterTable";
import Cards from "../reuseableComponents/Cards";
import "./AgentConversations.scss";
import Line from "../../../ResuseableComponnets/Charts/Line";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetConversionData } from "../../states/actions";
import { useTranslation } from "react-i18next";

const AgentConversations = (props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 3,
  });
  const agentMessage = useSelector(
    (state) => state.dashboard?.Conversation?.messagesAndStats?.agentMessages
  );
  const open = useSelector((state) => state.dashboard?.Conversation?.messagesAndStats?.open);
  const progress = useSelector(
    (state) => state.dashboard?.Conversation?.messagesAndStats?.inProgress
  );
  const solved = useSelector(
    (state) => state.dashboard?.Conversation?.messagesAndStats?.solved
  );
  const waiting  = useSelector(
    (state) => state.dashboard?.Conversation?.messagesAndStats?.waiting
  );
  const assigned = useSelector(
    (state) => state.dashboard?.Conversation?.messagesAndStats?.assigned
  );
  const ssss = useSelector(
    (state) => state.dashboard?.Conversation
  );
  console.log(ssss,76545433322)
  const exit = useSelector((state) => state.dashboard?.Conversation?.messagesAndStats?.exit);
  const tags = useSelector((state) => state.dashboard?.Conversation?.tags);
  const ConversationInitiated = useSelector((state) => state.dashboard?.Conversation?.statusStats || []);
  const AllData = ConversationInitiated.map(item => ({
    date: item.date,
    solved: item.solved,
    opened: item.opened,
    in_progress: item.in_progress
  }));
  const  WiseConversations = useSelector((state) => state.dashboard?.Conversation?.channelConversationCount || []
);

  useEffect(() => {
    dispatch(GetConversionData())
  }, []);
  const [state, setState] = useState({
    initiated: false,
    open: false,
    inProgress: false,
    closed: false,
    pending: false,
    excited: false,
    cis: false,
    cts: false,
    cwc: false,
    mode: "",
    whatsapp: "",
    instagram: "",
    messenger: "",
  });
  let { title, value, isModalOpen, mode, modalOpenFunc } = props;
  const modalFooter = [
    <Button key="cancel" onClick={() => modalOpenFunc()}>
      Cancel
    </Button>,
    <Button
      key="apply"
      onClick={() => modalOpenFunc()}
      primary
      style={{ display: mode === "export" ? "none" : "" }}
    >
      Apply
    </Button>,
  ];
 
  const AgentsBoardColumns = [
    {
      title: t("Conversation tags"),
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: t("Count"),
      dataIndex: "count",
      key: "count",
    },
  ];

  const Alldata =
    tags && Array.isArray(tags)
      ? tags.map((item, index) => ({
          ...item,
          key: index,
          count: item.count,
          topic: item.topic,
        }))
      : [];

  const AgentsAvailibilityData = [];
  const items = [
    {
      key: "1",
      label: "Channels",
      children: (
        <div>
          <div style={{ fontWeight: "550" }}>Channel</div>
          <div style={{ marginTop: 6 }} />
          <Checkbox
            checked={state.whatsapp}
            onChange={() => setState({ ...state, whatsapp: !state.whatsapp })}
          >
            Whatsapp
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.messenger}
            onChange={() => setState({ ...state, messenger: !state.messenger })}
          >
            Messenger
          </Checkbox>
          <div style={{ marginTop: 4 }} />
          <Checkbox
            checked={state.instagram}
            onChange={() => setState({ ...state, instagram: !state.instagram })}
          >
            Instagram
          </Checkbox>
          <div style={{ marginTop: 4 }} />
        </div>
      ),
    },
  ];
  const data = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "No Data available",
        data: [0, 0, 0, 0, 0, 0, 0], 
        borderColor: "red",
        borderWidth: 1,
        fill: false,
      },
    ],
  };
  
  const channelWiseData = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "No Data available",
        data: [0, 0, 0, 0, 0, 0, 0], 
        borderColor: "red",
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const ChartData = {
    labels: WiseConversations.label, 
    datasets: [
      {
        label: 'Conversation Count',
        data: WiseConversations.value, 
        fill: false,
        borderColor: '#10163a',
        tension: 0.1
      }
    ]
  };
 
  const labels = AllData.map(item => item.date);
  const solvedData = AllData.map(item => item.solved);
  const openedData = AllData.map(item => item.opened);
  const inProgressData = AllData.map(item => item.in_progress);

  const AllChartData = {
    labels: labels,
    datasets: [
      {
        label: t('Solved'),
        data: solvedData,
        borderColor: '#10163a',
        fill: false
      },
      {
        label: t('Opened'),
        data: openedData,
        borderColor: '#10163a',
        fill: false
      },
      {
        label: t('In Progress'),
        data: inProgressData,
        borderColor: '#10163a',
        fill: false
      }
    ]
  };
 
  return (
    <div>
      <div className="count-card-container">
        <Cards title={t("Message")} value={agentMessage || 0} />
        <Cards title={t("Open")} value={open || 0} />
        <Cards title={t("In Progress")} value={progress || 0} />
        <Cards title={t("Assigned")} value={assigned || 0} />
        <Cards title={t("Waiting")} value={waiting || 0} />
        <Cards title={t("Solved")} value={solved || 0} />
        <Cards title={t("Exit By Customer")} value={exit || 0} />

      </div>
      <div className={"agents-availability-container"}>
        <div className={"cards-box"} style={{ width: "30%" }}>
          <h6>{t("Conversation tags")}</h6>
          <Table
            columns={AgentsBoardColumns}
            dataSource={Alldata}
            pagination={pagination}
            onChange={(page) => {
              setPagination(page)
            }}
            style={{
              marginTop: 24,
              height: 200,
              overflowY: "scroll",
            }}
          />
        </div>
        <div className={"cards-box"} style={{ width: "70%" }}>
          <h6>{t("Conversation Initiated Vs In-Progress Vs Solved")}</h6>
              <Line height={240} data={AllChartData} />
        </div>
      </div>
      <div
        className={"cards-box"}
        style={{
          width: "100%",
          marginTop: 10,
        }}
      >
        <h6>{t("Channel Wise Conversations")}</h6>
          <Line height={240} data={ChartData} />     
      </div>
      <Modal
        title={
          mode === "export"
            ? "Export Insights"
            : mode === "export" && "Filter Agent Performance"
        }
        open={isModalOpen}
        onCancel={() => {
          modalOpenFunc();
          setState({
            ...state,
            mac: false,
            tCustomers: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
        footer={modalFooter}
        closable={() => {
          modalOpenFunc();
          setState({
            ...state,
            mac: false,
            tCustomers: false,
            whatsapp: false,
            instagram: false,
            messenger: false,
          });
        }}
      >
        {props.mode === "export" ? (
          <div>
            <div style={{ fontWeight: "550" }}>Include breakdown by</div>
            <div style={{ marginTop: 6 }} />
            <Checkbox
              checked={state.initiated}
              onChange={() =>
                setState({ ...state, initiated: !state.initiated })
              }
            >
              Initiated
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.open}
              onChange={() => setState({ ...state, open: !state.open })}
            >
              Open
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.inProgress}
              onChange={() =>
                setState({ ...state, inProgress: !state.inProgress })
              }
            >
              In Progress
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.closed}
              onChange={() => setState({ ...state, closed: !state.closed })}
            >
              Closed
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.pending}
              onChange={() => setState({ ...state, pending: !state.pending })}
            >
              Pending
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.excited}
              onChange={() => setState({ ...state, excited: !state.excited })}
            >
              Excited
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.cis}
              onChange={() => setState({ ...state, cis: !state.cis })}
            >
              Conversation Initiated Vs In-Progress Vs Solved
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.cts}
              onChange={() => setState({ ...state, cts: !state.cts })}
            >
              Conversation Tags Stats
            </Checkbox>
            <div style={{ marginTop: 4 }} />
            <Checkbox
              checked={state.cwc}
              onChange={() => setState({ ...state, cwc: !state.cwc })}
            >
              Channel-wise Conversations
            </Checkbox>
          </div>
        ) : (
          mode === "filter" && (
            <Tabs defaultActiveKey="1" tabPosition={"top"} items={items} />
          )
        )}
      </Modal>
    </div>
  );
};
export default AgentConversations;
