import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";

const MainDrop = () => {
  const [state, setState] = useState({
    selectedLanguage: "",
  });
  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const languageOptions = [
    {
      id: 1,
      name: "English",
      language: "en",
      flag: require("../../assets/media/flags/united-states.svg").default,
    },
    {
      id: 2,
      name: "Arabic",
      language: "ea",
      flag: require("../../assets/media/flags/saudi-arabia.svg").default,
    },
    {
      id: 3,
      name: "Spanish",
      language: "es",
      flag: require("../../assets/media/flags/spain.svg").default,
    },
  ];

  let selectedOption = languageOptions.find(
    (item) => item.language === state.selectedLanguage
  );

  useEffect(() => {
    setState({ selectedLanguage: i18n.language });
  }, []);

  const menu = (
    <Menu>
      {languageOptions.map((option) => (
        <Menu.Item
          key={option.id}
          onClick={() => {
            setState({ ...state, selectedLanguage: option.language });
            changeLanguage(option.language);
          }}
        >
          <img
            style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "8px" }}
            src={option.flag}
            alt=""
          />
          <span>{option.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()} style={{ display: "flex", alignItems: "center", color: "#595959", textDecoration: "none" }}>
            <img
              style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "8px" }}
              src={selectedOption?.flag}
              alt=""
            />
            <span style={{ marginRight: "8px" }}>{selectedOption?.name}</span>
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default MainDrop;
