const initialState = {
    overview: null,
    Speed: null,
    Agent: null,
    Activity: null,
    Conversation : null,
    VoiceCustomer: null,
    Bot: null,
    Reports:null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "RECEIVE_DASHBOARD_OVERVIEW":
            return {
                ...state,
                overview: action.payload
            };
            case "RECEIVE_SPEED_METER":
                return {
                ...state,
                Speed: action.payload
            };case "RECEIVE_Agent_Performance":
            return {
                ...state,
                Agent: action.payload
            };
            case "RECEIVE_LOGIN_ACTIVITY":
                return {
                ...state,
                Activity: action.payload
            };
            case "RECEIVE_Conversation_Initiated":
            return {
                ...state,
                Conversation: action.payload
            };
            case "RECEIVE_whatsapp_Report":
            return {
                ...state,
               Reports: action.payload
            }; case "RECEIVE_Dashborad_Voice_of_customer_data":
            return {
                ...state,
                VoiceCustomer: action.payload
            };
                case "RECEIVE_Dashborad_Bot_Builder_Data":
            return {
                ...state,
                Bot: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
