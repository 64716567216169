import React, {useState} from 'react';
import {Tabs} from 'antd';
import Contacts from "./Components/AllContacts/Contacts";
import Groups from "./Components/Groups/Groups";
import { useTranslation } from 'react-i18next';
const ContactMain = () => {
    const {t} = useTranslation()
    const [state, setState] = useState({
        activeKey:'1'
    })
    const items = [
        {
            key: '1',
            label: t('Contacts'),
            children: state.activeKey==='1' && <Contacts/>,
        },
        {
            key: '2',
            label: t('Groups'),
            children: state.activeKey==='2' && <Groups/>,
        }
    ];

    const handleTabChange = (activeKey) =>{
        setState({...state, activeKey})
    }
    return <div className={'synctic-module-container'}>
        <div className={'cards-box'}>
            <Tabs
                defaultActiveKey="1"
                tabPosition={'top'}
                items={items}
                onChange={handleTabChange}
            />
        </div>
    </div>
}

export default ContactMain