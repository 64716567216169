import React, {useCallback, useState, useRef, useEffect} from "react";
import ReactFlow, {
    addEdge,
    Background,
    ReactFlowProvider,
    Controls,
} from "reactflow";
import { message } from "antd";
import {useNodes} from "../allnode";
import Sidebar from "./sideBar";

// import MessageEditor from "./sideBar/messageEditor.jsx";
import TriggerResponse from "./trigger/index.jsx";
import Node from "./customeNode/messageNode.jsx";
import optionNode from "./customeNode/optionNode.jsx";
import listNode from "./customeNode/listNode.jsx";
import ConditionNode from "./customeNode/conditionNode.jsx";
import conOptNode from "./customeNode/conditionOptionNode.jsx";
import {createMessageNode} from "./hooks/message";
import {isAllNodeisConnected} from "../utils.js";
import "reactflow/dist/style.css";
import {publishFlow, saveMessages} from "../Apis/Apis";
import {useSelector} from "react-redux";
import DeviceFrame from "./DeviceFrame/DeviceFrame";

const nodeTypes = {node: Node, option: optionNode, list: listNode, conNode: ConditionNode, conOptNode: conOptNode};
const OverviewFlow = (props) => {
    const flowPath = window.location.pathname.includes('/templates') ?  true : false;
    const [state, setState] = useState({
        isLoader: false,
        isDeviceShow: false
    })

    let flowData = useSelector(state => state?.chatFlow?.flowData)
    let flowDataId = flowData?.id

    const connectingNodeId = useRef(null);
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const {nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange} = useNodes();

    const onInit = (reactFlowInstance) => setReactFlowInstance(reactFlowInstance);

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };

    // adding new node
    const onDrop = (event) => {
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top
        });
        // adding node
        let messageId = `node_${new Date().getTime()}`;
        let newNode = createMessageNode(position, messageId, 'node')
        setNodes((es) => es.concat(newNode));

    };

    const onConnect = useCallback(
        (params) => {
            console.log(params)
            connectingNodeId.current = null;
            setEdges((edges) => {
                const filteredEdges = edges.filter((edge) => edge.target !== params.target);
                const completeFilterEdge = filteredEdges.filter((edge) => edge.source !== params.source)
                return addEdge({...params, markerEnd: {type: "arrowclosed"}}, completeFilterEdge);
            });

        },
        [setEdges]
    );

    const onConnectStart = useCallback((_, {nodeId}) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event) => {
            if (!connectingNodeId.current) return;

            const completeFilterEdge = edges.find((edge) => edge.source === connectingNodeId.current)
            if(completeFilterEdge) return true;

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top
            });
            // adding node
            let messageId = `node_${new Date().getTime()}`;
            let newNode = createMessageNode(position, messageId, 'node')
            setNodes((es) => es.concat(newNode));

            setEdges((edges) => {
                return addEdge({
                    "source": connectingNodeId.current,
                    "sourceHandle": "b",
                    "target": messageId,
                    "targetHandle": "a",
                    "markerEnd": {type: "arrowclosed"}
                }, edges);
            });
        }
    );
    
    const saveHandler = () => {
        if(nodes?.length > 0 && edges?.length > 0){
          const flowData = reactFlowInstance.toObject();
          const nodeReponse = isAllNodeisConnected(nodes, edges)
            
          if(nodeReponse){
            setState({isLoader : true})
            publishFlow(flowDataId, {flowData})
                .then(()=> {
                  setState({isLoader : false})
                  message.success("Flow has been publish")
                })
                .catch(()=> {
                  setState({isLoader : false})
                  message.error("Facing some issue while saving") 
                })
          }
        }
        else{
          message.warning("There is no node to save")
        }
    }
    const saveNodes = async () => {
        const flowData = reactFlowInstance.toObject();
        const response = await saveMessages(flowDataId, {flowData})
        return response.data || null;
    }

    const showDevice = () => {
            setState({...state, isDeviceShow: !state.isDeviceShow})
    }

    return (
            <div className="flow-wrapper">
                <Sidebar
                    onDrop={onDrop}
                    saveHandler={saveHandler}
                    showDevice={showDevice}
                    loader={state.isLoader}
                    isDeviceShow={state.isDeviceShow}
                    flowPath={flowPath}
                    flowInfo={flowData}
                />
                <div className="dndflow ">
                    {
                        state.isDeviceShow && <DeviceFrame flowDataId={flowDataId}/>
                    }
                    <ReactFlowProvider>
                        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                nodeTypes={nodeTypes}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onInit={onInit}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                disableKeyboardA11y={true}
                                deleteKeyCode={46}
                                onConnectEnd={onConnectEnd}
                                onConnectStart={onConnectStart}
                                fitView
                            >
                                <Background color="#aaa" gap={16}/>
                                <Controls/>
                            </ReactFlow>
                        </div>
                    </ReactFlowProvider>
                </div>
                <TriggerResponse saveNodes={saveNodes}/>
            </div>
    );
};

export default OverviewFlow;
